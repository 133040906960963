import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { useState } from "react";

import './input-file-field.scss';

const InputFileField = (props) => {
    const {
        param, 
        handleChange, 
        //value, 
        uploadFile,
        dataConfig
    } = props;
    const [loading, setLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState({});
    const [fileSizeError, setFileSizeStatus] = useState(false);

    const handleFileChange = (e) => {
        setFileSizeStatus(false);
        setLoading(true);

        const size = parseFloat((e.target.files[0].size/ 1024 / 1024).toFixed(3))
        const MAXSIZE = dataConfig.MAX_FILE_SIZE ? dataConfig.MAX_FILE_SIZE/1024/1024 : 10;
        if (size > MAXSIZE){
            setFileSizeStatus(true)
        }
        else{
            setFileSizeStatus(false)

            let formData = new FormData();
            formData.append('files', e.target.files[0]);

            uploadFile(formData)
            .then(res => {
                console.log("uploadFile done", res.data[0]);
                setUploadedFile(res.data[0]);
                //TODO show uploaded file name

                handleChange({target : {name: param.field, value: res.data[0].id}});
                setLoading(false);
            })
        }
    }    

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <LoadingButton component="label" variant="contained" loading={loading}>
                {param.headerName}
                <input type="file" hidden id={param.field} name={param.field} onChange={handleFileChange} />
            </LoadingButton>
            { fileSizeError && 
                <div 
                    className='uploadError'
                >
                    Votre fichier est trop volumineux!(supérieur à {dataConfig.MAX_FILE_SIZE/1024/1024}MB)
                </div> 
            }
            <span>{uploadedFile?.name}</span>
        </Stack>
    )

}
export default connect(mapStateToProps, mapDispatchToProps)(InputFileField);