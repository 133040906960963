import axios from "axios";

export const GET_ALL_DOCUMENT = "GET_ALL_DOCUMENT";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const MODIF_DOCUMENT = "MODIF_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const GET_ONE_DOCUMENT = "GET_ONE_DOCUMENT";
export const GET_ALL_DOCUMENT_BY_MATERIAL_GRAPHQL = "GET_ALL_DOCUMENT_BY_MATERIAL_GRAPHQL";
export const GET_ALL_DOCUMENT_BY_CONTRACT_GRAPHQL = "GET_ALL_DOCUMENT_BY_CONTRACT_GRAPHQL";
export const GET_ALL_DOCUMENT_BY_TICKET_GRAPHQL = "GET_ALL_DOCUMENT_BY_TICKET_GRAPHQL";

export const getAllDocuments = () => (dispatch) => {
    try {
        return axios.get("/documents")
                    .then(cli => {
                        dispatch({ type: GET_ALL_DOCUMENT, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL DOCUMENT ", error);
    }
};

export const addDocument = (documentState, history) => (dispatch) => {
    try {
        return axios.post("/documents", documentState);
    } catch (error) {
        return console.log("ERREUR AJOUT DOCUMENT ", error);
    }
};

export const updateDocument = (documentState) => (dispatch) => {
    try {
        return axios.put(`/documents/${documentState.id}`, documentState)
                    .then(cli => {
                        dispatch({ type: MODIF_DOCUMENT, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION DOCUMENT ", error);
    }
};

export const deleteDocument = (id) => (dispatch) => {
    try {
        return axios.delete(`/documents/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_DOCUMENT, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE DOCUMENT ", error);
    }
};

export const getOneDocument = (id) => (dispatch) => {
    try{
        return axios.get(`/documents/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_DOCUMENT, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const getAllDocumentsByMaterial = (materialId) => (dispatch) => {
    try {
        const querya = `query getAllDocumentsByMaterial($mid:ID!){
            documents (where : {materials : {id : $mid}}) {
              id
              file {
                  id
                  name
                  url
              }
              document_type {
                  id
                  name
              }
            }
        }`;
        const variablesa = { "mid" : materialId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL DOCUMENT ", error);
    }
};

export const getAllDocumentsByCompany = (companyId) => (dispatch) => {
    try {
        const querya = `query getDocuments($cid:ID!){
            documents (where : {building : { site: {company : { id : $cid }}}}) {
              id
              name
              floor
              building {
                  id
                  name
                  site {
                      id
                      name
                  }
              }
            }
        }`;
        const variablesa = { "cid" : companyId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL DOCUMENT ", error);
    }
};

export const getAllDocumentsByContract = (contractId) => (dispatch) => {
    try {
        const querya = `query getAllDocumentsByContract($cid:ID!){
            documents (where : {contracts : {id : $cid}}) {
              id
              file {
                  id
                  name
                  url
              }
              document_type {
                  id
                  name
              }
            }
        }`;
        const variablesa = { "cid" : contractId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL DOCUMENT ", error);
    }
};

export const getAllDocumentsByTicket = (ticketId) => (dispatch) => {
    try {
        const querya = `query getAllDocumentsByTicket($tid:ID!){
            documents (where : {ticket_histories : {ticket : {id : $tid}}}) {
              id
              file {
                  id
                  name
                  url
              }
              document_type {
                  id
                  name
              }
            }
        }`;
        const variablesa = { "tid" : ticketId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL DOCUMENT ", error);
    }
};