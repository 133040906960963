


import axios from "axios";

export const GET_ALL_TICKET_HISTORY = "GET_ALL_TICKET_HISTORY";
export const ADD_TICKET_HISTORY = "ADD_TICKET_HISTORY";
export const MODIF_TICKET_HISTORY = "MODIF_TICKET_HISTORY";
export const DELETE_TICKET_HISTORY = "DELETE_TICKET_HISTORY";
export const GET_ONE_TICKET_HISTORY = "GET_ONE_TICKET_HISTORY";

export const getAllTicketHistories = () => (dispatch) => {
    try {
        return axios.get("/ticket-histories")
                    .then(cli => {
                        dispatch({ type: GET_ALL_TICKET_HISTORY, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL TICKET HISTORY ", error);
    }
};

export const addTicketHistory = (ticketHistoryState, history) => (dispatch) => {
    try {
        return axios.post("/ticket-histories", ticketHistoryState)
                    .then(cli => {
                        dispatch({ type: ADD_TICKET_HISTORY, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT HISTORY TICKET ", error);
    }
  };
  
  export const updateTicketHistory = (ticketHistoryState) => (dispatch) => {
    try {
        return axios.put(`/ticket-histories/${ticketHistoryState.id}`, ticketHistoryState)
                    .then(cli => {
                        dispatch({ type: MODIF_TICKET_HISTORY, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION HISTORY TICKET ", error);
    }
  };
  
  export const deleteTicketHistory = (id) => (dispatch) => {
    try {
        return axios.delete(`/ticket-histories/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_TICKET_HISTORY, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE HISTORY TICKET ", error);
    }
  };
  
  export const getOneTicketHistory = (id) => (dispatch) => {
    try{
        return axios.get(`/ticket-histories/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_TICKET_HISTORY, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
  };

  export const getAllTicketHistoriesByTicket = (ticketId) => (dispatch) => {
    
    try {
        const querya = `query getTicketHistories($tid:ID!){
            ticketHistories (where : {ticket : { id : $tid }},sort: "created_at:desc") {
              id
              field
              oldValue
              newValue
              created_at
              modifierUser {
                  id
                  username
                  email
                  name
                  lastname
              }
              documents {
                id
                name
                file {
                    id
                    name
                }
              }
            }
        }`;
        const variablesa = { "tid" : ticketId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL TICKETS ", error);
    }
};

export const getAllTicketCommentsByTicket = (ticketId) => (dispatch) => {
    if(ticketId){
        try {
            const querya = `query getTicketComments($tid:ID!){
                ticketHistories (where : {ticket : { id : $tid }, field: "comments"},sort: "created_at:desc") {
                  id
                  field
                  oldValue
                  newValue
                  created_at
                  modifierUser {
                      id
                      username
                      email
                      name
                      lastname
                  }
                  documents {
                    id
                    name
                    file {
                        id
                        name
                        url
                    }
                  }
                }
            }`;
            const variablesa = { "tid" : ticketId }
    
            const body = {
                query : querya,
                variables : variablesa
            }
    
            return axios.post('/graphql', body);
        } catch (error) {
            return console.log("ERREUR GET ALL TICKETS ", error);
        }
    }
};

export const getLastCommentByTicket = (ticketId) => (dispatch) => {
    try {
        const querya = `query getLastComment($tid:ID!){
            ticketHistories (where : {ticket : { id : $tid }, field: "comments"},sort: "created_at:desc", limit: 1) {
              id
              field
              oldValue
              newValue
              created_at
              modifierUser {
                  id
                  username
                  email
                  name
                  lastname
              }
              documents {
                id
                name
                file {
                    id
                    name
                }
              }
            }
        }`;
        const variablesa = { "tid" : ticketId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL TICKETS ", error);
    }
};