import axios from "axios";

export const GET_ALL_TICKET_STATUT = "GET_ALL_TICKET_STATUT";
export const ADD_TICKET_STATUT = "ADD_TICKET_STATUT";
export const MODIF_TICKET_STATUT = "MODIF_TICKET_STATUT";
export const DELETE_TICKET_STATUT = "DELETE_TICKET_STATUT";
export const GET_ONE_TICKET_STATUT = "GET_ONE_TICKET_STATUT";

export const getAllTicketStatuts = () => (dispatch) => {
    try {
        return axios.get("/ticket-statuts")
                    .then(cli => {
                        dispatch({ type: GET_ALL_TICKET_STATUT, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL TICKET STATUT ", error);
    }
};

export const addTicketStatut = (ticketStatutState, history) => (dispatch) => {
    try {
        return axios.post("/ticket-statuts", ticketStatutState)
                    .then(cli => {
                        dispatch({ type: ADD_TICKET_STATUT, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT STATUT TICKET ", error);
    }
  };
  
  export const updateTicketStatut = (ticketStatutState) => (dispatch) => {
    try {
        return axios.put(`/ticket-statuts/${ticketStatutState.id}`, ticketStatutState)
                    .then(cli => {
                        dispatch({ type: MODIF_TICKET_STATUT, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION STATUT TICKET ", error);
    }
  };
  
  export const deleteTicketStatut = (id) => (dispatch) => {
    try {
        return axios.delete(`/ticket-statuts/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_TICKET_STATUT, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE STATUT TICKET ", error);
    }
  };
  
  export const getOneTicketStatut = (id) => (dispatch) => {
    try{
        return axios.get(`/ticket-statuts/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_TICKET_STATUT, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
  };

  export const getNotificationTicketStatuts = () => (dispatch) => {
    try {
        return axios.get("/ticket-statuts?notifClient=1");
    } catch (error) {
        return console.log("ERREUR GET ALL TICKET STATUT ", error);
    }
};