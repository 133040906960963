import { useEffect, useState } from "react";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import FilterList from '@mui/icons-material/FilterList';

import CurrentFilter from "./current-filter";
import AddFilter from "./add-filter";

import './grid-filter.scss';

const GridFilter = (props) => {
    const [addFilter, setAddFilter] = useState(false);
    const [currentFilter, setCurrentFilter] = useState([]);
    const [fields, setFields] = useState(props.fields);

    useEffect(() => {
        //setAddFilter(currentFilter.length !== 0);

        if (props.onFilterChanged) {
            props.onFilterChanged(currentFilter)
        }
        setFields(getRemainFields())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter])

    useEffect(() => {
        if (props.onFilterActive) {
            props.onFilterActive(addFilter)
        }
    }, [addFilter])

    const toggleAddFilter = () => {
        setAddFilter(!addFilter);
    }

    const handleAddNewFilter = (newfilter) => {
        setCurrentFilter([...currentFilter, newfilter]);
        setAddFilter(false);
    }

    const handleFilterDeleting = (index) => {
        setCurrentFilter(currentFilter.filter((_, i) => { // important : use filter instead of splice, in order to create new array
            return i != index
        }));
    }

    const getRemainFields = () => {
        const activeFields = currentFilter.length > 0 ? currentFilter.map(cf => cf.field) : [];
        
        const ret = fields.map((f) => {
            f.active = !activeFields.includes(f.field);
            return f
        });
        return ret;
    }

    return (
        <div className="filter">
            <div className="current">
                <div className="btn-filter" onClick={toggleAddFilter}>
                    <FilterList fontSize="small" />
                    <span>Filtrer</span>
                </div>
                <CurrentFilter key={currentFilter} fields={fields} filter={currentFilter} onFilterDeleting={handleFilterDeleting}/>
            </div>
            {addFilter && 
                <AddFilter fields={fields} currentFilter={currentFilter} onFilterAdded={handleAddNewFilter} />
            }
        </div>
    )
} 
export default connect(mapStateToProps, mapDispatchToProps)(GridFilter);