import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { useEffect, useState } from "react";

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { comparators } from "../../core/utils";

const CurrentFilter = (props) => {
    const [filters, setFilters] = useState([]);
    const [fields, setFields] = useState(props.fields);

    useEffect(() => {
        setFilters(props.filter)
    }, [props.filter])

    useEffect(() => {
        setFields(props.fields)
    }, [props.fields])

    const handleDelete = (index) => {
        if (props.onFilterDeleting){
            props.onFilterDeleting(index)
        }
    }

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
      }));

    return (
        <div className="activeFilter">
            <Paper
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    background: 'white',
                    border: '1px solid rgb(227, 233, 243)',
                    minHeight: 34
                }}
                variant="outlined"
                square 
                component="ul"
                >
                {filters.map((f, index) => {
                    const fieldTitle = fields.filter(fld => fld.field == f.field)[0]?.title;
                    const fieldType = fields.filter(fld => fld.field == f.field)[0]?.type;
                    const comparatorTitle = comparators[fieldType]?.filter(c => c.comparator == f.comparator)[0].title;
                    let fieldValue = (fieldType == "date") ? new Date(f.value).toLocaleDateString('en-GB'): f.value;

                    return (
                        <ListItem key={index}>
                            <Chip
                                sx = {{
                                    borderRadius:0,
                                    border: '1px solid rgba(0, 126, 255, 0.24)',
                                    background: 'rgba(0, 126, 255, 0.08)',
                                    color: 'rgb(0, 126, 255)',
                                    height: '25px'
                                }}
                                label={<><strong>{fieldTitle}</strong> {comparatorTitle} <strong>{fieldValue}</strong></>}
                                variant="outlined"
                                onDelete={() => handleDelete(index)}
                                size="small"
                            />
                        </ListItem>
                    );
                })}
            </Paper>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(CurrentFilter);