
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import './input-text-field.scss';

const InputTextField = (props) => {
    const {
        param, 
        handleChange, 
        value, 
        disabled
    } = props;
    const { inputType } = param;

    

    const onChange = (e) => {
        handleChange(e);
        
        if (param.onChange){
            // eslint-disable-next-line no-eval
            eval(param.onChange.codeToEval);

            const tgt = {target: {name: param.onChange.targetField, value: e.target.value}};
            handleChange(tgt)             
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
            }}
            >
            <TextField fullWidth variant="outlined" margin="normal" size="small" InputLabelProps={{ shrink: true }}
                type={inputType}
                id={param.field}
                name={param.field} 
                label={param.headerName}
                onChange={onChange}
                defaultValue={value}
                required={param.mandatory}  
                disabled={disabled}
                autoComplete='new-password'
            />
        </Box>
    )
}
export default InputTextField;