import {
  GET_ALL_DOCUMENTTYPE,
  MODIF_DOCUMENTTYPE,
  DELETE_DOCUMENTTYPE,
  ADD_DOCUMENTTYPE,
  GET_ONE_DOCUMENTTYPE,
} from "../actions/documentTypeAction";

const initialState = {
  documentTypes: [],
};

export function documentTypeReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DOCUMENTTYPE:
      return { ...state, documentTypes: action.payload };

    case ADD_DOCUMENTTYPE:
      return { ...state, documentType: action.payload };

    case MODIF_DOCUMENTTYPE:
      return { ...state };

    case DELETE_DOCUMENTTYPE:
      return { ...state };

    case GET_ONE_DOCUMENTTYPE:
      return { documentTypes: action.payload };

    default:
      return { ...state };
  }
}
