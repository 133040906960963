import { 
  ADD_TICKET_OBJECT, 
  DELETE_TICKET_OBJECT, 
  GET_ALL_TICKET_OBJECT, 
  GET_ONE_TICKET_OBJECT, 
  MODIF_TICKET_OBJECT 
} from "../actions/ticketObjectAction";


const initialState = {
  ticketObjects: [],
};

export function ticketObjectReducers(state = initialState, action) {
  switch (action.type) {
      case GET_ALL_TICKET_OBJECT:
          return { ...state, ticketObjects: action.payload };

      case ADD_TICKET_OBJECT:
          return { ...state, ticketObject: action.payload };

      case MODIF_TICKET_OBJECT:
          return { ...state };

      case DELETE_TICKET_OBJECT:
          return { ...state };

      case GET_ONE_TICKET_OBJECT:
          return { ticketObject: action.payload };

      default:
          return { ...state };
  }
}