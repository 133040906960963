import { 
  ADD_TICKET_ORIGIN, 
  DELETE_TICKET_ORIGIN, 
  GET_ALL_TICKET_ORIGIN, 
  GET_ONE_TICKET_ORIGIN, 
  MODIF_TICKET_ORIGIN 
} from "../actions/ticketOriginAction";


const initialState = {
  ticketOrigins: [],
  ticketOrigin: {}
};

export function ticketOriginReducers(state = initialState, action) {
  switch (action.type) {
      case GET_ALL_TICKET_ORIGIN:
          return { ...state, ticketOrigins: action.payload };

      case ADD_TICKET_ORIGIN:
          return { ...state, ticketOrigin: action.payload };

      case MODIF_TICKET_ORIGIN:
          return { ...state };

      case DELETE_TICKET_ORIGIN:
          return { ...state };

      case GET_ONE_TICKET_ORIGIN:
          return { ticketOrigin: action.payload };

      default:
          return { ...state };
  }
}