import {
  GET_ALL_KNOWLEDGEBASE,
  MODIF_KNOWLEDGEBASE,
  DELETE_KNOWLEDGEBASE,
  ADD_KNOWLEDGEBASE,
  GET_ONE_KNOWLEDGEBASE
} from "../actions/knowledgeBaseAction";

const initialState = {
  knowledgeBases: [],
  knowledgeBase: {},
};

export function knowledgeBaseReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_KNOWLEDGEBASE:
      return { ...state, knowledgeBases: action.payload };

    case ADD_KNOWLEDGEBASE:
      return { ...state, knowledgeBase: action.payload };

    case MODIF_KNOWLEDGEBASE:
      return { ...state };

    case DELETE_KNOWLEDGEBASE:
      return { ...state };

    case GET_ONE_KNOWLEDGEBASE:
      return { knowledgeBase: action.payload };

    default:
      return { ...state };
  }
}
