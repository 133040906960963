import CircularProgressWithLabel from "../../components/dashboard/circular-progress-with-label";

const TicketByPriorite = (props) => {
    const {data, totalTickets} = props;

    return (
        <>
            <h2>Nombre de tickets par priorité</h2>
            <div className="priorite-content contentDashboard">
                {
                    Object.keys(data).map((key, index) => {
                        return (
                            <div key={index} className={key.toLowerCase()}>
                                <CircularProgressWithLabel value={totalTickets === 0 ? 0:parseInt((data[key].data.length / totalTickets)*100)} />
                                <div>
                                    <div>Ticket {key}</div>
                                    <div>{data[key].data.length} / {totalTickets}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
export default TicketByPriorite;