import axios from "axios";

export const GET_ALL_TICKET_TYPE = "GET_ALL_TICKET_TYPE";
export const ADD_TICKET_TYPE = "ADD_TICKET_TYPE";
export const MODIF_TICKET_TYPE = "MODIF_TICKET_TYPE";
export const DELETE_TICKET_TYPE = "DELETE_TICKET_TYPE";
export const GET_ONE_TICKET_TYPE = "GET_ONE_TICKET_TYPE";

export const getAllTicketTypes = () => (dispatch) => {
  try {
      return axios.get("/ticket-types")
                  .then(cli => {
                      dispatch({ type: GET_ALL_TICKET_TYPE, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR GET ALL TICKET TYPE ", error);
  }
};

export const addTicketType = (ticketTypeState, history) => (dispatch) => {
  try {
      return axios.post("/ticket-types", ticketTypeState)
                  .then(cli => {
                      dispatch({ type: ADD_TICKET_TYPE, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR AJOUT TICKET TYPE ", error);
  }
};

export const updateTicketType = (ticketTypeState) => (dispatch) => {
  try {
      return axios.put(`/ticket-types/${ticketTypeState.id}`, ticketTypeState)
                  .then(cli => {
                      dispatch({ type: MODIF_TICKET_TYPE, payload: cli.data })
                  });
  } catch (error) {
      return console.log("ERREUR MODIFICATION TICKET TYPE ", error);
  }
};

export const deleteTicketType = (id) => (dispatch) => {
  try {
      return axios.delete(`/ticket-types/${id}`)
                  .then((cli) => {
                      dispatch({ type: DELETE_TICKET_TYPE, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR DELETE TICKET TYPE ", error);
  }
};

export const getOneTicketType = (id) => (dispatch) => {
  try{
      return axios.get(`/ticket-types/${id}`)
                  .then(rep => {
                      dispatch({ type: GET_ONE_TICKET_TYPE, payload: rep.data });
                  });
  } catch (error) {
      return console.log("ERREUR ", error);
  }
};