import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import FolderIcon from "@mui/icons-material/Folder";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { useState, useEffect } from "react";

//import InputFileField from '../input/input-file-field';

import "./document-list.scss";

const DocumentList = (props) => {
  const {
    param,
    handleChange,
    uploadFile,
    addDocument,
    downloadFile,
    add,
    docdata,
    dataConfig,
    compactMode,
    disabled,
    onStartUploading,
    onEndUploading,
  } = props;

  const MAXSIZE = dataConfig.MAX_FILE_SIZE
    ? dataConfig.MAX_FILE_SIZE / 1024 / 1024
    : 10;
  const FILE_TYPE = dataConfig.FILE_TYPE_TO_UPLOAD
    ? dataConfig.FILE_TYPE_TO_UPLOAD.split(", ")
    : [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".png", ".jpg", ".jpeg"];
  const validFileType = (file) => {
    const type = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
    console.log("fileext", type);
    console.log("FILE_TYPE", FILE_TYPE);
    return FILE_TYPE.includes(type);
  };

  const sourceFunc = param.provider.source.func;
  const sourceFuncParam = param.provider.source.param;
  //const sourceData = props[param.provider.source.data];
  const [sourceDataDynamic, setSourceDataDynamic] = useState(
    docdata ? docdata : []
  );
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [deletedFileTmp, setDeletedFileTmp] = useState(null);
  const [fileSizeError, setFileSizeStatus] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);

  useEffect(() => {
    console.log("call reload");
    reload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceFuncParam]);

  const reload = () => {
    if (sourceFuncParam) {
      props[sourceFunc](sourceFuncParam).then((res) => {
        console.log("reload.res", res);
        setSourceDataDynamic(res.data.data[param.provider.source.data]);
      });
    } else {
      if (sourceFunc) {
        props[sourceFunc]();
      }
    }
  };

  const handleFileChange = (e) => {
    // setFileTypeError(false);
    // setFileSizeStatus(false);
    console.log(e.target.value);
    if (
      deletedFileTmp?.name === e.target.files[0].name ||
      deletedFileTmp?.id === uploadedFile?.id
    ) {
      setDeletedFileTmp(null);
    }
    const size = parseFloat((e.target.files[0].size / 1024 / 1024).toFixed(3));
    const valid = validFileType(e.target.files[0]);
    if (size > MAXSIZE || !valid) {
      if (size > MAXSIZE) setFileSizeStatus(true);
      if (!valid) setFileTypeError(true);
    } else if (size < MAXSIZE && valid) {
      setFileTypeError(false);
      setFileSizeStatus(false);
      if (e.target.files && e.target.files.length > 0) {
        if (onStartUploading && typeof onStartUploading === "function")
          onStartUploading();
        setLoading(true);

        let formData = new FormData();
        formData.append("files", e.target.files[0]);

        uploadFile(formData).then((res) => {
          setUploadedFile(res.data[0]);
          //TODO insert document and  show uploaded file name
          const doc = {
            name: res.data[0].name,
            document_type: 1, //TODO dynamic
            file: res.data[0].id,
          };
          addDocument(doc).then((resAddDoc) => {
            let arrayId = [resAddDoc.data.id];
            console.log("sourceDataDynamic", sourceDataDynamic);
            sourceDataDynamic.forEach((sdd) => {
              arrayId.push(sdd.id);
            });
            handleChange({ target: { name: param.field, value: arrayId } });

            let newSource = sourceDataDynamic;
            newSource.push(resAddDoc.data);
            setSourceDataDynamic([]);
            setSourceDataDynamic(newSource);
          });

          setLoading(false);
          if (onEndUploading && typeof onEndUploading) onEndUploading();
        });
      }
    }
  };

  const handleDownloadItem = (e, id) => {
    downloadFile(id);
  };

  const handleDeleteItem = (e, file) => {
    setDeletedFileTmp(file);
    let newSource = sourceDataDynamic.filter((sdd) => sdd.id !== file.id);
    setSourceDataDynamic([]);
    setSourceDataDynamic(newSource);

    handleChange({
      target: { name: param.field, value: newSource.map((ns) => ns.id) },
    });
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
      className="uploadFileContent"
      disabled={disabled}
    >
      {add && (
        <Stack className={"addDoc " + (compactMode ? "compact" : "")}>
          <InputLabel id={param.field + "-select-label"}>
            {param.headerName}
          </InputLabel>
          <div className="dFlex uploadFile">
            <LoadingButton
              color="inherit"
              component="label"
              className="upload-button"
              variant="contained"
              loading={loading}
              startIcon={<AttachFileIcon />}
              size="small"
              disabled={disabled}
            >
              Ajouter document {`(< ${MAXSIZE}MB)`}
              {!deletedFileTmp && (
                <input
                  type="file"
                  id="uploadFile"
                  accept={FILE_TYPE}
                  hidden
                  onChange={handleFileChange}
                />
              )}
              {deletedFileTmp && (
                <input
                  type="file"
                  id="uploadFileTemp"
                  accept={FILE_TYPE}
                  hidden
                  onChange={handleFileChange}
                />
              )}
            </LoadingButton>
            <span
              className={fileTypeError ? "msgFileTypeError" : "msgFileType"}
            >
              <span className="icon-info"></span>
              <b>Fichiers autorisés : </b>
              {FILE_TYPE.toString().replaceAll(",", ", ")}
            </span>
          </div>
          {fileSizeError && (
            <div className="uploadError">
              Votre fichier est trop volumineux!(supérieur à {MAXSIZE}MB)
            </div>
          )}
        </Stack>
      )}

      <List
        dense={true}
        className={"listDoc " + (compactMode ? "compact" : "")}
      >
        {sourceDataDynamic?.map((data, index) => {
          return (
            <ListItem
              key={data.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  className="removeDoc"
                  onClick={(e) => handleDeleteItem(e, data)}
                  sx={{ display: add ? "flex" : "none" }}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemIcon className="icon-doc">
                <FolderIcon />
              </ListItemIcon>
              <ListItemText
                primary={data.file[0].name}
                onClick={(e) => handleDownloadItem(e, data.file[0].id)}
              />
            </ListItem>
          );
        })}
      </List>
    </FormControl>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);
