
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import './input-text-multiline-field.scss';

const InputTextMultilineField = (props) => {
    const {param, handleChange, value, rows} = props;
    const { inputType } = param;

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
            }}
            >
            <TextField fullWidth variant="outlined" margin="normal" size="small" InputLabelProps={{ shrink: true }}
                multiline
                rows={rows}
                type={inputType}
                name={param.field} 
                label={param.headerName}
                onChange={handleChange}
                defaultValue={value} 
                required={param.mandatory}
            />
        </Box>
    )
}
export default InputTextMultilineField;