import { UPDATE_CONFIG, GET_CONFIG } from "../actions/configAction"

const initialState = {
  config : {},
}

export const configReducers = (state = initialState, action) => {
  switch(action.type){
    case GET_CONFIG:
      return {...state, config: action.payload,}
    
    case UPDATE_CONFIG:
      return {...state, config: action.payload,}
      
    default:
      return {...state};
  }
}