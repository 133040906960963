import { Avatar } from "@mui/material";

const TicketByContact = (props) => {
  const { data, totalTickets } = props;

  return (
    <>
      <h2>Nombre de tickets par contact</h2>
      <div className="list contentDashboard">
        {Object.keys(data).map((key, index) => {
          //console.log('data.key', data[key].data.length)
          const spl = key.split(" ");
          const fn = spl.length > 0 ? spl[0].substring(0, 1) : "";
          const ln = spl.length > 1 ? spl[1].substring(0, 1) : "";
          return (
            <div key={index} className="item">
              <div>
                <Avatar>{`${fn}${ln}`}</Avatar>
              </div>
              <div className="details">
                <div>{key}</div>
                <div>
                  <span className="nbTicket">{data[key].data.length}</span>{" "}
                  ticket{data[key].data.length > 1 ? "s" : ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default TicketByContact;
