/**
 * usersAction.js
 * users redux actions
 * 
 * History
 *   06/01/2023 : ajout de forgotPassword
 */

import axios from "axios";

export const GET_ME = "GET_ME";
export const ADD_USER = "ADD_USER";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const MODIF_RESS = "MODIF_RESS";
export const DELETE_RESS = "DELETE_RESS";
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ONE_USER = "GET_ONE_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const EMAIL_CONFIRMATION = "EMAIL_CONFIRMATION";

const userKey = "clientmmgmt";

export const loginUser = (logState, history) => (dispatch) => {
  try {
    return axios.post("/auth/local", logState);
    
    /*.then((user) => {
      dispatch({ type: LOGIN_USER, payload: user.data });
      localStorage.setItem(userKey, user.data.jwt);
      window.location.reload();
    });*/
  } catch (error) {
    console.log('errror from loginUser', error)
    return dispatch({
      type: LOGIN_FAILED,
      payload: error.user ? error.user.data : {message : "Login ou mot de passe incorrect"},
    });
  }
};


export const logout = () => async (dispatch) => {
  localStorage.removeItem(userKey);
  return dispatch({ type: LOGOUT_USER, payload: {} });
}; 

// ressource
export const getMe = () => (dispatch) => {
  const jwt = localStorage.getItem(userKey);
  try {
    return axios
      .get("/users/me", { headers: { Authorization: `Bearer ${jwt}` } })
      .then((res) => {
        //console.log('action.getMe', res)
        dispatch({ type: GET_ME, payload: res.data });
      });
  } catch (e) {
    return console.error(e);
  }
};

export const registerUser = (userState, history) => (dispatch) => {
  try {
    console.log("user ",userState);
    return axios.post("/auth/local/register", {...userState, ...{deleted:0}}).then((res) => {
      dispatch({ type: ADD_USER, payload: res.data });
      //localStorage.setItem(userKey, res.data.jwt);
      //window.location.reload();
      // history.push("/accueil");
      // Document.location="/accueil";
    });
  } catch (error) {
    return console.log("FFFFFFFFFFFFFF");
  }
};

export const addUser = (userState, history) => (dispatch) => {
 
  try {
    //console.log('userState', userState);
    let company = userState.company;
    if(userState.role === "3") company = process.env.REACT_APP_OWN_COMPANY_ID; //si rôle admin, on force le compte à 'Kardham'
    const userPayload = {...userState, ...{deleted:0, confirmed:1, language: 'fr', timezone : 'Europe/Paris', company: company}};

    return axios.post("/users", userPayload);
  } catch (error) {
    return dispatch({
      type: ADD_USER_ERROR,
      payload: error,
    });
  }
};

export const modifRessource = (ressourceState) => (dispatch) => {
  try {
    return axios
      .put(`/users/${ressourceState.id}`, ressourceState);
  } catch (error) {
    return console.log("ERREUR MODIFICATION RESSOURCE", error);
  }
};

/**
 * 28/02/2023 : suppression logique
 * @param {S} id 
 * @returns 
 */
export const supprimRessource = (id) => (dispatch) => {
  try {
    /*
    return axios.delete(`/users/${id}`).then((res) => {
      dispatch({ type: DELETE_RESS, payload: res.data });
    });
    */
    return axios
    .put(`/users/${id}`, {deleted : true})
    .then((res) => {
      dispatch({ type: MODIF_RESS, payload: res.data });
    });
  } catch (error) {
    return console.log("ERREUR DELETE RESSOURCE", error);
  }
};

export const getAllUsers = () => (dispatch) => {
  try {
      return axios.get("/users?deleted=0")
                  .then(cli => {
                    const filterData = cli.data.filter((data) => { //pas réussi à filtrer avec strapi api
                      return ['client', 'admin'].includes(data.role.type)
                    })
                      dispatch({ type: GET_ALL_USER, payload: filterData });
                  });
  } catch (error) {
      return console.log("ERREUR GET ALL USER ", error);
  }
};

export const getOneUser = (id) => (dispatch) => {
  try{
      return axios.get(`/users/${id}`);
  } catch (error) {
      return console.log("ERREUR ", error);
  }
};

export const getAllUsersSync = () => {
  try {
      return axios.get("/users");
  } catch (error) {
      return console.log("ERREUR GET ALL USER ", error);
  }
};

export const forgotPassword = (logState) => (dispatch) => {
  try {
    return axios.post("/auth/forgot-password", logState);
  } catch (error) {
    console.log('errror from forgotPassword', error)
  }
};

export const resetPassword = (logState) => (dispatch) => {
  try {
    return axios.post("/auth/reset-password", logState);
  } catch (error) {
    console.log('errror from resetPassword', error)
  }
};

export const customResetPassword = (logState) => (dispatch) => {
  try {
    return axios.post("/auth/custom-reset-password", logState);
  } catch (error) {
    console.log('errror from resetPassword', error)
  }
};

export const emailConfirmation = (confirmationToken) => (dispatch) => {
  try {
    return axios.post("/auth/json-email-confirmation", confirmationToken);
    
  } catch (error) {
    console.log('errror from emailConfirmation', error)
  }
};