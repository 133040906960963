import { useState, useEffect } from "react";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";
import { comparators } from "../../core/utils";

import { MenuItem, Select, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';

const OneFilter = (props) => {
    const [selectedField, setSelectedField] = useState(
        (props.currentFilter ? props.currentFilter.field : ""));

    const [selectedFieldComparator, setSelectedFieldComparator] = useState(
        (props.currentFilter ? props.currentFilter.comparator : ""));

    const [filterValue, setFilterValue] = useState(props.currentFilter ? props.currentFilter.value : "");

    const [fieldComparators, setFieldComparators] = useState(
        (props.allFields && props.allFields.length > 0 ) ? comparators[props.allFields[0].type] : []);

    const [isValid, setIsValid] = useState(false);
    const [textFieldType, setTextFieldType] = useState("text");

    useEffect(() => {
        setIsValid(getValidity());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedField, selectedFieldComparator, filterValue])

    const handleSelectChange = (e) => {
        setSelectedField(e.target.value);
        if (props.allFields.filter(f => f.field == e.target.value).length > 0){
            const sf = props.allFields.filter(f => f.field == e.target.value)[0];
            setFieldComparators(comparators[sf.type]);
            setTextFieldType(sf.type);
        }
    }

    const handleSelectFieldComparatorChange = (e) => {
        setSelectedFieldComparator(e.target.value);
    }

    const handleFilterValueChange = (e) => {
        setFilterValue(e.target.value);
    }

    const handleAdd = (e) => {
        if (props.onAddNewFilter) {
            const newFilter = {field : selectedField, comparator: selectedFieldComparator, value: filterValue};
            props.onAddNewFilter(newFilter);
            reinitFields();
        }
    }

    const getValidity = () => {
        return selectedField != "" && selectedFieldComparator != "" && filterValue != "";
    }

    const reinitFields = () => {
        setSelectedField("");
        setSelectedFieldComparator("");
        setFilterValue("");
    }

    return (
        <div className="one-field">
            <div className="input">
                <Select 
                    sx={{
                        width:'150px'
                    }}
                    className="field-select"
                    displayEmpty
                    value={selectedField}
                    onChange={handleSelectChange}>
                        {selectedField == "" && <MenuItem key={-1} value=""><label className="default-option">Champ à filtrer</label></MenuItem>}
                    {props.allFields &&
                        props.allFields.map((f, index) => {
                            return (
                                <MenuItem key={index} value={f.field} disabled={!f.active}>{f.title}</MenuItem>
                            )
                        })
                    }
                </Select>
                <Select 
                    sx={{
                        width:'150px'
                    }}
                    className="comparator-select"
                    displayEmpty
                    value={selectedFieldComparator}
                    onChange={handleSelectFieldComparatorChange}>
                        {selectedFieldComparator == "" && <MenuItem key={-1} value=""><label className="default-option">Comparateur</label></MenuItem>}
                    {fieldComparators &&
                        fieldComparators.map((c, index) => {
                            return (
                                <MenuItem key={index} value={c.comparator}>{c.title}</MenuItem>
                            )
                        })
                    }
                </Select>
                <TextField
                    sx={{
                        width:'150px'
                    }}
                    className="value-input"
                    placeholder="valeur"
                    value={filterValue}
                    type={textFieldType}
                    onChange={(event) => handleFilterValueChange(event)}
                ></TextField>
            </div>
            {props.canAdd && 
                <div className="add">
                    <IconButton aria-label="delete" size="small" onClick={handleAdd} disabled={!isValid}>
                        <AddCircleIcon fontSize="inherit" />
                    </IconButton>
                </div>
            }
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(OneFilter);