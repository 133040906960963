import {
    SET_NEEDREFRESH,
  } from "../actions/navigationAction";
  
  const initialState = {
    needRefresh: false,
  };
  
  export function navigationReducers(state = initialState, action) {
    switch (action.type) {
      case SET_NEEDREFRESH:
        return { ...state, needRefresh: action.payload };
  
      default:
        return { ...state };
    }
  }
  