import {
  GET_ALL_CONTACT,
  GET_ONE_CONTACT,
  GET_ALL_CONTACT_GRAPHQL,
  SET_OWN_COMPANY_CONTACTS,
  GET_COMPANY_CONTACTS
} from "../actions/contactAction";

const initialState = {
  contacts: [],
  contact: {},
  ownCompanyContacts: []
};

export function contactReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONTACT:
      return { ...state, contacts: action.payload };

    case GET_ONE_CONTACT:
      return { ...state, contact: action.payload };

    case GET_ALL_CONTACT_GRAPHQL:
      return { ...state, contacts: action.payload };      

    case SET_OWN_COMPANY_CONTACTS:
      return { ...state, ownCompanyContacts: action.payload };

    case GET_COMPANY_CONTACTS:
      return {...state, contacts: action.payload}

    default:
      return { ...state };
  }
}
