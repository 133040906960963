import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import axios from 'axios';

import MainLayout from "./layout/main-layout";
import AuthLayout from "./layout/auth-layout";

import { BrowserRouter as Router } from "react-router-dom";
import './App.scss';

axios.defaults.baseURL = process.env.REACT_APP_PROXY;

const App = () => {
  const [confirmationCode, setConfirmationCode] = useState("");
  
  useEffect(() => {
    const jwt = localStorage.getItem("clientmmgmt");
    if (jwt) {
      axios.defaults.headers.common = {'Authorization': `Bearer ${jwt}`}
    }
    else {
      const location = window.location;
      // console.log(location?.search)
      const urlParams = new URLSearchParams(location.search);
      
      if (urlParams.get('confirmation')){
        setConfirmationCode(urlParams.get('confirmation'));
      }
    }
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {localStorage.getItem("clientmmgmt") ? <Router><MainLayout /></Router> : <AuthLayout code={confirmationCode} />}
      </PersistGate>
    </Provider>   
  );
}

export default App;
