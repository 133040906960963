import {
  UPLOAD_FILE
} from "../actions/fileAction";

const initialState = {
  file: {},
};

export function fileReducers(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE:
      return { ...state, file: action.payload };

      default:
      return { ...state };
  }
}
