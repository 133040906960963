import {
    GET_ROLE,
  } from "../actions/rolesAction";
  
  const initialState = {
    roles: [],
  };
  
  export function rolesReducers(state = initialState, action) {
    switch (action.type) {
  
      case GET_ROLE:
        return { roles: action.payload };
  
      default:
        return { ...state };
    }
  };