import { SET_TICKET_LIST } from "../actions/ticketListAction";

export const TICKET_LIST_DEFAULT= 'default';
export const TICKET_LIST_ME = 'me';

const initialState = {
  ticketListState: TICKET_LIST_DEFAULT,
};

export function ticketListReducers(state = initialState, action) {
  switch (action.type) {
    case SET_TICKET_LIST:
      return { ...state, ticketListState: action.payload }
  
    default:
      return { ...state };
  }
}