import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import mapStateToProps from "../../../redux/mapStateToProps";
import mapDispatchToProps from "../../../redux/mapDispatchToProps";
//import Moment from "moment";
import { getOneEscalationGroup_ } from "../../../redux/actions/escalationGroupAction";
// import {
//   Checkbox,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   FormControlLabel,
// } from "@mui/material";
import TicketComments from "../comments/ticket-comments";
import DocumentList from "../../../components/document/document-list";
import RestoreIcon from '@mui/icons-material/Restore';

import "./ticket-history.scss";
import { getLocaleDateTime } from "../../../core/utils";

const TicketHistory = (props) => {
  const {
    //disableComment,
    getAllTicketHistoriesByTicket,
    getAllTicketStatuts,
    ticketStatuts,
    getAllTicketPriorities,
    ticketPriorities,
    ticketId,
    getOneTicket,
    updateTicket,
    user,
    addTicketHistory,
    //getLastCommentByTicket,
    getAllTicketObjects,
    ticketObjects,
    getAllTicketMotifs,
    ticketMotifs,
    getAllTicketOrigins,
    ticketOrigins,
    refresh,
  } = props;
  const [ticketHistories, setTicketHistories] = useState(null);
  const [levelEscatalatedUsers, setLevelEscatalatedUsers] = useState(null);
  const [comment, setComment] = useState("");
  const [refreshComments, setRefreshComments] = useState(false);
  //   const [open, setOpen] = useState(false);
  const [disableAddComment, setDisableAddComment] = useState(true);
  //const [checkedShare, setCheckedShare] = useState(false);
  const [documentId, setDocumentId] = useState(0);

  const docParam = {
    field: "documents",
    headerName: "",
    inputType: "document-list",
    flex: 0.2,
    provider: { source: {}, displayField: "name", valueField: "id" },
    filterable: true,
    add: true,
    edit: true,
    list: false,
    copy: true,
    listdetails: false,
  };

  useEffect(() => {
    getAllTicketStatuts();
    getAllTicketPriorities();
    getAllTicketObjects();
    getAllTicketMotifs();
    getAllTicketOrigins();

    getAllTicketHistoriesByTicket(ticketId).then((res) => {
      setTicketHistories(res.data.data.ticketHistories);
      getOneTicket(ticketId).then((result) => {
        if (result.data?.responsible_user?.escalation_level_group) {
          getOneEscalationGroup_(
            result.data?.responsible_user?.escalation_level_group
          ).then((res) => {
            setLevelEscatalatedUsers(res.data.users);
            //console.log(res.data.users);
          });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // useEffect(() => {
  //   getLastCommentByTicket(ticketId).then((res) => {
  //     if (res.data.data.ticketHistories.length > 0) {
  //       setLastComment(res.data.data.ticketHistories[0]);
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refreshComments]);

  useEffect(() => {
    setDisableAddComment(comment === "" ? true : false);
    // if (comment == "" && checkedShare == true) {
    //   setCheckedShare(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };
  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const renderStatutHistory = (history, index) => {
    const oldTicketsArray = ticketStatuts?.filter((th) => {
      return th.id === history.oldValue;
    });
    const oldStatus = oldTicketsArray[0]?.name;

    const newTicketsArray = ticketStatuts?.filter((th) => {
      return th.id === history.newValue;
    });
    const newStatus = newTicketsArray[0]?.name;
    return (
      <div className="histo" key={index}>
        <h3>
          Statut modifié de {oldStatus ? oldStatus : "Aucun"} à{" "}
          {newStatus ? newStatus : "Aucun"}
        </h3>
        <div className="explication">
          {getLocaleDateTime(history?.created_at, user.timezone)} par{" "}
          {history?.modifierUser?.name
            ? history?.modifierUser?.name +
              " " +
              history?.modifierUser?.lastname
            : history?.modifierUser?.email}
        </div>
      </div>
    );
  };

  const renderPriorityHistory = (history, index) => {
    const oldTicketsArray = ticketPriorities?.filter((th) => {
      return th.id == history.oldValue;
    });
    const oldPriority = oldTicketsArray[0]?.name;

    const newTicketsArray = ticketPriorities?.filter((th) => {
      //console.log(th)
      return th.id == history.newValue;
    });
    const newPriority = newTicketsArray[0]?.name;

    return (
      <div className="histo" key={index}>
        <h3>
          Priorité modifiée de {oldPriority ? oldPriority : "Aucun"} à{" "}
          {newPriority ? newPriority : "Aucun"}
        </h3>
        <div className="explication">
          {getLocaleDateTime(history?.created_at, user.timezone)} par{" "}
          {history?.modifierUser?.name
            ? history?.modifierUser?.name +
              " " +
              history?.modifierUser?.lastname
            : history?.modifierUser?.email}
        </div>
      </div>
    );
  };

  const renderResponsibleUserHistory = (history, index) => {
    if (levelEscatalatedUsers) {
      const oldTicketsArray = levelEscatalatedUsers?.filter((th) => {
        return th.id == history.oldValue;
      });
      const oldResponsible = oldTicketsArray[0]?.name;

      const newTicketsArray = levelEscatalatedUsers?.filter((th) => {
        return th.id == history.newValue;
      });
      const newResponsible = newTicketsArray[0]?.name;

      return (
        <div className="histo" key={index}>
          <h3>
            Responsable modifié de {oldResponsible ? oldResponsible : "Aucun"} à{" "}
            {newResponsible ? newResponsible : "Aucun"}
          </h3>
          <div className="explication">
            {getLocaleDateTime(history?.created_at, user.timezone)} par{" "}
            {history?.modifierUser?.name
              ? history?.modifierUser?.name +
                " " +
                history?.modifierUser?.lastname
              : history?.modifierUser?.email}
          </div>
        </div>
      );
    }
  };

  const renderTicketCreationHistory = (history, index) => {
    return (
      <div className="histo" key={index}>
        <h3>Créé</h3>
        <div className="explication">
          {getLocaleDateTime(history?.created_at, user.timezone)} par{" "}
          {history?.modifierUser?.name
            ? history?.modifierUser?.name +
              " " +
              history?.modifierUser?.lastname
            : history?.modifierUser?.email}
        </div>
      </div>
    );
  };

  const renderTicketMotifHistory = (history, index) => {
    const oldTicketsArray = ticketMotifs?.filter((th) => {
      return th.id == history.oldValue;
    });
    const oldMotif = oldTicketsArray[0]?.name;

    const newTicketsArray = ticketMotifs?.filter((th) => {
      return th.id == history.newValue;
    });
    const newMotif = newTicketsArray[0]?.name;

    return (
      <div className="histo" key={index}>
        <h3>
          Anomalie modifiée de {oldMotif ? oldMotif : "Aucun"} à{" "}
          {newMotif ? newMotif : "Aucun"}
        </h3>
        <div className="explication">
          {getLocaleDateTime(history?.created_at, user.timezone)} par{" "}
          {history?.modifierUser?.name
            ? history?.modifierUser?.name +
              " " +
              history?.modifierUser?.lastname
            : history?.modifierUser?.email}
        </div>
      </div>
    );
  };

  const renderTicketObjectHistory = (history, index) => {
    const oldTicketsArray = ticketObjects?.filter((th) => {
      return th.id == history.oldValue;
    });
    const oldObject = oldTicketsArray[0]?.name;

    const newTicketsArray = ticketObjects?.filter((th) => {
      return th.id == history.newValue;
    });
    const newObject = newTicketsArray[0]?.name;

    return (
      <div className="histo" key={index}>
        <h3>
          Objet du ticket modifié de {oldObject ? oldObject : "Aucun"} à{" "}
          {newObject ? newObject : "Aucun"}
        </h3>
        <div className="explication">
          {getLocaleDateTime(history?.created_at, user.timezone)} par{" "}
          {history?.modifierUser?.name
            ? history?.modifierUser?.name +
              " " +
              history?.modifierUser?.lastname
            : history?.modifierUser?.email}
        </div>
      </div>
    );
  };

  const renderTicketOriginHistory = (history, index) => {
    const oldTicketsArray = ticketOrigins?.filter((th) => {
      return th.id == history.oldValue;
    });
    const oldOrigin = oldTicketsArray[0]?.name;

    const newTicketsArray = ticketOrigins?.filter((th) => {
      return th.id == history.newValue;
    });
    const newOrigin = newTicketsArray[0]?.name;

    return (
      <div className="histo" key={index}>
        <h3>
          Origine du ticket modifié de {oldOrigin ? oldOrigin : "Aucun"} à{" "}
          {newOrigin ? newOrigin : "Aucun"}
        </h3>
        <div className="explication">
          {getLocaleDateTime(history?.created_at, user.timezone)} par{" "}
          {history?.modifierUser?.name
            ? history?.modifierUser?.name +
              " " +
              history?.modifierUser?.lastname
            : history?.modifierUser?.email}
        </div>
      </div>
    );
  };

  const renderHistory = (history, index) => {
    switch (history.field) {
      case "statut":
        return renderStatutHistory(history, index);
      case "priority":
        return renderPriorityHistory(history, index);
      case "responsible_user":
        return renderResponsibleUserHistory(history, index);
      case "ticket_object":
        return renderTicketObjectHistory(history, index);
      case "creation":
        return renderTicketCreationHistory(history, index);
      case "motif":
        return renderTicketMotifHistory(history, index);
      case "origin":
        return renderTicketOriginHistory(history, index);
      // add another fields here
      default:
        return null;
    }
  };

  const addComment = () => {
    let historyAdd = {
      modifierUser: user.id,
      ticket: ticketId,
      field: "comments",
      newValue: comment,
      sendToClient: true,
    };

    if (documentId > 0) {
      historyAdd.documents = [{ id: documentId }];
    }

    updateTicket({ id: ticketId, lastUpdatedBy: user.id }).then((result) => {
      addTicketHistory(historyAdd).then((result) => {
        setRefreshComments(!refreshComments);
        setComment("");
        //setOpen(false);
      });
    });
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  const handleDocumentChange = (e) => {
    setDocumentId(e.target.value[0]);
    //console.log("handleDocumentChange", documentId);
  };

  return (
    <>
      <div className="historySection">
        <div className="historique">
          <div className="title">
            <span>
              <RestoreIcon />
            </span>
            <span>Historique</span>
          </div>
          <div className="historiqueItem">
            {/* {lastComment ? renderCommentsHistory(lastComment) : ""} */}
            {ticketHistories?.map((th, index) => {
              return renderHistory(th, index);
            })}
          </div>
        </div>
      </div>

      <div className="commentSection">
        <TicketComments
          refresh={refreshComments}
          setRefresh={setRefreshComments}
          ticketId={ticketId}
        />
        <div className="ticketItem">
          <textarea
            className="textComment"
            value={comment}
            onChange={handleChangeComment}
            placeholder="Votre Commentaire ici..."
          ></textarea>
          <div className="btnTicket forCommentValidation">
            <div className="share-comment">
              <DocumentList
                handleChange={handleDocumentChange}
                disabled={disableAddComment}
                compactMode={true}
                add={true}
                param={docParam}
              />
            </div>

            <button
              className={!disableAddComment ? "validate" : ""}
              onClick={addComment}
              disabled={disableAddComment}
            >
              Ajouter Commentaire
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(TicketHistory);
