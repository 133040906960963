/**
 * UserSettingsNotification
 * Création : 29/03/2023
 * Page permettant à un utilisateur de paramétrer son compte (changement mot de passe, paramétrage notification, fuseau horaire)
 * Historique
 *      05/04/2023 Enregistrement paramétrage notification par mail
 * TODO
 *      05/04/2023 d'autres paramétrages de notification à demander au client 
 *          (par exp : notifier quand statut du ticket change, quand le ticket est cloturé, quand un autre ticket d'un autre collaborateur de la même société a été créé ..)
 */
import { connect } from "react-redux";
import mapStateToProps from "../../../redux/mapStateToProps";
import mapDispatchToProps from "../../../redux/mapDispatchToProps";

import { useState, useEffect } from "react";

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const UserSettingsNotification = (props) => {
    const {user, modifRessource, getMe, getNotificationTicketStatuts} = props;
    const [notification, setNotification] = useState(true);
    const [notifStatus, setNotifStatuts] = useState([]);
    const [notifCheckedStatus, setNotifCheckedStatuts] = useState([])
    const [allStatus, setAllStatus] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");

    useEffect(() => {
        setNotification(user.notif);
    },[user])

    useEffect(() => {
        if (notification){
            getNotificationTicketStatuts().then((statuts) => {
                setNotifStatuts(statuts.data)
            })
        }
        else {
            setAllStatus(false)
            setNotifCheckedStatuts([])
        }
    },[notification])

    useEffect(() => {
        setAllStatus(notifStatus.length === user.statut_notif.length);
        const checkedStatusId = user.statut_notif.map((sn) => {return sn.id})
        let temp = notifStatus.map((ns) => {
            const checked = checkedStatusId.includes(ns.id);
            return {...ns, checked: checked}
        });
        setNotifCheckedStatuts(temp)
    }, [notifStatus])

    useEffect(() => {
        var temp = notifCheckedStatus;
        temp = temp.map((ncs) => {
            return {...ncs, checked: allStatus};
        });
        setNotifCheckedStatuts([...temp])

    }, [allStatus])

    const handleChangeNotif = (event) => {
        setNotification(event.target.checked);
    }

    const handleChangeAllStatus = (event) => {
        setAllStatus(event.target.checked);
    }

    const handleChangeStatus = (event, statusId) => {
        const temp = notifCheckedStatus;
        const currentns = temp.filter(ncs => ncs.id === statusId) [0];
        currentns.checked = event.target.checked;

        setNotifCheckedStatuts([...temp])
    }

    const handleValidate = () => {
        
        const checkedStatus = notifCheckedStatus.filter(ncs => ncs.checked);
        modifRessource({
            id : user.id,
            notif : notification,
            statut_notif: checkedStatus
        }).then ((res) => {
            setInfoMessage("Vos paramètres de messagerie ont bien été enregistrés.");
            setTimeout(() => {
                setInfoMessage("")
            }, 3000)
            getMe();
        }).catch((err) => {
            console.log('error', err);
            setErrorMessage("Erreur lors de l'enregistrement de vos paramètres : " + err)
        })
    }

    return (
        <div className="contentRight">
            <h1 className="title">Paramètres de messagerie</h1>
            <Typography component="div">
                <Box sx={{ fontWeight: 'medium', mt: 5, mb: 4, fontSize: '14px' }}><b>Notification par e-mail : </b></Box>
            </Typography>
            <div className="receiveNotif">
                <FormControlLabel control={<Switch checked={notification} onChange={handleChangeNotif} size="small" />} label="Recevoir des notifications e-mail pour le changement de statut :" labelPlacement="start" />
            </div>
            {notification && 
                <div className="status-list">
                    <ul>
                        <li><FormControlLabel control={<Checkbox size="small" checked={allStatus} onChange={(event) => handleChangeAllStatus(event)} />} label="Tout changement de statut" labelPlacement="end" /></li>

                    {notifCheckedStatus && notifCheckedStatus.length > 0 &&
                        notifCheckedStatus.map((ns, index) => {
                            return <li key={index}><FormControlLabel 
                                            control={<Checkbox size="small" 
                                                        disabled={allStatus}
                                                        onChange={(event) => handleChangeStatus(event, ns.id)}
                                                        checked={ns['checked']} />} 
                                            label={ns.name} 
                                            labelPlacement="end" />
                                    </li>
                        })
                    }
                    </ul>
                </div>
            }
            <div className="text-right">
                <button className="btn primary" onClick={handleValidate}>Sauvegarder</button>
            </div>
            {
                infoMessage && <Alert severity="info">{infoMessage}</Alert>
            }
            {
                errorMessage && <Alert severity="warning">{errorMessage}</Alert>
            }
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsNotification);