/**
 * HeaderLayout
 * Création : 15/03/2023
 * Header de l'application, permet de naviguer entre les fonctionnalités principales (accueil, tickets, base de connaissance)
 *   et d'aller dans ses paramétrages
 * Historique
 *      DD/MM/YYYY   log
 */
import * as React from "react";
import { connect } from "react-redux";
import mapStateToProps from "../redux/mapStateToProps";
import mapDispatchToProps from "../redux/mapDispatchToProps";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import logoKardhamPng from "../assets/images/logoKardham.png";
import logoKardhamMobile from "../assets/images/logoMobile.png";
import searchBtn from "../assets/images/search.svg";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import { ReactComponent as ParamLogo } from "../assets/images/parametre.svg";
import { ReactComponent as Tickets } from "../assets/images/ticket.svg";
import { ReactComponent as LogOut } from "../assets/images/logout.svg";

import { Link } from "react-router-dom";

import MenuOpenIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddTicketDialog from "../pages/ticket/add-dialog";

import "./header-layout.scss";
import {
  TICKET_LIST_DEFAULT,
  TICKET_LIST_ME,
} from "../redux/reducers/ticketListReducers";
// import FormDialog from '../components/dialog/form-dialog';
// import { ticketParams } from '../pages/ticket';

const HeaderLayout = (props) => {
  const {
    currentUser,
    getOneCompany,
    company,
    setTicketList,
    user,
    getAllKnowledgeBasesBySearchKey,
    getAllTicketsBySearchKey,
  } = props;
  const [ownCompanyName, setOwnCompanyName] = useState("Kardham");
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [stateBody, setStateBody] = useState(false);
  //const [activeMenu, setActiveMenu] = React.useState(null)
  const open = Boolean(anchorEl);
  const location = useLocation();
  const searchInput = useRef();

  const menuIsInPath = (word) => {
    return location?.pathname.toLowerCase().includes(word?.toLowerCase());
  };
  const homeIsActive = () => {
    return (
      menuIsInPath("home") ||
      (!menuIsInPath("ticket") &&
        !menuIsInPath("solution") &&
        !menuIsInPath("user"))
    );
  };

  useEffect(() => {
    if (currentUser.company) {
      getOneCompany(currentUser.company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (company && company.name) {
      setOwnCompanyName(company.name);
    }
  }, [company]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const { logout } = props;

  const changeNav = (nav) => {
    handleClose();
    navigate("/" + nav);
    if (nav === "tickets/me") {
      setTicketList(TICKET_LIST_ME);
    }
    if (nav === "tickets" || nav === "tickets/own-company") {
      setTicketList(TICKET_LIST_DEFAULT);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    window.location.reload();
  };

 
  const openMenu = () => {
    setStateBody(!stateBody)
  };
  useEffect(() => {
    if (stateBody === false) {
      document.body.classList.add("openMenu");
    } else {
      document.body.classList.remove("openMenu");
    }
  }, [stateBody]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      //timeout pour ne déclencher la recherche qu'après 300 ms, pour être sur qu'il ne tape plus
      if (searchKey !== "") {
        //let results = [];

        getAllKnowledgeBasesBySearchKey(searchKey).then((kbs) => {
          //console.log('kbs', kbs)
          const kbResults = kbs.data.data.knowledgeBases.map((kb) => {
            return {
              type: `kb ${kb.knowledge_category.class}`,
              text: `[Solution] - ${kb.question}`,
              link: `solution/${kb.knowledge_category.id}/${kb.id}`,
            };
          });

          const companyId =
            currentUser?.role?.type.toLowerCase() === "admin"
              ? null
              : currentUser?.company;

          getAllTicketsBySearchKey(searchKey, companyId).then((tickets) => {
            //console.log('tickets', tickets)
            const ticketResults = tickets.map((ticket) => {
              return {
                type: "ticket",
                text: `[Ticket #${ticket.id}] - ${ticket.objet}`,
                link: `ticket/${ticket.id}`,
              };
            });

            if (searchInput.current.value !== "") {
              setSearchResults([...kbResults, ...ticketResults]);
            }
          });
        });
      } else if (searchKey === "") {
        setSearchResults([]);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const handleCloseAddTicketModal = () => {
    setTicketModalOpen(false);
  };
  const handleClickAddTicket = () => {
    setTicketModalOpen(true);
  };

  const handleSearchClick = () => {
    if (!isSearchOpen) searchInput.current.focus();
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearchChange = (e) => {
    setSearchKey(e.target.value);
  };

  const goto = (nav) => {
    changeNav(nav);
    setSearchKey("");
    setSearchResults([]);
    handleSearchClick();
  };

  const handleSelectMobileMenu = (menu) => {
    setStateBody(true);
    changeNav(menu);
  }

  return (
    <header>
      <div className="menuHautHeader">
        <div className="containerPrincipal">
          <div className="cttLogo">
            <img
              className="desktop"
              src={logoKardhamPng}
              alt="desktop-kardham-logo"
            />
            <img
              className="mobile"
              src={logoKardhamMobile}
              alt="mobile-kardham-logo"
            />
          </div>
          <div className="allLinkMenu">
            <div className={`search ${isSearchOpen ? "open" : ""}`}>
              <Button className="searchbtn" onClick={handleSearchClick}>
                <img src={searchBtn} alt="searchBtn" />
              </Button>
              <div className={`search-input-container`}>
                <input
                  className="search-input"
                  type="text"
                  value={searchKey}
                  placeholder="Chercher un ticket ou une solution.."
                  onChange={handleSearchChange}
                  ref={searchInput}
                />
              </div>
              {isSearchOpen && searchResults.length > 0 && (
                <div className="result">
                  <ul>
                    {searchResults?.map((result, index) => {
                      return (
                        <li
                          key={index}
                          className={`${result.type}`}
                          onClick={() => goto(result.link)}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: result.text.replace(
                                searchKey,
                                `<b>${searchKey}</b>`
                              ),
                            }}
                          ></div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>

            <div>
              <Button
                id="basic-button"
                className="menuUser"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <div className="achron">
                  {currentUser?.username?.substring(0, 1)}
                </div>
                {currentUser?.username}
                <KeyboardArrowDownOutlinedIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {user?.role?.type === "admin" ||
                company?.name?.toLowerCase()?.includes("kardham") ? (
                  ""
                ) : (
                  <>
                    <MenuItem className="itemDrop">
                      <Tickets />
                      Tickets
                    </MenuItem>
                    <MenuItem
                      onClick={() => changeNav("tickets/me")}
                      className="itemDrop"
                    >
                      Créés par moi
                    </MenuItem>
                    <MenuItem
                      onClick={() => changeNav("tickets/own-company")}
                      className="itemDrop"
                    >
                      {ownCompanyName}
                    </MenuItem>
                    <Divider />
                  </>
                )}

                <MenuItem
                  onClick={() => changeNav("user/settings")}
                  className="itemDrop mesParam"
                >
                  <ParamLogo />
                  Mes paramètres
                </MenuItem>
                <MenuItem
                  onClick={() => handleLogout()}
                  className="itemDrop logOut"
                >
                  <LogOut />
                  Déconnexion
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <nav className="menuBasHeader">
        <div className="containerPrincipal">
          <ul className="menuForDesktopMobile forDesktop">
            <li>
              <button
                className={homeIsActive() ? "active" : ""}
                onClick={() => changeNav("home")}
              >
                Accueil
              </button>
            </li>
            <li>
              <button
                className={menuIsInPath("ticket") ? "active" : ""}
                onClick={() => changeNav("tickets")}
              >
                Tickets
              </button>
            </li>
            <li>
              <button
                className={menuIsInPath("solution") ? "active" : ""}
                onClick={() => changeNav("solution")}
              >
                Solutions
              </button>
            </li>
            {currentUser.role?.type === "admin" && (
              <li>
                <button
                  className={menuIsInPath("user") ? "active" : ""}
                  onClick={() => changeNav("user")}
                >
                  Utilisateurs
                </button>
              </li>
            )}
            {user?.role?.type === "admin" ||
            company?.name?.toLowerCase()?.includes("kardham")
              ? ""
              : user?.id && (
                  <li className="createTicket">
                    <Button
                      onClick={handleClickAddTicket}
                      className="btn primary save addTicket"
                    >
                      <AddIcon />
                      <span className="txtTicket"> Créer ticket</span>
                    </Button>
                  </li>
                )}
          </ul>
          <div className="menuForMobile">
            <Link to="#" className="forMobile" onClick={openMenu}>
              <div className="headMobile">
                <MenuOpenIcon />
              </div>
            </Link>
            <div className="containerMenuForMobile">
              <ul>
                <li onClick={() => handleSelectMobileMenu("home")}>
                  <HomeOutlinedIcon /> Accueil
                </li>
                <li onClick={() => handleSelectMobileMenu("tickets")}>
                  <ConfirmationNumberOutlinedIcon /> Tickets
                </li>
                <li onClick={() => handleSelectMobileMenu("solution")}>
                  <TipsAndUpdatesOutlinedIcon /> Solutions
                </li>
                {currentUser.role?.type === "admin" && (
                  <li onClick={() => handleSelectMobileMenu("user")}>
                    <AccountCircleOutlinedIcon /> Utilisateurs
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* <FormDialog 
                open={ticketModalOpen} 
                onClose={handleCloseAddTicketModal} 
                title={'NOUVEAU TICKET'} 
                fields={ticketParams?.fields.filter((f) => f.add)}
                // handler={handler}
                // data={defaultDefinedData? {...currentRow,...defaultDefinedData} : currentRow}
                // withOrder={orderedFields}
                // afterAdd={afterAdd && addMode ? afterAdd: null}
            /> */}
      {ticketModalOpen && (
        <div>
          <AddTicketDialog
            onClose={handleCloseAddTicketModal}
            open={ticketModalOpen}
          />
        </div>
      )}
    </header>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
