import axios from "axios";

export const GET_ALL_TICKET_PRIORITY = "GET_ALL_TICKET_PRIORITY";
export const ADD_TICKET_PRIORITY = "ADD_TICKET_PRIORITY";
export const MODIF_TICKET_PRIORITY = "MODIF_TICKET_PRIORITY";
export const DELETE_TICKET_PRIORITY = "DELETE_TICKET_PRIORITY";
export const GET_ONE_TICKET_PRIORITY = "GET_ONE_TICKET_PRIORITY";


export const getAllTicketPriorities = () => (dispatch) => {
    try {
        return axios.get("/ticket-priorities")
                    .then(cli => {
                        dispatch({ type: GET_ALL_TICKET_PRIORITY, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL TICKET PRIORITY ", error);
    }
};

export const addTicketPriority = (ticketPriorityState, history) => (dispatch) => {
    try {
        return axios.post("/ticket-priorities", ticketPriorityState)
                    .then(cli => {
                        dispatch({ type: ADD_TICKET_PRIORITY, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT TICKET PRIORITY ", error);
    }
  };
  
  export const updateTicketPriority = (ticketPriorityState) => (dispatch) => {
    try {
        return axios.put(`/ticket-priorities/${ticketPriorityState.id}`, ticketPriorityState)
                    .then(cli => {
                        dispatch({ type: MODIF_TICKET_PRIORITY, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION TICKET PRIORITY ", error);
    }
  };
  
  export const deleteTicketPriority = (id) => (dispatch) => {
    try {
        return axios.delete(`/ticket-priorities/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_TICKET_PRIORITY, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE TICKET PRIORITY ", error);
    }
  };
  
  export const getOneTicketPriority = (id) => (dispatch) => {
    try{
        return axios.get(`/ticket-priorities/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_TICKET_PRIORITY, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
  };