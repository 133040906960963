import {
  getMe,
  registerUser,
  loginUser,
  logout,
  getAllUsers,
  modifRessource,
  supprimRessource,
  addUser,
  getOneUser,
  forgotPassword,
  resetPassword,
  customResetPassword,
  emailConfirmation
} from "./actions/usersAction";

import { getPortailRoles } from "./actions/rolesAction";

import {
  getAllCompanies,
  getOneCompany,
  getOneCompany_,
  updateCompany,
} from "./actions/companyAction";

import {
  getAllCompanyTypes,
  addCompanyType,
  updateCompanyType,
  deleteCompanyType,
  getOneCompanyType,
} from "./actions/companyTypeAction";

import {
  getAllDocumentTypes,
  addDocumentType,
  updateDocumentType,
  deleteDocumentType,
  getOneDocumentType,
} from "./actions/documentTypeAction";

import {
  getAllDocuments,
  addDocument,
  updateDocument,
  deleteDocument,
  getOneDocument,
  getAllDocumentsByMaterial,
  getAllDocumentsByContract,
  getAllDocumentsByTicket,
} from "./actions/documentAction";

import {
  getAllContacts,
  getOneContact,
  getAllContactsByCompany,
  getOwnCompanyContacts,
  //addOwnCompanyContact,
  getKardhamContact,
  getAllContactsByCompanyAsync,
} from "./actions/contactAction";

import {
  addTicket,
  getAllTickets,
  getOneTicket,
  updateTicket,
  getOneTicketGraphql,
  deleteTicket,
  getAllTicketsGraphql,
  getFilteredTicketsGraphql,
  getCompanyTickets,
  getAllTicketsBySearchKey,
  selectTicket,
  getAllTicketsNotFiltered,
} from "./actions/ticketAction";

import {
  addTicketType,
  deleteTicketType,
  getAllTicketTypes,
  getOneTicketType,
  updateTicketType,
} from "./actions/ticketTypeAction";

import {
  getAllTicketObjects,
  addTicketObject,
  updateTicketObject,
  deleteTicketObject,
  getOneTicketObject,
} from "./actions/ticketObjectAction";

import {
  getAllTicketPriorities,
  addTicketPriority,
  updateTicketPriority,
  deleteTicketPriority,
  getOneTicketPriority,
} from "./actions/ticketPriorityAction";

import {
  getAllTicketStatuts,
  addTicketStatut,
  updateTicketStatut,
  deleteTicketStatut,
  getOneTicketStatut,
  getNotificationTicketStatuts,
} from "./actions/ticketStatutAction";

import {
  getAllTicketMotifs,
  addTicketMotif,
  updateTicketMotif,
  deleteTicketMotif,
  getOneTicketMotif,
} from "./actions/ticketMotifAction";

import {
  getAllTicketOrigins,
  addTicketOrigin,
  updateTicketOrigin,
  deleteTicketOrigin,
  getOneTicketOrigin,
} from "./actions/ticketOriginAction";

import {
  getAllTicketHistories,
  addTicketHistory,
  updateTicketHistory,
  deleteTicketHistory,
  getOneTicketHistory,
  getAllTicketHistoriesByTicket,
  getAllTicketCommentsByTicket,
  getLastCommentByTicket,
} from "./actions/ticketHistoryAction";

import {
  getAllKnowledgeBases,
  addKnowledgeBase,
  updateKnowledgeBase,
  deleteKnowledgeBase,
  getOneKnowledgeBase,
  getAllKnowledgeBasesByCategory,
  getAllKnowledgeBasesBySearchKey,
} from "./actions/knowledgeBaseAction";

import {
  getAllKnowledgeCategories,
  addKnowledgeCategory,
  updateKnowledgeCategory,
  deleteKnowledgeCategory,
  getOneKnowledgeCategory,
} from "./actions/knowledgeCategoryAction";

import { setNeedRefresh } from "./actions/navigationAction";

import { getConfig, updateConfig } from "./actions/configAction";

import { sendMail } from "./actions/mailAction";

import { uploadFile, downloadFile } from "./actions/fileAction";
import { getOneCompanyMaterials } from "./actions/materialAction";
import { getAllSitesByCompany } from "./actions/siteAction";
import { getAllBuildingsBySite } from "./actions/buildingAction";
import { getAllRoomsByBuilding } from "./actions/roomAction";
import { getAllEscalationGroups } from "./actions/escalationGroupAction";
import { setTicketList } from "./actions/ticketListAction";
import {
  addTicketConsultation,
  deleteTicketConsultation,
  getAllTicketConsultation,
  getTicketConsultationsByUser,
} from "./actions/ticketConsultationAction";

const mapDispatchToProps = (dispatch) => ({
  //user
  getMe: () => dispatch(getMe()),
  registerUser: (userState, history) =>
    dispatch(registerUser(userState, history)),
  loginUser: (logState, history) => dispatch(loginUser(logState, history)),
  logout: (history) => dispatch(logout(history)),
  getAllUsers: () => dispatch(getAllUsers()),
  modifRessource: (ressourceState) => dispatch(modifRessource(ressourceState)),
  supprimRessource: (id) => dispatch(supprimRessource(id)),
  addUser: (userState, history) => dispatch(addUser(userState, history)),
  getOneUser: (id) => dispatch(getOneUser(id)),
  forgotPassword: (logState) => dispatch(forgotPassword(logState)),
  resetPassword: (logState) => dispatch(resetPassword(logState)),
  customResetPassword: (logState) => dispatch(customResetPassword(logState)),
  emailConfirmation:(confirmationToken) => dispatch(emailConfirmation(confirmationToken)),

  // role
  getPortailRoles: () => dispatch(getPortailRoles()),

  // company
  getAllCompanies: (async_ = false) => dispatch(getAllCompanies(async_)),
  getOneCompany: (id) => dispatch(getOneCompany(id)),
  getOneCompany_: (id) => dispatch(getOneCompany_(id)),
  getCompanyTickets: (companyId) => dispatch(getCompanyTickets(companyId)),
  updateCompany: (companyState) => dispatch(updateCompany(companyState)),

  // companyType
  getAllCompanyTypes: () => dispatch(getAllCompanyTypes()),
  addCompanyType: (companyTypeState, history) =>
    dispatch(addCompanyType(companyTypeState, history)),
  updateCompanyType: (companyTypeState) =>
    dispatch(updateCompanyType(companyTypeState)),
  deleteCompanyType: (id) => dispatch(deleteCompanyType(id)),
  getOneCompanyType: (id) => dispatch(getOneCompanyType(id)),

  // documentType
  getAllDocumentTypes: () => dispatch(getAllDocumentTypes()),
  addDocumentType: (serviceState, history) =>
    dispatch(addDocumentType(serviceState, history)),
  updateDocumentType: (serviceState) =>
    dispatch(updateDocumentType(serviceState)),
  deleteDocumentType: (id) => dispatch(deleteDocumentType(id)),
  getOneDocumentType: (id) => dispatch(getOneDocumentType(id)),

  // document
  getAllDocuments: () => dispatch(getAllDocuments()),
  addDocument: (serviceState, history) =>
    dispatch(addDocument(serviceState, history)),
  updateDocument: (serviceState) => dispatch(updateDocument(serviceState)),
  deleteDocument: (id) => dispatch(deleteDocument(id)),
  getOneDocument: (id) => dispatch(getOneDocument(id)),
  getAllDocumentsByMaterial: (materialId) =>
    dispatch(getAllDocumentsByMaterial(materialId)),
  getAllDocumentsByContract: (contractId) =>
    dispatch(getAllDocumentsByContract(contractId)),
  getAllDocumentsByTicket: (ticketId) =>
    dispatch(getAllDocumentsByTicket(ticketId)),

  // contact
  getAllContacts: () => dispatch(getAllContacts()),
  getOneContact: (id) => dispatch(getOneContact(id)),
  getAllContactsByCompany: (companyId) =>
    dispatch(getAllContactsByCompany(companyId)),
  getOwnCompanyContacts: () => dispatch(getOwnCompanyContacts()),
  getKardhamContact: () => dispatch(getKardhamContact()),
  getAllContactsByCompanyAsync: (companyId) =>
    dispatch(getAllContactsByCompanyAsync(companyId)),

  // ticket
  getAllTickets: () => dispatch(getAllTickets()),
  getOneTicket: (id) => dispatch(getOneTicket(id)),
  getOneTicketGraphql: (id) => dispatch(getOneTicketGraphql(id)),
  addTicket: (ticketState, history) =>
    dispatch(addTicket(ticketState, history)),
  updateTicket: (ticketState) => dispatch(updateTicket(ticketState)),
  deleteTicket: (id) => dispatch(deleteTicket(id)),
  getAllTicketsGraphql: () => dispatch(getAllTicketsGraphql()),
  getFilteredTicketsGraphql: (filter, idComp) =>
    dispatch(getFilteredTicketsGraphql(filter, idComp)),
  getAllTicketsBySearchKey: (searchKey, companyID) =>
    dispatch(getAllTicketsBySearchKey(searchKey, companyID)),
  selectTicket: (ticket) => dispatch(selectTicket(ticket)),
  getAllTicketsNotFiltered: () => dispatch(getAllTicketsNotFiltered()),

  //ticket priority
  getAllTicketPriorities: () => dispatch(getAllTicketPriorities()),
  addTicketPriority: (ticketPriorityState, history) =>
    dispatch(addTicketPriority(ticketPriorityState, history)),
  updateTicketPriority: (ticketPriorityState) =>
    dispatch(updateTicketPriority(ticketPriorityState)),
  deleteTicketPriority: (id) => dispatch(deleteTicketPriority(id)),
  getOneTicketPriority: (id) => dispatch(getOneTicketPriority(id)),

  // ticket statut
  getAllTicketStatuts: () => dispatch(getAllTicketStatuts()),
  addTicketStatut: (ticketStatutState, history) =>
    dispatch(addTicketStatut(ticketStatutState, history)),
  updateTicketStatut: (ticketStatutState) =>
    dispatch(updateTicketStatut(ticketStatutState)),
  deleteTicketStatut: (id) => dispatch(deleteTicketStatut(id)),
  getOneTicketStatut: (id) => dispatch(getOneTicketStatut(id)),
  getNotificationTicketStatuts: () => dispatch(getNotificationTicketStatuts()),

  //ticketTypes
  getAllTicketTypes: () => dispatch(getAllTicketTypes()),
  addTicketType: (ticketTypeState, history) =>
    dispatch(addTicketType(ticketTypeState, history)),
  updateTicketType: (ticketTypeState) =>
    dispatch(updateTicketType(ticketTypeState)),
  deleteTicketType: (id) => dispatch(deleteTicketType(id)),
  getOneTicketType: (id) => dispatch(getOneTicketType(id)),

  //ticketObjects
  getAllTicketObjects: () => dispatch(getAllTicketObjects()),
  addTicketObject: (ticketObjectState, history) =>
    dispatch(addTicketObject(ticketObjectState, history)),
  updateTicketObject: (ticketObjectState) =>
    dispatch(updateTicketObject(ticketObjectState)),
  deleteTicketObject: (id) => dispatch(deleteTicketObject(id)),
  getOneTicketObject: (id) => dispatch(getOneTicketObject(id)),

  // ticket motif
  getAllTicketMotifs: () => dispatch(getAllTicketMotifs()),
  addTicketMotif: (ticketMotifState, history) =>
    dispatch(addTicketMotif(ticketMotifState, history)),
  updateTicketMotif: (ticketMotifState) =>
    dispatch(updateTicketMotif(ticketMotifState)),
  deleteTicketMotif: (id) => dispatch(deleteTicketMotif(id)),
  getOneTicketMotif: (id) => dispatch(getOneTicketMotif(id)),

  // ticket origin
  getAllTicketOrigins: () => dispatch(getAllTicketOrigins()),
  addTicketOrigin: (ticketOriginState, history) =>
    dispatch(addTicketOrigin(ticketOriginState, history)),
  updateTicketOrigin: (ticketOriginState) =>
    dispatch(updateTicketOrigin(ticketOriginState)),
  deleteTicketOrigin: (id) => dispatch(deleteTicketOrigin(id)),
  getOneTicketOrigin: (id) => dispatch(getOneTicketOrigin(id)),

  //navigation
  setNeedRefresh: (needRefresh) => dispatch(setNeedRefresh(needRefresh)),

  // ticket history
  getAllTicketHistories: () => dispatch(getAllTicketHistories()),
  addTicketHistory: (ticketHistoryState, history) =>
    dispatch(addTicketHistory(ticketHistoryState, history)),
  updateTicketHistory: (ticketHistoryState) =>
    dispatch(updateTicketHistory(ticketHistoryState)),
  deleteTicketHistory: (id) => dispatch(deleteTicketHistory(id)),
  getOneTicketHistory: (id) => dispatch(getOneTicketHistory(id)),
  getAllTicketHistoriesByTicket: (ticketId) =>
    dispatch(getAllTicketHistoriesByTicket(ticketId)),
  getAllTicketCommentsByTicket: (ticketId) =>
    dispatch(getAllTicketCommentsByTicket(ticketId)),
  getLastCommentByTicket: (ticketId) =>
    dispatch(getLastCommentByTicket(ticketId)),

  //configData
  getConfigData: (callback) => dispatch(getConfig(callback)),
  updateConfigData: (configData, callback) =>
    dispatch(updateConfig(configData, callback)),

  //mail
  sendMail: (options) => dispatch(sendMail(options)),

  // knowledgeBase
  getAllKnowledgeBases: (async_ = false) =>
    dispatch(getAllKnowledgeBases(async_)),
  addKnowledgeBase: (knowledgeBaseState, history, callback) =>
    dispatch(addKnowledgeBase(knowledgeBaseState, history, callback)),
  updateKnowledgeBase: (knowledgeBaseState) =>
    dispatch(updateKnowledgeBase(knowledgeBaseState)),
  deleteKnowledgeBase: (id) => dispatch(deleteKnowledgeBase(id)),
  getOneKnowledgeBase: (id) => dispatch(getOneKnowledgeBase(id)),
  getAllKnowledgeBasesByCategory: (idCategory) =>
    dispatch(getAllKnowledgeBasesByCategory(idCategory)),
  getAllKnowledgeBasesBySearchKey: (searchKey) =>
    dispatch(getAllKnowledgeBasesBySearchKey(searchKey)),

  // knowledgeCategory
  getAllKnowledgeCategories: (async_ = false) =>
    dispatch(getAllKnowledgeCategories(async_)),
  addKnowledgeCategory: (knowledgeCategoryState, history, callback) =>
    dispatch(addKnowledgeCategory(knowledgeCategoryState, history, callback)),
  updateKnowledgeCategory: (knowledgeCategoryState) =>
    dispatch(updateKnowledgeCategory(knowledgeCategoryState)),
  deleteKnowledgeCategory: (id) => dispatch(deleteKnowledgeCategory(id)),
  getOneKnowledgeCategory: (id) => dispatch(getOneKnowledgeCategory(id)),

  //file
  uploadFile: (formData) => dispatch(uploadFile(formData)),
  downloadFile: (fileId) => dispatch(downloadFile(fileId)),

  //materials
  getOneCompanyMaterials: (compID) => dispatch(getOneCompanyMaterials(compID)),

  //site
  getAllSitesByCompany: (compID) => dispatch(getAllSitesByCompany(compID)),

  //
  getAllBuildingsBySite: (siteID) => dispatch(getAllBuildingsBySite(siteID)),

  getAllRoomsByBuilding: (buildingID) =>
    dispatch(getAllRoomsByBuilding(buildingID)),

  getAllEscalationGroups: () => dispatch(getAllEscalationGroups()),

  //ticketListState
  setTicketList: (state) => dispatch(setTicketList(state)),

  //ticketConsultation
  getAllTicketConsultation: () => dispatch(getAllTicketConsultation()),
  addTicketConsultation: (ticketConsultation) =>
    dispatch(addTicketConsultation(ticketConsultation)),
  getTicketConsultationsByUser: (userID) =>
    dispatch(getTicketConsultationsByUser(userID)),
  deleteTicketConsultation: (consultation) =>
    dispatch(deleteTicketConsultation(consultation)),
});

export default mapDispatchToProps;
