import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import OneFilter from "./one-filter";

const AddFilter = (props) => {

    const handleAddNewFilter = (newfilter) => {
        if (props.onFilterAdded){
            props.onFilterAdded(newfilter);
        }
    }

    return (
        <div className="addFilter">
            <ul>
                <li>
                    <OneFilter allFields={props.fields} canAdd={true} onAddNewFilter={handleAddNewFilter} />
                </li>
            </ul>
            
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AddFilter);