import {
  GET_ALL_TICKET_STATUT,
} from "../actions/ticketStatutAction";

const initialState = {
  ticketStatuts: [],
  ticketStatut: {}
};

export function ticketStatutReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TICKET_STATUT:
      return { ...state, ticketStatuts: action.payload };

    default:
      return { ...state };
  }
}
