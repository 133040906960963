import {
  GET_ALL_DOCUMENT,
  MODIF_DOCUMENT,
  DELETE_DOCUMENT,
  ADD_DOCUMENT,
  GET_ONE_DOCUMENT,
  GET_ALL_DOCUMENT_BY_MATERIAL_GRAPHQL,
  GET_ALL_DOCUMENT_BY_CONTRACT_GRAPHQL,
  GET_ALL_DOCUMENT_BY_TICKET_GRAPHQL
} from "../actions/documentAction";

const initialState = {
  documents: [],
};

export function documentReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DOCUMENT:
      return { ...state, documents: action.payload };

    case ADD_DOCUMENT:
      return { ...state, document: action.payload };

    case MODIF_DOCUMENT:
      return { ...state };

    case DELETE_DOCUMENT:
      return { ...state };

    case GET_ONE_DOCUMENT:
      return { documents: action.payload };

    case GET_ALL_DOCUMENT_BY_MATERIAL_GRAPHQL:
      return { ...state, documents: action.payload };      
      
    case GET_ALL_DOCUMENT_BY_CONTRACT_GRAPHQL:
      return { ...state, documents: action.payload };        

    case GET_ALL_DOCUMENT_BY_TICKET_GRAPHQL:
      return { ...state, documents: action.payload };

    default:
      return { ...state };
  }
}
