import axios from "axios";

export const GET_ALL_COMPANYTYPE = "GET_ALL_COMPANYTYPE";
export const ADD_COMPANYTYPE = "ADD_COMPANYTYPE";
export const MODIF_COMPANYTYPE = "MODIF_COMPANYTYPE";
export const DELETE_COMPANYTYPE = "DELETE_COMPANYTYPE";
export const GET_ONE_COMPANYTYPE = "GET_ONE_COMPANYTYPE";

export const getAllCompanyTypes = () => (dispatch) => {
    try {
        return axios.get("/company-types")
                    .then(cli => {
                        dispatch({ type: GET_ALL_COMPANYTYPE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL COMPANYTYPE ", error);
    }
};

export const addCompanyType = (companyTypeState, history) => (dispatch) => {
    try {
        return axios.post("/company-types", companyTypeState)
                    .then(cli => {
                        dispatch({ type: ADD_COMPANYTYPE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT COMPANYTYPE ", error);
    }
};

export const updateCompanyType = (companyTypeState) => (dispatch) => {
    try {
        return axios.put(`/company-types/${companyTypeState.id}`, companyTypeState)
                    .then(cli => {
                        dispatch({ type: MODIF_COMPANYTYPE, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION COMPANYTYPE ", error);
    }
};

export const deleteCompanyType = (id) => (dispatch) => {
    try {
        return axios.delete(`/company-types/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_COMPANYTYPE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE COMPANYTYPE ", error);
    }
};

export const getOneCompanyType = (id) => (dispatch) => {
    try{
        return axios.get(`/company-types/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_COMPANYTYPE, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};