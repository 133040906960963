import {
  GET_ALL_COMPANY,
  GET_ONE_COMPANY,
  MODIF_COMPANY,
} from "../actions/companyAction";

const initialState = {
  companies: [],
  company: {},
};

export function companyReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COMPANY:
      return { ...state, companies: action.payload };

    case GET_ONE_COMPANY:
      return { company: action.payload };

    case MODIF_COMPANY:
      return { ...state };

    default:
      return { ...state };
  }
}
