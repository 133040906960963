
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import './input-switch-field.scss';
/*
            <FormControlLabel control={} label={param.headerName} labelPlacement="start" />
*/
const InputSwitchField = (props) => {
    const {param, handleChange, value} = props;

    return (
        <FormControlLabel control={<Switch defaultChecked={value} onChange={handleChange} name={param.field} size="small" />} label={param.headerName} labelPlacement="start" />
        
    )
}
export default InputSwitchField;