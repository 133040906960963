import axios from "axios";
import fileDownload from 'js-file-download';

export const UPLOAD_FILE = "UPLOAD_FILE";

export const uploadFile = (formData) => (dispatch) => {
    try {
        console.log('uploading formData', formData);
        return axios.post("/upload", formData);

    } catch (error) {
        return console.log("ERREUR UPLOAD FILE ", error);
    }
};

export const downloadFile = (fileId) => (dispatch) => {
    try{
        return axios.get(`/upload/files/${fileId}`)
                    .then(rep => {
                        console.log(rep);
                        axios.get(`${rep.data.url}`, {
                            responseType: 'blob',
                        }).then((res) => {
                            fileDownload(res.data, `${rep.data.name}`);
                        });                        
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }    
}
