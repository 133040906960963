import {
  GET_ALL_TICKET_PRIORITY,
  ADD_TICKET_PRIORITY,
  MODIF_TICKET_PRIORITY,
  DELETE_TICKET_PRIORITY,
  GET_ONE_TICKET_PRIORITY
} from "../actions/ticketPriorityAction";

const initialState = {
  ticketPriorities: [],
  ticketPriority: {}
};

export function ticketPriorityReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TICKET_PRIORITY:
      return { ...state, ticketPriorities: action.payload };
    
      case ADD_TICKET_PRIORITY:
        return { ...state, ticketPriority: action.payload };

    case MODIF_TICKET_PRIORITY:
        return { ...state };

    case DELETE_TICKET_PRIORITY:
        return { ...state };

    case GET_ONE_TICKET_PRIORITY:
        return { ticketPriority: action.payload };

    default:
        return { ...state };
  }
}
