import {
  ADD_TICKET,
  GET_ALL_TICKET,
  GET_ONE_TICKET,
  MODIF_TICKET,
  DELETE_TICKET,
  GET_ALL_TICKET_NO_FILTER
} from "../actions/ticketAction";

const initialState = {
  tickets: [],
  ticket: {},
  all: []
};

export function ticketReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TICKET:
      return { ...state, tickets: action.payload };

    case GET_ONE_TICKET:
      return { ticket: action.payload };

    case ADD_TICKET:
      return { ...state, ticket: action.payload };

    case MODIF_TICKET:
        return { ...state, ticket: action.payload };

    case DELETE_TICKET:
      return { ...state };

    case GET_ALL_TICKET_NO_FILTER:
      return {...state, all: action.payload};
  
    default:
      return { ...state };
  }
}
