import axios from "axios";

export const GET_ALL_TICKET_MOTIF = "GET_ALL_TICKET_MOTIF";
export const ADD_TICKET_MOTIF = "ADD_TICKET_MOTIF";
export const MODIF_TICKET_MOTIF = "MODIF_TICKET_MOTIF";
export const DELETE_TICKET_MOTIF = "DELETE_TICKET_MOTIF";
export const GET_ONE_TICKET_MOTIF = "GET_ONE_TICKET_MOTIF";

export const getAllTicketMotifs = () => (dispatch) => {
  try {
      return axios.get("/ticket-motifs")
                  .then(cli => {
                      dispatch({ type: GET_ALL_TICKET_MOTIF, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR GET ALL COMPANYMOTIF ", error);
  }
};

export const addTicketMotif = (ticketMotifState, history) => (dispatch) => {
  try {
      return axios.post("/ticket-motifs", ticketMotifState)
                  .then(cli => {
                      dispatch({ type: ADD_TICKET_MOTIF, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR AJOUT COMPANYMOTIF ", error);
  }
};

export const updateTicketMotif = (ticketMotifState) => (dispatch) => {
  try {
      return axios.put(`/ticket-motifs/${ticketMotifState.id}`, ticketMotifState)
                  .then(cli => {
                      dispatch({ type: MODIF_TICKET_MOTIF, payload: cli.data })
                  });
  } catch (error) {
      return console.log("ERREUR MODIFICATION COMPANYMOTIF ", error);
  }
};

export const deleteTicketMotif = (id) => (dispatch) => {
  try {
      return axios.delete(`/ticket-motifs/${id}`)
                  .then((cli) => {
                      dispatch({ type: DELETE_TICKET_MOTIF, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR DELETE COMPANYMOTIF ", error);
  }
};

export const getOneTicketMotif = (id) => (dispatch) => {
  try{
      return axios.get(`/ticket-motifs/${id}`)
                  .then(rep => {
                      dispatch({ type: GET_ONE_TICKET_MOTIF, payload: rep.data });
                  });
  } catch (error) {
      return console.log("ERREUR ", error);
  }
};