import axios from "axios";

export const GET_ALL_BUILDING = "GET_ALL_BUILDING";
export const GET_ONE_BUILDING = "GET_ONE_BUILDING";
export const GET_ONE_BUILDING_GRAPHQL = "GET_ONE_BUILDING_GRAPHQL";
export const GET_ALL_BUILDING_GRAPHQL = "GET_ALL_BUILDING_GRAPHQL";
export const GET_ALL_BUILDING_BY_COMPANY_GRAPHQL = "GET_ALL_BUILDING_BY_COMPANY_GRAPHQL";

export const getAllBuildings = () => (dispatch) => {
    try {
        return axios.get("/buildings")
                    .then(cli => {
                        dispatch({ type: GET_ALL_BUILDING, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL BUILDING ", error);
    }
};

export const getOneBuilding = (id) => (dispatch) => {
    try{
        return axios.get(`/buildings/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_BUILDING, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const getOneBuildingGraphql = (id) => (dispatch) => {
    try{
        const querya = `query BuildingDetails($bid: ID!) {
                building(id: $bid) {
                    id
                    name
                    site {
                        id
                        name
                        company {
                            id 
                            name 
                        }
                    }
                }
            }`;
        const variablesa = { "bid" : id }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body)
        .then((result) => {
            dispatch({ type: GET_ONE_BUILDING_GRAPHQL, payload: result.data.data.building });
        });
    } catch (error) {
        return console.log("ERREUR GET_ONE_BUILDING_GRAPHQL", error);
    }
};

export const getAllBuildingsBySite = (siteId) => (dispatch) => {
    try {
        const querya = `query getBuildings($sid:ID!){
            buildings (where : {site : {id : $sid}}) {
                id
                name
                site {
                    id
                    name
                }
            }
        }`;
        const variablesa = { "sid" : siteId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL BUILDING ", error);
    }
};

export const getAllBuildingsByCompany = (companyId) => (dispatch) => {
    try {
        const querya = `query getBuildings($cid:ID!){
            buildings (where : {site : { company : { id : $cid }}}) {
              id
              name
              site {
                  id
                  name
              }
            }
        }`;
        const variablesa = { "cid" : companyId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL BUILDING ", error);
    }
};

export const addBuildingSync = (buildingState) => (dispatch) => {
    try {
        return axios.post("/buildings", buildingState);
    } catch (error) {
        return console.log("ERREUR AJOUT BUILDING ", error);
    }
};

export const getAllBuildingsSync = () => (dispatch) => {
    try {
        return axios.get("/buildings");
    } catch (error) {
        return console.log("ERREUR GET ALL BUILDING ", error);
    }
};

export const importBuildingData = (siteData) => {
    try {
        
        if (window.location.origin.indexOf("localhost") !== -1){
            return axios.post("http://localhost/gp/import-building.php", siteData,{
                headers: { 'Content-Type': 'application/json;charset=UTF-8' }
            });
        }
        else {
            return axios.post(window.location.origin + "/import-building.php", siteData,{
                headers: { 'Content-Type': 'application/json' }
            });
        }
    } catch (error) {
        return console.log("importBuilding", error);
    }
};