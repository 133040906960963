/**
 * TicketDetailsPage
 * Page de details d'un ticket
 *
 * History
 *    13/01/2023
 *      Suppression warning
 */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
//import { getAllUsersSync } from "../../redux/actions/usersAction";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as Tickets } from "../../assets/images/ticket.svg";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

import TicketHistory from "./history/ticket-history";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";

//import { getSlaEndTime, formatDateTimeFr } from "../../core/utils";

import "./ticket-details-page.scss";
import { Chip } from "@mui/material";
import { getOneCompany_ } from "../../redux/actions/companyAction";
import { getClassIcon, getLocaleDateTime } from "../../core/utils";
import { getTicketConsultationsByUserByTicketSync } from "../../redux/actions/ticketConsultationAction";

const baseURL = process.env.REACT_APP_PROXY;

const TicketDetailsPage = (props) => {
  const {
    needRefresh,
    updateTicket,
    user,
    getOneTicket,
    currentUser,
    addTicketConsultation,
    ticket,
    selectTicket,
    deleteTicketConsultation,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();
  const [editDescription, setEditDescription] = useState(false);
  const [currentDescriptionValue, setCurrentDescriptionValue] = useState("");

  const reloadTicket = (id) => {
    getOneTicket(id).then((res) => {
      const ticket = res.data;
      if (ticket?.company?.id)
        getOneCompany_(ticket?.company?.id).then((r) => {
          selectTicket({ ...ticket, company: r.data });
          setCurrentDescriptionValue(ticket.comment);
        });
      else {
        selectTicket(ticket);
        setCurrentDescriptionValue(ticket.comment);
      }
    });
  };

  const updateConsultationsList = (ticket) => {
    // if (consultations) console.log(consultations?.length);
    // //const newLength = consultations?.length - 1;
    // const newLength = 0
    // if (newLength > 0) {
    //   deleteTicketConsultation(Number(consultations[newLength]?.id), () => {
    //     const newConsultationsList = consultations?.splice(newLength);
    //     updateConsultationsList(newConsultationsList);
    //   });
    // }
    // else {

    // }
    getTicketConsultationsByUserByTicketSync(ticket?.id, user?.id)?.then(
      (c) => {
        const consultations = c?.data?.data?.ticketConsultations;
        consultations.forEach((consultation) => {
          deleteTicketConsultation(consultation?.id);
        });
      }
    );
    addTicketConsultation({
      ticket: ticket?.id,
      users_permissions_user: user?.id,
    });
  };

  useEffect(() => {
    if (user?.id) {
      //console.log(user?.id);
      if (ticket?.id) {
        updateConsultationsList(ticket);
      } else {
        getOneTicket(id).then((res) => {
          const ticket = res.data;
          console.log({
            ticket: ticket?.id,
            users_permissions_user: user?.id,
          });
          updateConsultationsList(ticket);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      reloadTicket(id);
    }
  }, [needRefresh, id]);

  const handleEditDescription = () => {
    setEditDescription(true);
  };

  const handleDescriptionChange = (e) => {
    setCurrentDescriptionValue(e.target.value);
    // console.log("handleDescriptionChange", currentDescriptionValue);
  };

  const handleDescriptionBlur = (e) => {
    setEditDescription(false);
    if (ticket.id && currentDescriptionValue !== ticket.comment) {
      updateTicket({
        id: ticket.id,
        comment: currentDescriptionValue,
        lastUpdatedBy: user.id,
      }).then((result) => {
        reloadTicket(ticket.id);
      });
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  //console.log(ticket);

  return (
    <div className="detailsTicket">
      <div className="titleDetails">
        <h1 className="title">
          <KeyboardArrowLeftOutlinedIcon onClick={goBack} />
          {ticket?.ticket_object
            ? "Ticket #" + id + " - " + ticket?.ticket_object?.name
            : "Ticket #" + id}
        </h1>
      </div>
      <Box>
        <Grid container className="cttTicket showHideEdit">
          <Grid item xs={6} pr={2}>
            <div className="listTab">
              <div className="scrollA">
                <div className="ticketItem" style={{ paddingTop: "0" }}>
                  <div className="itemForItem editable">
                    <div className="blocView">
                      <div className="title">
                        <LocationOnOutlinedIcon />
                        <span>Emplacement de l'anomalie</span>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          {(ticket?.sites?.length > 0 &&
                          ticket?.sites[0]?.address
                            ? `${ticket?.sites[0]?.address}, `
                            : "") +
                            (ticket?.buildings?.length > 0 &&
                            ticket?.buildings[0]?.name
                              ? `Bâtiment ${ticket?.buildings[0]?.name}, `
                              : "") +
                            (ticket?.rooms?.length > 0 && ticket?.rooms[0]?.name
                              ? `Porte ${ticket?.rooms[0]?.name} `
                              : "")}
                        </div>
                      </div>
                    </div>
                    <div className="blocView">
                      <div className="title">
                        <InfoOutlinedIcon />
                        <span>Information sur le matériel et contact</span>
                      </div>
                      {ticket?.materials?.length > 1 ? (
                        <>
                          <div className="infoTicket">
                            <div className="itemInfo">
                              <div className="selectPrioCustom">
                                {ticket?.materials?.map((mat) => (
                                  <Chip
                                    key={mat?.id}
                                    label={`${mat?.designation}, ${mat?.serialnumber}`}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="infoTicket">
                            <div className="itemInfo">
                              <div className="selectPrioCustom">
                                {ticket?.contacts[0]?.firstName
                                  ? ticket?.contacts[0]?.firstName +
                                    " " +
                                    ticket?.contacts[0]?.lastName
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="infoTicket">
                            <div className="itemInfo">
                              <div className="selectPrioCustom">
                                {ticket?.materials?.map(
                                  (mat) =>
                                    `${mat?.designation} ${
                                      mat?.serialnumber
                                    }, ${
                                      ticket?.contacts[0]?.firstName
                                        ? ticket?.contacts[0]?.firstName +
                                          " " +
                                          ticket?.contacts[0]?.lastName
                                        : "-"
                                    }`
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="blocView infoT">
                      <div className="title">
                        <Tickets />
                        <span>Information sur le ticket</span>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Numero du ticket :</label>
                          <div className="selectPrioCustom">{ticket?.id}</div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Statut :</label>
                          <div className="selectPrioCustom">
                            <div
                              className={
                                "state " + ticket?.ticket_statut?.class
                              }
                            >
                              <span
                                className={
                                  "stateIcon " +
                                  getClassIcon(ticket?.ticket_statut?.class)
                                }
                              ></span>
                              {ticket?.ticket_statut?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Priorité :</label>
                          <div className="selectPrioCustom">
                            <div
                              className={
                                "prio " + ticket?.ticket_priority?.classofprio
                              }
                            >
                              {ticket?.ticket_priority?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Objet du ticket :</label>
                          <div className="selectPrioCustom">
                            {ticket?.ticket_object?.name}
                          </div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Type de ticket :</label>
                          <div className="selectPrioCustom black-disabled">
                            {ticket?.ticket_type?.name}
                          </div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Propriétaire du ticket :</label>
                          <div className="selectPrioCustom">
                            {ticket?.responsible_user?.name
                              ? ticket?.responsible_user?.name +
                                " " +
                                ticket?.responsible_user?.lastname
                              : "Aucun"}
                          </div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Propriétaire du compte :</label>
                          <div className="selectPrioCustom propCompte">
                            {ticket?.company?.contact_owner?.firstName
                              ? ticket?.company?.contact_owner?.firstName +
                                " " +
                                ticket?.company?.contact_owner?.lastName
                              : "Aucun"}
                          </div>
                        </div>
                      </div>

                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Date / Heure de création :</label>
                          <div className="selectPrioCustom">
                            {getLocaleDateTime(
                              ticket?.created_at,
                              currentUser.timezone
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Date / Heure de mise à jour :</label>
                          <div className="selectPrioCustom">
                            {getLocaleDateTime(
                              ticket?.updated_at,
                              currentUser.timezone
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Date / Heure de clôture :</label>
                          <div className="selectPrioCustom">
                            {ticket?.closedDate
                              ? getLocaleDateTime(
                                  ticket?.closedDate,
                                  currentUser.timezone
                                )
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="infoTicket">
                        <div className="itemInfo">
                          <label>Objet :</label>
                          <div>{ticket?.objet}</div>
                        </div>
                      </div>

                      <div className="infoTicket description">
                        <div className="itemInfo desc">
                          <label>Description :</label>
                          <div
                            className="editDescriptionZone"
                            onClick={handleEditDescription}
                          >
                            {!editDescription && <p>{ticket?.comment}</p>}
                            {editDescription && (
                              <>
                                <textarea
                                  //ref={descriptionTextareaRef}
                                  rows="6"
                                  spellCheck="false"
                                  className="textareaDescription"
                                  defaultValue={ticket?.comment}
                                  onChange={handleDescriptionChange}
                                  onBlur={handleDescriptionBlur}
                                ></textarea>
                              </>
                            )}

                            {!editDescription && (
                              <span className="labelEditerDescription">
                                Editer
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {ticket?.documents?.length > 0 && (
                      <div className="blocView fichier">
                        {ticket?.documents?.map((doc) => (
                          <div key={doc?.id}>
                            <div className="name">
                              <TextSnippetOutlinedIcon />
                              {doc?.name}
                            </div>
                            <div>
                              <button className="btn primary">
                                <FileDownloadOutlinedIcon />
                                <a
                                  href={baseURL + doc?.file[0]?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Télécharger
                                </a>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} className="commentStory">
            <div className="listTab right">
              <div className="ticketItem">
                <TicketHistory
                  ticketId={id}
                  //refresh={refreshHistory}
                  disableComment={ticket?.ticket_statut?.slug === "cloture"}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(TicketDetailsPage);
