/**
 * AddTicketPage
 * Création : 15/03/2023
 * Page permettant de créer un ticket
 * Historique
 *      DD/MM/YYYY   log
 */
import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";
import FormDialog from "../../components/dialog/form-dialog";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import PlaceChooser from "../../components/place/place-chooser";
import {
  getOneBuildingMaterials,
  getOneRoomMaterials,
  getOneSiteMaterials,
} from "../../redux/actions/materialAction";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import "./add.scss";

const fields = [
  {
    field: "ticket_priority",
    headerName: "Priorité",
    inputType: "list",
    flex: 0.13,
    filterable: true,
    add: true,
    edit: true,
    provider: {
      source: {
        func: "getAllTicketPriorities",
        data: "ticketPriorities",
      },
      displayField: "name",
      valueField: "id",
    },
  },
  {
    field: "ticket_type",
    headerName: "Type",
    inputType: "list",
    flex: 0.13,
    filterable: true,
    add: true,
    export: true,
    copy: false,
    listdetails: false,
    provider: {
      source: {
        func: "getAllTicketTypes",
        data: "ticketTypes",
      },
      displayField: "name",
      valueField: "id",
    },
  },
  {
    field: "ticket_object",
    headerName: "Objet du ticket",
    inputType: "list",
    flex: 0.13,
    className:"width100",
    filterable: true,
    add: true,
    export: true,
    copy: false,
    listdetails: false,
    provider: {
      source: {
        func: "getAllTicketObjects",
        data: "ticketObjects",
      },
      displayField: "name",
      valueField: "id",
    },
  },
  {
    field: "objet",
    headerName: "Objet",
    inputType: "text",
    className:"width100",
    flex: 0.2,
    add: true,
    copy: false,
    listdetails: false,
    mandatory: true,
  },
  {
    field: "company",
    headerName: "Nom du compte",
    inputType: "hidden",
    flex: 0.2,
    filterable: true,
    add: true,
    copy: false,
    listdetails: false,
  },
  {
    field: "contrat",
    headerName: "Contrat",
    inputType: "hidden",
    flex: 0.2,
    filterable: true,
    add: true,
    copy: false,
    listdetails: false,
  },
  {
    field: "comment",
    headerName: "Description",
    inputType: "textarea",
    flex: 0.2,
    add: true,
    copy: false,
    listdetails: false,
    rows: 3,
    mandatory: true,
  },
  {
    field: "documents",
    headerName: "Documents",
    inputType: "document-list",
    flex: 0.2,
    provider: {
      source: {
        func: "getAllDocumentsByMaterial",
        param: "[id]",
        data: "documents",
      },
      displayField: "name",
      valueField: "id",
    },
  },
];

const AddTicketDialog = ({
  open,
  onClose,
  user,
  companyMaterials,
  setNeedRefresh,
  contacts,
  getOneCompanyMaterials,
  ticketOrigins,
  getAllTicketOrigins,
  addTicket,
  getAllTicketStatuts,
  ticketStatuts,
}) => {
  const selectedCompany = user.company;
  //la valeur du champs origine du ticket est portail
  const ticketOriginPortail =
    ticketOrigins?.length && ticketOrigins?.length > 0
      ? ticketOrigins?.filter((origin) =>
          origin?.name?.toLowerCase()?.includes("portail")
        )[0]
      : null;

  const NewTicketStatus =
    ticketStatuts?.length && ticketStatuts?.length > 0
      ? ticketStatuts?.filter((status) =>
          status?.name?.toLowerCase()?.includes("nouveau")
        )[0]
      : null;

  const [datasToSave, setDatasToSave] = useState({
    escalation_level_group: "1",
  });
  const [selectedSiteID, setSelectedSiteID] = useState(0);
  const [selectedBuildingID, setSelectedBuildingID] = useState(0);
  const [selectedRoomID, setSelectedRoomID] = useState(0);
  const [selectedMaterials, SetSelectedMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isInProgress, setIsInprogress] = useState(false);

  useEffect(() => {
    getAllTicketOrigins();
    getAllTicketStatuts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNeedRefresh(false);
    //console.log("eee")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasToSave]);
  useEffect(() => {
    setMaterials(companyMaterials);
  }, [companyMaterials]);
  useEffect(() => {
    const siteID = parseInt(selectedSiteID);
    const buildingID = parseInt(selectedBuildingID);
    const roomID = parseInt(selectedRoomID);
    if (siteID > 0 && buildingID === 0 && selectedRoomID === 0)
      getOneSiteMaterials(selectedSiteID.toString());
    if (buildingID > 0 && roomID === 0)
      getOneBuildingMaterials(selectedBuildingID.toString());
    if (roomID > 0) getOneRoomMaterials(selectedRoomID.toString());
    if (buildingID <= 0 && roomID <= 0 && siteID <= 0) {
      if (selectedCompany) getOneCompanyMaterials(selectedCompany?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomID, selectedBuildingID, selectedSiteID]);

  const handleClose = () => {
    if (onClose && typeof onClose === "function") onClose();
  };
  const handlePlaceSet = (siteId, buildingId, roomId) => {
    setSelectedSiteID(siteId);
    setSelectedBuildingID(buildingId);
    setSelectedRoomID(roomId);
  };
  const handleChangeMaterial = (event, value) => {
    SetSelectedMaterials(value);
  };
  const handleChangeContact = (e, value) => {
    setSelectedContacts(value);
  };
  const handleChangeFields = (allDataForm) => {
    if (!datasToSave) setDatasToSave(allDataForm);
    else {
      setDatasToSave({
        ...allDataForm,
        escalation_level_group: datasToSave.escalation_level_group,
      });
    }
  };
  const handleSaveForm = () => {
    const data = {
      ...datasToSave,
      ticket_statut: NewTicketStatus,
      ticket_origin: ticketOriginPortail,
      company: selectedCompany?.toString(),
      //contract: selectedContrat?.id?.toString(),
      sites: selectedSiteID > 0 ? [selectedSiteID.toString()] : [],
      buildings: selectedBuildingID > 0 ? [selectedBuildingID.toString()] : [],
      rooms: selectedRoomID > 0 ? [selectedRoomID.toString()] : [],
      materials: selectedMaterials,
      contacts: selectedContacts,
      lastUpdatedBy: user?.id,
    };
    console.log(data);
    setIsInprogress(true);
    addTicket(data).then((result) => {
      console.log(result);
      setNeedRefresh(true);
      onClose();
      setIsInprogress(false);
    });
    // props[handler.add](data, history).then((result) => {
    //   setNeedRefresh(true);
    //   history.push("/ticket");
    // });
  };

  return (
    <Dialog onClose={handleClose} fullWidth={true} maxWidth="md" open={open}>
      <DialogTitle className="text-center">
        Nouveau ticket
        <button className="closePop" onClick={handleClose}>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent dividers>
        <div className="paddTicket formForTicket">
          <div className="scrollFinalTicket">
            <div className="placeChooseTicket h3reset">
              <span className="title">Emplacement de l'anomalie</span>
              <PlaceChooser
                comprops={selectedCompany}
                withNullDefaultValue
                placeSet={handlePlaceSet}
              />
            </div>
            <div className="ticketAnomalie h3reset">
              <span className="title">
                Information sur le materiel et contact
              </span>
              <div className="formPopup">
                <div className="inputContent">
                  <div className="MuiFormControl-root mt-25">
                    <InputLabel id="select-ticket-contact">
                      Nom du materiel
                    </InputLabel>
                    <Autocomplete
                      value={selectedMaterials}
                      multiple
                      options={materials}
                      className="multiForMaterial"
                      groupBy={(material) => material?.designation}
                      getOptionLabel={(material) => material?.serialnumber}
                      noOptionsText="Pas de résultat"
                      onChange={(event, value) =>
                        handleChangeMaterial(event, value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onMouseDownCapture={(e) => e.stopPropagation()}
                          placeholder="Rechercher un matériel..."
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="inputContent contact">
                  <div className="MuiFormControl-root">
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      margin="normal"
                    >
                      <InputLabel id="select-ticket-contact">
                        Contacts
                      </InputLabel>
                      <Autocomplete
                        multiple
                        options={contacts}
                        value={selectedContacts}
                        noOptionsText="Pas de résultat"
                        onChange={handleChangeContact}
                        labelId="select-ticket-contact"
                        className="multiForMaterial"
                        name="contact"
                        label="Contact"
                        getOptionLabel={(contact) =>
                          `${contact.firstName || ""} ${contact.lastName || ""}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onMouseDownCapture={(e) => e.stopPropagation()}
                            placeholder="Sélectionner un contact..."
                          />
                        )}
                        // renderValue={(selected) =>{
                        //   console.log(selected)
                        //   return (
                        //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        //       {selected.map((value) => (
                        //         <Chip key={value} label={value} />
                        //       ))}
                        //     </Box>
                        //   )}
                        // }
                      >
                        {contacts?.map((contact) => (
                          <MenuItem value={contact?.id} key={contact?.id}>
                            {contact.firstName + " " + contact.lastName}
                          </MenuItem>
                        ))}
                      </Autocomplete>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="ticketAnomalie h3reset">
              <span className="title">Information sur le ticket</span>
              <FormDialog
                isPage={true}
                fields={fields}
                customHandleChangeField={handleChangeFields}
                withOrder
                validHandler={setFormIsValid}
                onStartUploadDocument={() => {
                  setFormIsValid(false);
                }}
                onEndUploadDoculent={() => {
                  setFormIsValid(true);
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="btn secondary">
          Annuler
        </Button>
        <Button
          className="btn primary save"
          disabled={!formIsValid}
          onClick={handleSaveForm}
        >
          <AddIcon />
          {isInProgress ? "Ajout en cours..." : "Créer un ticket"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTicketDialog);
