/**
 * UserPage
 * Création : 15/03/2023
 * Page contenant la liste des utilisateurs (acces admin)
 * Historique
 *      DD/MM/YYYY   log
 */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import GenericDatagrid from "../../components/datagrid/generic-datagrid";
//import AddUserDialog from "./add";

const userParams = {
    name: 'user',
    access: ['admin'],
    path: '/user',
    title: 'Utilisateurs',
    menu: true,
    description : '',
    component : 'UserPage',
    icon : 'people',
    isParent: false,
    fields : [
        {
            field: "company",
            headerName: "Compte",
            inputType: 'list', 
            native: true,
            mandatory:true,
            flex:0.17,
            provider : {source : {func:'getAllCompanies', data:'companies'}, displayField: 'name', valueField : 'id'},
            valueGetter: ({ row }) => row?.company?.name, 
            filterable: true, add: true, edit: true, list: true, export:true, copy:true, listdetails: false
        },
        { field: "name", headerName: "Nom" , inputType: 'text', flex: 0.18, filterable: true, add: true, edit: true, list: true, export:true, copy:false, autocomplete: {field:'lastName'}},
        { field: "lastname", headerName: "Prénom" , inputType: 'text', flex: 0.18, filterable: true, add: true, edit: true, list: true, export:true, copy:false, autocomplete: {field:'firstName'} },
        { field: "username", headerName: "Nom d'utilisateur" , inputType: 'text', flex: 0.16, filterable: true, add: true, edit: true, list: true, export:true, copy:false },
        { field: "email", headerName: "Email" , inputType: 'email', flex: 0.2, filterable: true, add: true, edit: true, list: true, export:true, copy:false, autocomplete: {field:'email'} }, 
        { field: "password", headerName: "Mot de passe" , inputType: 'password', flex: 0.2, filterable: false, add: true, edit: false, list: false, export: false, copy:false },
        {
            field: "role",
            headerName: "Rôle",
            inputType: 'list', 
            native: true,
            flex:0.17,
            provider : {source : {func:'getPortailRoles', data:'roles'}, displayField: 'name', valueField : 'id'},
            valueGetter: ({ row }) => row?.role?.name, 
            filterable: true, add: true, edit: true, list: true, export:true, copy:true, listdetails: false,
            onChange : {
                condition : {value: "3"},//rôle admin
                codeToEvalTrue : `document.getElementById("company").disabled=true;`,
                codeToEvalFalse : `document.getElementById("company").disabled=false;`,
                reactCodeTrue: { targetField : 'company', targetFieldValue : `${process.env.REACT_APP_OWN_COMPANY_ID}`},
                reactCodeFalse : { targetField : 'company', targetFieldValue : ""}
            }
        }
    ],
    handler : { 
        list: {func:'getAllUsers', data:'users'}, 
        add : 'addUser', 
        update: 'modifRessource', 
        delete : 'supprimRessource',
        autocomplete : {source : {func:'getAllContacts', data:'contacts'}, displayField: 'firstName', displayField2: 'lastName', valueField : 'id'},
    }
}

const UserPage = (props) => {
    const { getAllUsers, users, sendMail } = props;
    //const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    //const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
    const columns = userParams.fields;

    const handleAfterAdd = (data) => {
        //console.log('handleAfterAdd', data);
        const appUrl = window.location.origin;
        const username = data.username;
        const password = data.password;
        let body = '<p>Bonjour,</p>';
        body += '<p>Merci d’avoir rejoint notre portail clients KD 360-Portal.</p>'
        body += '<p>Nous aimerions vous confirmer que votre compte a été créé avec succès. Pour accéder au portail, cliquez sur le lien ci-dessous.</p>';
        body += '<ul>';
        body += `<li>Url : <a href="${appUrl}" target="_blank">${appUrl}</a></li>`;
        body += `<li>Login : ${username}</li>`;
        body += `<li>Mot de passe : ${password}</li>`;
        body += `</ul>`;
        body += `<p></p>`;
        body += `<p>Si vous rencontrez des difficultés pour vous connecter à votre compte, contactez-nous à <a href="mailto:helpdesk@kardham-digital.com" target="_blank">helpdesk@kardham-digital.com</a></p>`;
        body += `<p></p>`;
        body += `<p>Bien cordialement,</p>`;
        body += `<p>L\'équipe support Kardham Digital</p>`;
        sendMail({
            to : data.email,
            subject: 'Votre accès au portail KD 360',
            html: body,
        }).then((r) => console.log('sendMail.res', r));
    }

    useEffect(() => {
        getAllUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="utilisateur">
            <GenericDatagrid 
                data={users} 
                fields={columns} 
                type="user" 
                title={userParams.title} 
                handler={userParams.handler} 
                disableClickRow={true}
                columnVisibilityModel={{
                    password : false,
                }}
                afterAdd={handleAfterAdd}
                />
        </div>
    ) 
}
export default connect(mapStateToProps, mapDispatchToProps)(UserPage);