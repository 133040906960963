import {
    ADD_TICKET_TYPE,
    DELETE_TICKET_TYPE,
    GET_ALL_TICKET_TYPE,
    GET_ONE_TICKET_TYPE,
    MODIF_TICKET_TYPE
} from "../actions/ticketTypeAction";

const initialState = {
    ticketTypes: [],
};

export function ticketTypeReducers(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_TICKET_TYPE:
            return { ...state, ticketTypes: action.payload };

        case ADD_TICKET_TYPE:
            return { ...state, ticketType: action.payload };

        case MODIF_TICKET_TYPE:
            return { ...state };

        case DELETE_TICKET_TYPE:
            return { ...state };

        case GET_ONE_TICKET_TYPE:
            return { ticketType: action.payload };

        default:
            return { ...state };
    }
}