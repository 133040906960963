/* 
*Returns true if valid
*
* @param {string} fields All fields in form 
* @param {string} dataRef the date ref in the form 
*/
import Moment from 'moment';
import moment from 'moment-timezone';
import ExcelJS from "exceljs";
import saveAs from "file-saver";

export const isFormValid = (fields, dataRefCurrent ) => {
  let valid = true;
  fields.forEach ((f) => {
      if (f.mandatory){
        const exist = dataRefCurrent?.hasOwnProperty(f.field) && dataRefCurrent[f.field] !== null;
        const value = exist ? dataRefCurrent[f.field] : undefined
          valid &= (typeof(value) === 'string'? value !== '': exist) ;
      }
  });

  return valid;
}   

export const hasRequiredField = (fields) => {
  let hasRequired = false;
  fields.forEach((f) => {
    if (f.mandatory){
        hasRequired = true; 
    }
  })
  return hasRequired;
}

export const sleep = (ms) => {
  return new Promise((resolve) => 
    {
      setTimeout(() => {  
        resolve("yes");
      }, ms)
    });
}

export const addDays = (date, days) => {
  return new Date(date.getTime() + (days*24*60*60*1000));
}

export const getMondayOfCurrentWeek = () => {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const tempDate = new Date(today.setDate(first));

  const monday = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0, 0);
  return monday;
}

export const getSundayOfCurrentWeek = () => {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 7;
  const tempDate = new Date(today.setDate(first));

  const sunday = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 23, 59, 59);
  return sunday;
}

export const getMondayOfLastWeek = () => {
  const today = new Date();
  const first = today.getDate() - today.getDay() - 6;
  const tempDate = new Date(today.setDate(first));

  const monday = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0, 0);
  return monday;
}

export const getSundayOfLastWeek = () => {
  const today = new Date();
  const first = today.getDate() - today.getDay();
  const tempDate = new Date(today.setDate(first));

  const sunday = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 23, 59, 59);
  return sunday;
}

export const getFirstDayOfCurrentMonth = () => {
  const today = new Date();

  return new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
}

export const getLastDayOfCurrentMonth = () => {
  const today = new Date();

  return new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
}

export const getFirstDayOfLastMonth = () => {
  const lastDayLastMonth = getLastDayOfLastMonth();

  return new Date(lastDayLastMonth.getFullYear(), lastDayLastMonth.getMonth(), 1, 0, 0, 0);
}

export const getLastDayOfLastMonth = () => {
  const firstDayCurrentMonth = getFirstDayOfCurrentMonth();
  const tempDate = addDays(firstDayCurrentMonth, -1)

  return new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 23, 59, 59);
}

export const getFirstDayOfCurrentYear = () => {
  const today = new Date();

  return new Date(today.getFullYear(), 0, 1, 0, 0, 0);
}

export const getLastDayOfCurrentYear = () => {
  const today = new Date();

  return new Date(today.getFullYear(), 11, 31, 23, 59, 59);
}

export const getSlaEndTime = (slaType, ticket) => {
  const keyValue = ticket?.contract?.slas && ticket?.contract?.slas.length > 0 ? ticket.contract.slas[0].hasOwnProperty(slaType) ? ticket?.contract?.slas[0][slaType] : null: null;

  const startOfKeyTimeIndex = keyValue?.indexOf('+') + 1;
  const key_NB_OfTimeToAdd = parseInt(keyValue?.substring(startOfKeyTimeIndex));
  let momentKey = '';

  if (typeof (keyValue) === 'string' && keyValue?.charAt(0) === 'H') {
    momentKey = 'h';
  }
  else if (typeof (keyValue) === 'string' && keyValue?.charAt(0) === 'J') {
    momentKey = 'd'
  }
  if (keyValue) return Moment(ticket?.created_at).add(key_NB_OfTimeToAdd, momentKey)
  else return null

}

export const formatDateTimeFr = (date) => {
  return date ? Moment(date).format('DD/MM/YYYY') + ' à ' + Moment(date).format('HH')+'h'+Moment(date).format('mm') : '-';
}

export const formatUtc = (m) => {
  return Moment.utc(Moment(m)).format('DD/MM/YYYY HH:mm:ss');
}

export const formatDateUtc = (m) => {
  return Moment.utc(Moment(m)).format('DD/MM/YYYY');
}

export const formatHourUtc = (m) => {
  return Moment.utc(Moment(m)).format('HH:mm:ss');
}

export const getDateYYYYMMDD = () => {
  const now = new Date();
  return `${now.getFullYear()}${(now.getMonth()+101+'').substring(1,3)}${(now.getDate()+100+'').substring(1,3)}`;
}

export const getLocaleDateTime = (date, timezone) => {
 // console.log(timezone);
  //to do : remover the warning ==> Moment Timezone has no data for 2
  return date && timezone ? moment.tz(date, timezone).format('DD/MM/YYYY HH:mm'): moment(date).format('DD/MM/YYYY HH:mm');
}

export const comparators = {
  "text" : [
    {comparator:"_eq", title : 'est'},
    {comparator:"_ne", title : 'n\'est pas'},
    {comparator:"_contains", title : 'contient'},
    {comparator:"_ncontains", title : 'ne contient pas'},
    
  ],
  "date" : [
    {comparator:"_eq", title:"est"}, 
    {comparator:"_gt", title:"est supérieure à"}, 
    {comparator:"_lt", title:"est inférieure à"}
  ]
}

export const exportToExcel = (filename, params) => {
  let excelJSWorkbook = new ExcelJS.Workbook();
  let worksheet = excelJSWorkbook.addWorksheet(filename);

  const defaultColumnWidth = 18;
  const dataToExport = params.data;

  //header row
  let headerRow = worksheet.addRow();
  let columnIndex = 1;
  let cell = null;

  params.fields.forEach(field => {
    cell = headerRow.getCell(columnIndex); 
    cell.value = field.label;
    if (field.columnWidth) {
      worksheet.getColumn(columnIndex).width = defaultColumnWidth * field.columnWidth;
    }
    columnIndex++;
  });

  //data rows
  dataToExport.forEach(d => {
    let newRow = worksheet.addRow();
    columnIndex = 1;

    params.fields.forEach(field => {
      cell = newRow.getCell(columnIndex); 
      cell.value = `${d[field.name]}`;
      if (field.alignment) {
        cell.alignment = field.alignment;
      }
      columnIndex++;
    });
  });

  excelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `${filename}.xlsx`
    );
  });
}

export const getClassIcon = (dataclass) => {
  switch (dataclass) {
    case 'nouveau-ticket':
      return 'icon-new-ticket';   
    case 'en-attente-client':
      return 'icon-time';
    case 'intervention-en-remote':
      return 'service';
    case 'intervention-sur-site':
      return 'societe';
    case 'solved':
      return 'icon-solved';
    case 'cloture':
      return 'icon-closed';
    case 'en-cours-n1':
      return 'icon-attente'; 
    default:
      return 'icon-escale'
  }
};