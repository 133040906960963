import axios from "axios";

export const GET_ROLE = "GET_ROLE";

export const getPortailRoles = () => (dispatch) => {
  try {
    return axios.get(`/users-permissions/roles`)
      .then((res) => {
        const portailRoles = res.data?.roles.filter((r) => r.type === "admin" || r.type === "client")
        dispatch({ type: GET_ROLE, payload: portailRoles });
      });
  } catch (err) {
    return console.log("ERREUR ROLE ", err);
  }
};