import axios from "axios";

export const GET_ALL_TICKET_OBJECT = "GET_ALL_TICKET_OBJECT";
export const ADD_TICKET_OBJECT = "ADD_TICKET_OBJECT";
export const MODIF_TICKET_OBJECT = "MODIF_TICKET_OBJECT";
export const DELETE_TICKET_OBJECT = "DELETE_TICKET_OBJECT";
export const GET_ONE_TICKET_OBJECT = "GET_ONE_TICKET_OBJECT";

export const getAllTicketObjects = () => (dispatch) => {
  try {
      return axios.get("/ticket-objects")
                  .then(cli => {
                      dispatch({ type: GET_ALL_TICKET_OBJECT, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR GET ALL TICKET_OBJECT ", error);
  }
};

export const addTicketObject = (ticketObjectState, history) => (dispatch) => {
  try {
      return axios.post("/ticket-objects", ticketObjectState)
                  .then(cli => {
                      dispatch({ type: ADD_TICKET_OBJECT, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR AJOUT TICKET_OBJECT ", error);
  }
};

export const updateTicketObject = (ticketObjectState) => (dispatch) => {
  try {
      return axios.put(`/ticket-objects/${ticketObjectState.id}`, ticketObjectState)
                  .then(cli => {
                      dispatch({ type: MODIF_TICKET_OBJECT, payload: cli.data })
                  });
  } catch (error) {
      return console.log("ERREUR MODIFICATION TICKET_OBJECT ", error);
  }
};

export const deleteTicketObject = (id) => (dispatch) => {
  try {
      return axios.delete(`/ticket-objects/${id}`)
                  .then((cli) => {
                      dispatch({ type: DELETE_TICKET_OBJECT, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR DELETE TICKET_OBJECT ", error);
  }
};

export const getOneTicketObject = (id) => (dispatch) => {
  try{
      return axios.get(`/ticket-objects/${id}`)
                  .then(rep => {
                      dispatch({ type: GET_ONE_TICKET_OBJECT, payload: rep.data });
                  });
  } catch (error) {
      return console.log("ERREUR ", error);
  }
};