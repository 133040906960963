/* eslint-disable react-hooks/exhaustive-deps */
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { useEffect, useState } from "react";

import './input-autocomplete-field.scss';

const filter = createFilterOptions();

/**
 * 2022/11/02 : prise en compte de displayField2 (à optimiser)
 * @param {*} props 
 * @returns 
 */
const InputAutocompleteField = (props) => {
    const {param, handleChange, value, disabled, paramDataID, setNeedRefresh} = props;
    const useDataIDAsParam = param?.useDataIDAsParam;

    const [newItemName, setNewItemName] = useState("");

    const sourceFunc = param.provider.source.func;
    const sourceFuncParam = param.provider.source.param?param.provider.source.param:null;
    const sourceData = props[param.provider.source.data];

    let localDefaultValue = (newItemName !== "" ? ((sourceData.find(s => s[param.provider.displayField] === newItemName))?(sourceData.find(s => s[param.provider.displayField] === newItemName)):value): value);
    if (localDefaultValue.id > 0)
        handleChange({target : {name: param.field, value: localDefaultValue.id}});

    useEffect(() => {
        if (!useDataIDAsParam)
        props[sourceFunc](sourceFuncParam);
        setNeedRefresh(false);
    }, [newItemName, param.provider.source.param]);  


    useEffect(()=>{
        if (paramDataID && useDataIDAsParam)props[sourceFunc](paramDataID);
        setNeedRefresh(false);
    },[paramDataID, useDataIDAsParam])

    const defaultProps = {
        options: sourceData,
        getOptionLabel: (option) => {
            // Add "xxx" option created dynamically
            if (option.inputValue) {
                return option.inputValue;
            }
            // Regular option
            return option[param.provider.displayField];
        }
    };

    const defaultOptions = {
        filterOptions :(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) =>{
                return inputValue === option[param.provider.displayField]});
            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    inputValue,
                    [param?.provider?.displayField||'name']: `Créer "${inputValue}"`,
                });
            }

            return filtered;
        }
    };

    const handlerOptions = {
        onChange:(event, newValue) => {
            if (newValue && newValue.inputValue) {
                //appel du handler d'ajout...
                props[param.handler.add]({[param?.provider?.displayField]:newValue.inputValue}, (createdDataID)=>{
                    setNewItemName(newValue.inputValue);
                     //..puis rechargement des options avec le nouvel id créé (?)
                    handleChange({target : {name: param.field, value: createdDataID}});
                    setNeedRefresh(true);
                });
                
            } else {
                if (newValue)
                    handleChange({target : {name: param.field, value: newValue.id}});
            }
        },
        onInputChange:(event, newValue) => {
            //console.log('param.field', param.field);
            //console.log('newValue', newValue);
            if (newValue === ''){
                handleChange({target : {name: param.field, value: 0}});
            }
        }
    }

    return (
        <div className="inputContent">
            <Autocomplete
                size="small"
                disabled={disabled}
                name={param.field}
                defaultValue={value}
                {...handlerOptions}
                {...defaultProps}
                {...defaultOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys

                renderInput={(params) => <TextField {...params} label={param.headerName} />}
                renderOption={(props, option) => <li {...props}>{option[param.provider.displayField]}{param.provider.displayField2 ? " " + option[param.provider.displayField2]: ""}</li>}
            />
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(InputAutocompleteField);