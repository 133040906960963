import {
  GET_ALL_MATERIAL,
  GET_ONE_MATERIAL,
  GET_ONE_COMPANY_MATERIALS,
  GET_ONE_SITE_MATERIALS,
  GET_ONE_BUILDING_MATERIALS,
  GET_ONE_ROOM_MATERIALS
} from "../actions/materialAction";

const initialState = {
  materials: [],
  material: {}
};

export function materialReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MATERIAL:
      return { ...state, materials: action.payload };

    case GET_ONE_MATERIAL:
      return { material: action.payload };

    case GET_ONE_COMPANY_MATERIALS:
      return { ...state, materials: action.payload };    
    
    case GET_ONE_SITE_MATERIALS:
      return { ...state, materials: action.payload };    

    case GET_ONE_BUILDING_MATERIALS:
      return { ...state, materials: action.payload };  

    case GET_ONE_ROOM_MATERIALS:
        return { ...state, materials: action.payload };  

    default:
      return { ...state };
  }
}
