/* eslint-disable eqeqeq */
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import mapStateToProps from "../../../redux/mapStateToProps";
import mapDispatchToProps from "../../../redux/mapDispatchToProps";
import Moment from "moment";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";

import "./ticket-comments.scss";

const TicketComments = (props) => {
  const {
    refresh,
    setRefresh,
    getAllTicketCommentsByTicket,
    ticketId,
    user,
    updateTicketHistory,
    downloadFile,
  } = props;
  const [ticketComments, setTicketComments] = useState(null);

  useEffect(() => {
    getAllTicketCommentsByTicket(ticketId).then((res) => {
      setTicketComments(res.data.data.ticketHistories);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const [selectedEdit, setSelectedEdit] = useState(null);
  const handleClickEditComment = (value, id) => () => {
    setSelectedEdit(id);
    setCommentEdited(value);
  };

  const [commentEdited, setCommentEdited] = useState("");
  const handleCommentChange = (event) => {
    setCommentEdited(event.target.value);
  };

  const validateEditComment = (history) => () => {
    updateTicketHistory({ id: history.id, newValue: commentEdited }).then(
      (result) => {
        setCommentEdited("");
        setSelectedEdit(null);
        setRefresh(!refresh);
      }
    );
  };

  const cancelEditComment = () => () => {
    setCommentEdited("");
    setSelectedEdit(null);
  };

  const handleDownloadfile = (e, id) => {
    console.log("should download", id);
    downloadFile(id);
  };

  return (
    <>
      {ticketComments?.length > 0 && (
        <div className="comment">
          <div className="title">
            <span>
              <ForumOutlinedIcon />
            </span>
            <span>Commentaires</span>
          </div>
          <div className="historiqueItem">
            {ticketComments?.map((history, index) => {
              return (
                <div key={index} className="histo">
                  <h3>
                    Commentaire de{" "}
                    {history?.modifierUser?.name
                      ? history?.modifierUser?.name +
                        " " +
                        history?.modifierUser?.lastname
                      : history?.modifierUser?.email}
                    {user.id == history.modifierUser.id ? (
                      <>
                        {selectedEdit != index && (
                          <button
                            className="editCom"
                            onClick={handleClickEditComment(
                              history.newValue,
                              index
                            )}
                          >
                            Editer
                          </button>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </h3>
                  <div className="explication">
                    {Moment(history?.created_at).format("D/MM/YYYY")} à{" "}
                    {Moment(history?.created_at).format("HH")}h
                    {Moment(history?.created_at).format("mm")}
                  </div>
                  <div className="textcom">
                    <div className="display-pre-line oneLine">
                      <div className="text">
                        {selectedEdit != index ? history?.newValue : ""}
                      </div>
                      <div className="file">
                        {history.documents?.length > 0 &&
                          history.documents?.map((data, index) => {
                            return (
                              <ListItem key={data.id} className="item">
                                <ListItemIcon className="icon-doc">
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={data.file[0].name}
                                  onClick={(e) =>
                                    handleDownloadfile(e, data.file[0].id)
                                  }
                                />
                              </ListItem>
                            );
                          })}
                      </div>
                    </div>
                    {selectedEdit == index && (
                      <textarea
                        className="textComment"
                        value={commentEdited}
                        onChange={handleCommentChange}
                      ></textarea>
                    )}
                    {user.id == history.modifierUser.id ? (
                      <>
                        {selectedEdit == index && (
                          <div className="btnEditComment">
                            <div className="btnTicket ">
                              <button
                                className="cancel"
                                onClick={cancelEditComment()}
                              >
                                Annuler
                              </button>
                            </div>
                            <div className="btnTicket ">
                              <button
                                className="validate"
                                onClick={validateEditComment(history)}
                              >
                                Valider
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(TicketComments);
