import {
  useEffect,
  useRef,
  useState,
  //useEffect
} from "react";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import InputSelectField from "../input/input-select-field";

const PlaceChooser = ({ comprops, placeSet, withNullDefaultValue }) => {
  const formRef = useRef({});

  useEffect(() => {
    setSiteId(-1);
    setBuildingId(-1);
  }, [comprops]);

  const [siteId, setSiteId] = useState(-1);
  const [buildingId, setBuildingId] = useState(-1);

  const siteProviderSourceParam = () => {
    if (comprops?.id) return comprops.id;
    else {
      if (typeof comprops === "number") return comprops.toString();
      if (typeof comprops === "string") return comprops;
      else {
        console.error(
          `le type de la valeur de comprops ne doit pas être ${typeof comprops} => ${comprops}`
        );
      }
    }
  };

  const siteParam = {
    param: {
      field: "site",
      headerName: "Site",
      provider: {
        source: {
          func: "getAllSitesByCompany",
          param: siteProviderSourceParam(),
          data: "sites",
        },
        displayField: "name",
        valueField: "id",
      },
    },
  };

  const buildingParam = {
    param: {
      field: "building",
      headerName: "Bâtiment",
      provider: {
        source: {
          func: "getAllBuildingsBySite",
          param: siteId,
          data: "buildings",
        },
        displayField: "name",
        valueField: "id",
      },
    },
  };

  const roomParam = {
    param: {
      field: "room",
      headerName: "Pièce",
      provider: {
        source: {
          func: "getAllRoomsByBuilding",
          param: buildingId,
          data: "rooms",
        },
        displayField: "name",
        valueField: "id",
      },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "site") {
      setSiteId(value);
      setBuildingId(-1);
      placeSet(value, 0, 0);
    } else if (name === "building") {
      setBuildingId(value);
      placeSet(siteId, value, 0);
    } else if (name === "room") {
      placeSet(siteId, buildingId, value);
    }

    formRef.current = { ...formRef.current, [name]: value };
  };

  return (
    <div className="formPopup">
      <div className="inputContent">
        <InputSelectField
          {...siteParam}
          withNullDefaultValue={withNullDefaultValue}
          handleChange={handleChange}
        />
      </div>
      <div className="inputContent">
        <InputSelectField
          {...buildingParam}
          withNullDefaultValue={withNullDefaultValue}
          handleChange={handleChange}
        />
      </div>
      <div className="inputContent">
        <InputSelectField
          {...roomParam}
          withNullDefaultValue={withNullDefaultValue}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PlaceChooser);
