/**
 * UserSettingsTimezone
 * Création : 29/03/2023
 * Page permettant à un utilisateur de paramétrer son compte (changement mot de passe, paramétrage notification, fuseau horaire)
 * Historique
 *      05/04/2023  Enregistrement paramétrage langue et timezone
 */
import { connect } from "react-redux";
import mapStateToProps from "../../../redux/mapStateToProps";
import mapDispatchToProps from "../../../redux/mapDispatchToProps";

import { useState, useEffect } from "react";
import moment from 'moment-timezone';

import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';
import MenuItem  from '@mui/material/MenuItem';
import InputLabel  from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const UserSettingsTimezone = (props) => {
    const {user, modifRessource, getMe} = props;
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [language, setLanguage] = useState("fr");
    const [timezone, setTimezone] = useState("Europe/Paris"); // GMT+2 par défaut (user en France)
    const [orderedTimezones, setOrderedTimeszones] = useState([]);

    useEffect(() => {
        if (user.language) setLanguage(user.language);
        if (user.timezone) setTimezone(user.timezone);

        const timezones = moment.tz.names();
        setOrderedTimeszones(timezones.sort((a, b) => {
            const offsetA = moment.tz(a).utcOffset();
            const offsetB = moment.tz(b).utcOffset();
            return offsetA - offsetB;
        }));
    },[user])

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
    }

    const handleChangeTimezone = (event) => {
        setTimezone(event.target.value);
    }

    const handleValidate = () => {
        console.log('user', user)
        console.log('language', language)
        console.log('timezone', timezone)
        modifRessource({
            id : user.id,
            language : language,
            timezone: timezone
        }).then ((res) => {
            getMe();
            //TODO afficher message d'info
            setInfoMessage("Vos paramètres de localisation ont bien été enregistrés.");
            setTimeout(() => {
                setInfoMessage("")
            }, 3000)
        }).catch((err) => {
            console.log('error', err);
            //TODO afficher message d'erreur
            setErrorMessage("Erreur lors de l'enregistrement de vos paramètres : " + err)
        })
    }

    return (
        <div className="contentRight timeZone">
            <h1 className="title">Paramètres de localisation</h1>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" size="small" margin="normal">
                            <InputLabel>Langue :</InputLabel>
                            {/** TODO : désactiver la langue anglaise */}
                            <Select value={language} onChange={handleChangeLanguage}>
                                <MenuItem value="fr" key="fr">Français</MenuItem>
                                <MenuItem value="en" key="en">English</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" size="small" margin="normal">
                            <InputLabel>Fuseau horaire :</InputLabel>

                            <Select value={timezone} onChange={handleChangeTimezone}>
                                {
                                    orderedTimezones.map(tz => {
                                        return <MenuItem value={tz} key={tz}>{`${tz} (UTC${moment.tz(tz).format('Z')})`}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <div className="text-right">
                <button className="btn primary" onClick={handleValidate}>Sauvegarder</button>
            </div>
            {
                infoMessage && <Alert severity="info">{infoMessage}</Alert>
            }
            {
                errorMessage && <Alert severity="warning">{errorMessage}</Alert>
            }
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsTimezone);