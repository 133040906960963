import axios from "axios";

export const GET_ALL_CONTACT = "GET_ALL_CONTACT";
export const GET_ONE_CONTACT = "GET_ONE_CONTACT";
export const GET_ALL_CONTACT_GRAPHQL = "GET_ALL_CONTACT_GRAPHQL";
export const SET_OWN_COMPANY_CONTACTS = "SET_OWN_COMPANY_CONTACTS";
export const GET_COMPANY_CONTACTS = "GET_COMPANY_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";

const OWN_COMPANY_NAME = "KARDHAM"; //process.env.REACT_APP_DEFAULT_OWN_COMPANY_NAME; //TODO uncomment

export const getAllContacts = () => (dispatch) => {
    try {
        return axios.get(`/contacts?_limit=5000`)
                    .then(cli => {
                        dispatch({ type: GET_ALL_CONTACT, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL CONTACT ", error);
    }
};

export const addContact = (contactState, history) => (dispatch) => {
    try {
        return axios.post("/contacts", contactState)
                    .then(cli => {
                        dispatch({ type: ADD_CONTACT, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT CONTACT ", error);
    }
};

export const getOneContact = (id) => (dispatch) => {
    try{
        return axios.get(`/contacts/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_CONTACT, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const getAllContactsByCompany = (companyId) => (dispatch) => {
    try {
        const querya = `query getContacts($cid:ID!){
            contacts (where : {company : {id : $cid}}) {
              id
              firstName
              lastName
              function {
                  id
                  name
              }
              service
              vip
              fix_number
              mobile_number
              email
              company {
                  id
                  name
              }
            }
        }`;
        const variablesa = { "cid" : companyId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body).then(res => {
            //console.log(res.data.data.contacts)
            dispatch({
                type: GET_COMPANY_CONTACTS,
                payload: res.data.data.contacts
            })
          });
    } catch (error) {
        return console.log("ERREUR GET ALL CONTACT ", error);
    }
};

export const getAllContactsByCompany_ = (companyId) => {
    try {
        const querya = `query getContacts($cid:ID!){
            contacts (where : {company : {id : $cid}}) {
              id
              firstName
              lastName
              function {
                  id
                  name
              }
              service
              vip
              fix_number
              mobile_number
              email
              company {
                  id
                  name
              }
            }
        }`;
        const variablesa = { "cid" : companyId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL CONTACT ", error);
    }
};

export const getOwnCompanyContacts = () => (dispatch) => {
    try {
        return axios.get("/companies").then(cli => {
            const companies = cli.data;
            const ownCompanyID = companies.filter(comp =>
                comp.name.toLowerCase().trim().includes(OWN_COMPANY_NAME.toLowerCase())
            )[0].id;
            getAllContactsByCompany_(ownCompanyID).then(r=>{
                dispatch({ type: SET_OWN_COMPANY_CONTACTS, payload: r.data.data.contacts });
            }) 
        }) ;
    } catch (error) {
        return console.log("ERREUR GET ALL COMPANY ", error);
    }
}

export const addContactSync = (contactState) => (dispatch) => {
    try {
        return axios.post("/contacts", contactState);
    } catch (error) {
        return console.log("ERREUR addContactSync", error);
    }
};

export const addOwnCompanyContact = (contactState, callback) => (dispatch) => {
    try {
        axios.get("/companies").then(cli => {
            const companies = cli.data;
            const ownCompanyID = companies.filter(comp =>
                comp.name.toLowerCase().trim().includes(OWN_COMPANY_NAME.toLowerCase())
            )[0].id;
            return axios.post("/contacts", {...contactState,company:ownCompanyID}).then(cli => {
                dispatch({ type: ADD_CONTACT, payload: cli.data });
                if (callback && typeof(callback)==='function') callback(cli.data?.id);
            });
        });
    } catch (error) {
        return console.log("ERREUR addContactSync", error);
    }
}

export const getKardhamContact = () => (dispatch) => {
    try {
        getAllContactsByCompany_(process.env.REACT_APP_OWN_COMPANY_ID).then(r=>{
            dispatch({ type: SET_OWN_COMPANY_CONTACTS, payload: r.data.data.contacts });
        })
    } catch (error) {
        return console.log("ERREUR addContactSync", error);
    }
}

export const getAllContactsByCompanyAsync = (companyId) => (dispatch) => {
    try {
        getAllContactsByCompany_(companyId).then(r=>{
                dispatch({ type: GET_ALL_CONTACT, payload: r.data.data.contacts });
            }) 
    } catch (error) {
        return console.log("ERREUR GET ALL COMPANY ", error);
    }
}