import axios from "axios";

export const GET_ALL_SITE = "GET_ALL_SITE";
export const GET_ONE_SITE = "GET_ONE_SITE";
export const GET_ALL_SITE_GRAPHQL = "GET_ALL_SITE_GRAPHQL";

export const getAllSites = () => (dispatch) => {
    try {
        return axios.get("/sites")
                    .then(cli => {
                        dispatch({ type: GET_ALL_SITE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL SITE ", error);
    }
};

export const getOneSite = (id) => (dispatch) => {
    try{
        return axios.get(`/sites/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_SITE, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const getAllSitesByCompany = (companyId) => (dispatch) => {
    try {
        const querya = `query getSites($cid:ID!){
            sites (where : {company : {id : $cid}}) {
              id
              name
              address
              postal_code
              town
              country
              buildings {
                  id
                  name
              }
              company {
                  id
                  name
              }
            }
        }`;
        const variablesa = { "cid" : companyId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL SITE ", error);
    }
};

export const addSiteSync = (siteState) => (dispatch) => {
    try {
        return axios.post("/sites", siteState);
    } catch (error) {
        return console.log("ERREUR AJOUT SITE ", error);
    }
};

export const updateSiteSync = (siteState) => (dispatch) => {
    console.log('siteState', siteState);
    try {
        console.log('siteState', siteState);
        return axios.put(`/sites/${siteState.id}`, siteState);
    } catch (error) {
        console.log('siteState.error', error);
        return console.log("ERREUR MODIFICATION SITE ", error);
    }
};


export const getAllSitesSync = () => (dispatch) => {
    try {
        return axios.get("/sites");
    } catch (error) {
        return console.log("ERREUR GET ALL SITE ", error);
    }
};

export const importSiteData = (siteData) => {
    try {
        
        if (window.location.origin.indexOf("localhost") !== -1){
            return axios.post("http://localhost/gp/import-site.php", siteData,{
                headers: { 'Content-Type': 'application/json;charset=UTF-8' }
            });
        }
        else {
            return axios.post(window.location.origin + "/import-site.php", siteData,{
                headers: { 'Content-Type': 'application/json' }
            });
        }
    } catch (error) {
        return console.log("importSite", error);
    }
};