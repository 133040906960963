import {
  GET_ALL_SITE,
  GET_ONE_SITE,
  GET_ALL_SITE_GRAPHQL
} from "../actions/siteAction";

const initialState = {
  sites: [],
  site: {}
};

export function siteReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SITE:
      return { ...state, sites: action.payload };
      
    case GET_ONE_SITE:
      return { ...state, site: action.payload };

    case GET_ALL_SITE_GRAPHQL:
      return { ...state, sites: action.payload };      

    default:
      return { ...state };
  }
}
