const mapStateToProps = (state) => {
  return {
    users: state.users.users,
    user: state.users.user,
    errorUser: state.users.error,
    roles: state.roles.roles,
    companies: state.companies.companies,
    company: state.companies.company,
    companyTypes: state.companyTypes.companyTypes,
    documentTypes: state.documentTypes.documentTypes,
    documents: state.documents.documents,
    contacts: state.contacts.contacts,
    contact: state.contacts.contact,
    ownCompanyContacts: state.contacts.ownCompanyContacts,
    tickets: state.tickets.tickets,
    ticketsNotFiltered: state.tickets.all,
    ticket: state.tickets.ticket,
    ticketPriorities: state.ticketPriorities.ticketPriorities,
    ticketPriority: state.ticketPriorities.ticketPriority,
    ticketStatuts: state.ticketStatuts.ticketStatuts,
    ticketStatut: state.ticketStatuts.ticketStatut,
    ticketTypes: state.ticketTypes.ticketTypes,
    ticketObjects: state.ticketObjects.ticketObjects,
    ticketObject: state.ticketObjects.ticketObject,
    ticketMotifs: state.ticketMotifs.ticketMotifs,
    ticketMotif: state.ticketMotifs.ticketMotif,
    ticketHistories: state.ticketStatuts.ticketHistories,
    ticketHistory: state.ticketStatuts.ticketHistory,
    ticketOrigins: state.ticketOrigins.ticketOrigins,
    ticketOrigin: state.ticketOrigins.ticketOrigin,
    knowledgeBases: state.knowledgeBases.knowledgeBases,
    knowledgeBase: state.knowledgeBases.knowledgeBase,
    knowledgeCategories: state.knowledgeCategories.knowledgeCategories,
    knowledgeCategory: state.knowledgeCategories.knowledgeCategory,
    
    needRefresh : state.navigation.needRefresh,

    //configurations stored in DB
    dataConfig: state.configData.config,

    //company materials
    companyMaterials: state.materials.materials,

    sites: state.sites.sites,

    ticketConsultations: state.ticketConsultations.ticketConsultations,
    
    ticketListState: state.ticketListState.ticketListState,
  };
};
  
export default mapStateToProps; 
