/**
 * TicketPage
 * Création : 15/03/2023
 * Page contenant la liste des tickets du compte du user connecté (si client), sinon la liste de tous les tickets (si admin)
 * Historique
 *      DD/MM/YYYY   log
 */
import React, { useState } from "react";
import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";
import { useEffect } from "react";
import GenericDatagrid from "../../components/datagrid/generic-datagrid";
import { Backdrop, Chip, CircularProgress } from "@mui/material";
import GridFilter from "../../components/filter/grid-filter";

import SortIcon from "@mui/icons-material/Sort";

//import { ReactComponent as Upload } from "../../assets/images/icon-upload.svg";

import ImportExportIcon from "@mui/icons-material/ImportExport";

import "./ticket.scss";
import { useLocation, useNavigate } from "react-router-dom";

import { getClassIcon, getLocaleDateTime } from "../../core/utils";
import {
  TICKET_LIST_DEFAULT,
  TICKET_LIST_ME,
} from "../../redux/reducers/ticketListReducers";
import RecentItems from "./recentItems";

import { exportToExcel, getDateYYYYMMDD } from "../../core/utils";
import useWindowSize from "../../hooks/useWindowSize";

const columnsFilter = [
  { field: "id", title: "ID", type: "text", active: true },
  { field: "objet", title: "Objet", type: "text", active: true },
  { field: "comment", title: "Description", type: "text", active: true },
  { field: "comments", title: "Commentaire", type: "text", active: true },
  { field: "company.name", title: "Nom du compte", type: "text", active: true },
  {
    field: "created_at",
    title: "Date de création",
    type: "date",
    active: true,
  },
  {
    field: "updated_at",
    title: "Date de modification",
    type: "date",
    active: true,
  },
  { field: "closedDate", title: "Date de clôture", type: "date", active: true },
  {
    field: "ticket_priority.name",
    title: "Priorité",
    type: "text",
    active: true,
  },
  { field: "ticket_statut.name", title: "Statut", type: "text", active: true },
  {
    field: "responsible_user.lastname",
    title: "Responsable du ticket",
    type: "text",
    active: true,
  },
];

const TicketPage = (props) => {
  const {
    getAllTickets,
    getCompanyTickets,
    tickets,
    getFilteredTicketsGraphql,
    needRefresh,
    user,
    getOneCompany,
    company,
    currentUser,
    ticketListState,
    setTicketList,
    getTicketConsultationsByUser,
    selectTicket,
    getAllTicketsNotFiltered
  } = props;
  const companyID = user?.company;
  const isAdmin = user?.role?.type === "admin";
  const [isLoading, setIsLoading] = useState(false);
  /*leftside retract ticket*/
  const [recentItemsAreShown, setRecentItemsAreShown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [dataToExport, setDataToExport] = useState([]);
  const size = useWindowSize();
  const widthWindow = size.width;
  const [gridFilterActive, setGridFilterActive] = useState(false);
  //const heightWindow = size.height;

  const idCol = {
    field: "id",
    headerName: "#",
    inputType: "text",
    width: 55,
    filterable: true,
    list: true,
    export: true,
    copy: true,
    listdetails: true,
    add: false,
  };
  const companyColumn = {
    field: "company",
    headerName: "Nom du compte",
    width: 150,
    export: true,
    list: true,
    copy: false,
    listdetails: false,
    renderCell: (params) => {
      return <p>{params.row?.company?.name}</p>;
    },
    valueGetter: ({ row }) => row?.company?.name,
  };
  const otherTicketsCols = [
    {
      field: "objet",
      headerName: "Objet",
      inputType: "text",
      /*flex: 2,*/
      minWidth: widthWindow > 1024 ? 170 : 120,
      export: true,
      list: true,
      copy: false,
    },
    {
      field: "created_at",
      headerName: "Date de création",
      inputType: "text",
      //flex: 0.2,
      width: 175,
      filterable: true,
      className: "desktopOnly",
      list: widthWindow > 1024 ? true : false,
      export: true,
      copy: false,
      listdetails: false,
      renderCell: (params) => {
        return <p>{getLocaleDateTime(params?.value, currentUser?.timezone)}</p>;
      },
      //type: "dateTime",
    },
    {
      field: "updated_at",
      headerName: "Dernière modification",
      inputType: "text",
      //flex: 0.2,
      width: 175,
      filterable: true,
      list: widthWindow > 1024 ? true : false,
      export: true,
      copy: false,
      listdetails: false,
      renderCell: (params) => (
        <p>{getLocaleDateTime(params?.value, currentUser?.timezone)}</p>
      ),
      //type: "dateTime",
    },
    {
      field: "closedDate",
      headerName: "Date de clôture",
      inputType: "text",
      //flex: 0.2,
      width: 175,
      filterable: true,
      list: widthWindow > 1024 ? true : false,
      export: true,
      copy: false,
      listdetails: false,
      renderCell: (params) => {
        const closeDate = params?.value;
        return (
          <>
            {closeDate ? (
              <p>{getLocaleDateTime(closeDate, currentUser?.timezone)}</p>
            ) : (
              ""
            )}
          </>
        );
      },
      //type: "dateTime",
    },
    {
      field: "ticket_priority",
      headerName: "Priorité",
      inputType: "list",
      width: widthWindow > 1024 ? 130 : 100,
      provider: {
        source: { func: "getAllTicketPriorities", data: "ticketPriorities" },
        displayField: "name",
        valueField: "id",
      },
      renderCell: (params) => {
        if (params?.value) {
          const { name, classofprio } = params?.row?.ticket_priority;
          return (
            <Chip
              className={`ticketPriorityField ${
                classofprio ? classofprio : ""
              }`}
              label={name}
              variant="fill"
              size="small"
            ></Chip>
          );
        } else return <></>;
      },
      filterable: true,
      add: true,
      edit: true,
      list: true,
      export: true,
      copy: true,
      listdetails: false,
      //valueGetter: ({ row }) => row?.ticket_priority?.name,
      type: "singleSelect",
      valueOptions: ["Faible", "Moyenne", "Haute", "Critique"],
    },
    {
      field: "statut",
      headerName: "Statut",
      inputType: "list",
      width: widthWindow > 1024 ? 150 : 100,
      provider: {
        source: { func: "getAllTicketStatuts", data: "ticketStatuts" },
        displayField: "name",
        valueField: "id",
      },
      renderCell: (params) => {
        if (params?.row?.ticket_statut) {
          const { name } = params?.row?.ticket_statut;
          //console.log(params?.row?.ticket_statut.class)
          return (
            <div
              className={
                params?.row?.ticket_statut?.class + " ticketStatusField"
              }
            >
              <span
                className={
                  " stateIcon " +
                  getClassIcon(params?.row?.ticket_statut?.class)
                }
              ></span>
              <span className="n-s-mobile">{name} </span>
            </div>
          );
        } else return <></>;
      },
      filterable: true,
      add: true,
      edit: true,
      list: true,
      export: true,
      copy: true,
      listdetails: false,
      valueGetter: ({ row }) => row?.ticket_statut?.name,
    },
    {
      field: "responsible_user",
      headerName: "Responsable du ticket",
      /*flex: 2,*/
      width: 250,
      valueGetter: ({ row }) =>
        row.responsible_user
          ? `${row?.responsible_user?.name} ${row?.responsible_user?.lastname}`
          : "",
      filterable: true,
      list: widthWindow > 1024 ? true : false,
      export: true,
      copy: true,
      listdetails: false,
      mandatory: true,
    },
  ];
  const defaultColumns = [idCol, ...otherTicketsCols];
  const colsWithCompName = [idCol, companyColumn, ...otherTicketsCols];

  const ticketParams = {
    name: "tickets",
    access: ["client"],
    title:
      ticketListState === TICKET_LIST_DEFAULT
        ? `Tous les tickets`
        : "Tickets créés par moi",
    menu: true,
    description: "",
    component: "TicketPage",
    icon: "",
    isParent: false,
    fields: isAdmin ? colsWithCompName : defaultColumns,
  };
  const ticketsOrderedyDate = tickets
    ?.slice()
    ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));

  useEffect(() => {
    setIsLoading(true);
    loadDefaultData();
    //setCanDelete(user?.role?.type === 'admin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh]);
  useEffect(() => {
    setIsLoading(false);
    setDataToExport(
      tickets
        ? tickets.map((d) => {
            const firstContact =
              d.contacts && d.contacts.length > 0 ? d.contacts[0] : null;
            const suppContact =
              d.contacts && d.contacts.length > 1 ? d.contacts[1] : null;

            return {
              id: d.id,
              compte: d.company?.name,
              objet_ticket: d.ticket_object?.name,
              date_creation: d.created_at
                ? `${new Date(d.created_at).toLocaleDateString()}`
                : "",
              heure_creation: d.created_at
                ? `${new Date(d.created_at).toLocaleTimeString()}`
                : "",
              date_maj: d.updated_at
                ? `${new Date(d.updated_at).toLocaleDateString()}`
                : "",
              heure_maj: d.updated_at
                ? `${new Date(d.updated_at).toLocaleTimeString()}`
                : "",
              date_cloture: d.closedDate
                ? `${new Date(d.closedDate).toLocaleDateString()}`
                : "",
              heure_cloture: d.closedDate
                ? `${new Date(d.closedDate).toLocaleTimeString()}`
                : "",
              priorite: d.ticket_priority?.name,
              statut: d.ticket_statut?.name,
              responsable: d.responsible_user
                ? `${d.responsible_user?.name} ${d.responsible_user?.lastname}`
                : "",
              idJira: d.idJira,
              urlJira: d.urlJira,
              anomalie: d.ticket_motif ? d.ticket_motif.name : "Aucun",
              origine: d.ticket_origin?.name,
              proprietaire_compte: d.company?.contact_owner?.firstName
                ? d.company?.contact_owner?.firstName +
                  " " +
                  d.company?.contact_owner?.lastName
                : "-",
              nom_compte: d.company?.name,
              nom_contact: firstContact
                ? firstContact.firstName + " " + firstContact.lastName
                : "-",
              email_contact: firstContact ? firstContact.email : "-",
              tel_contact: firstContact
                ? firstContact.mobile_number
                  ? firstContact.mobile_number
                  : firstContact.fix_number
                  ? firstContact.fix_number
                  : "-"
                : "-",
              email_supp_contact: suppContact
                ? suppContact.email
                  ? suppContact.email
                  : "-"
                : "-",
              objet: d.objet,
              description: d.comment,
            };
          })
        : []
    );
  }, [tickets]);
  useEffect(() => {
    if (user?.company) getOneCompany(user?.company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    //console.log(user?.id);
    if (user?.id) getTicketConsultationsByUser(user?.id);
    if (location?.pathname?.toLowerCase()?.includes("tickets/me"))
      setTicketList(TICKET_LIST_ME);
    else setTicketList(TICKET_LIST_DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    loadDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);
  useEffect(() => {
    // console.log(size);
    if (size?.height && size?.width) {
      if (size.width <= 1024) {
        setRecentItemsAreShown(false);
      } else setRecentItemsAreShown(true);
    }
  }, [size]);

  const loadDefaultData = () => {
    // console.log(user, company);
    getAllTicketsNotFiltered();
    if (user?.username) {
      getTicketConsultationsByUser(user?.id);
      if (isAdmin) getAllTickets();
      else {
        if (company?.name?.toLowerCase()?.includes("kardham") || !user?.company)
          getAllTickets();
        else getCompanyTickets(companyID);
      }
    }
  };

  const handleFilterChanged = (filter) => {
    setIsLoading(true);
    if (filter && filter.length > 0) {
      //si l'id_company de l'utilisateur est nul, il devrait être admin de kardham
      if (companyID || isAdmin) {
        if (company?.name?.toLowerCase()?.includes("kardham"))
          getFilteredTicketsGraphql(filter, null);
        else getFilteredTicketsGraphql(filter, companyID);
      }
    } else {
      loadDefaultData();
    }
  };
  const handleClickRetract = () => {
    setRecentItemsAreShown(!recentItemsAreShown);
  };
  const handleSelectGridRow = (param) => {
    selectTicket(param?.row);
  };
  const handleSelectTicket = (ticket) => {
    selectTicket(ticket);
    navigate(`/ticket/${ticket?.id}`);
  };
  //console.log(user);

  const handleExport = (e) => {
    const filename = `ListeTickets_${getDateYYYYMMDD()}`;
    const params = {
      fields: [
        {
          name: "id",
          label: "Id",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "compte",
          label: "Compte",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "objet_ticket",
          label: "Objet du ticket",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "date_creation",
          label: "Date de création",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "heure_creation",
          label: "Heure de création",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "date_maj",
          label: "Date de mise à jour",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "heure_maj",
          label: "Heure de mise à jour",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "date_cloture",
          label: "Date de clôture",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "heure_cloture",
          label: "Heure de clôture",
          alignment: { vertical: "top" },
        },
        {
          name: "priorite",
          label: "Priorité",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "statut",
          label: "Statut",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        {
          name: "responsable",
          label: "Responsable",
          alignment: { vertical: "top" },
          columnWidth: 1,
        },
        { name: "anomalie", label: "Anomalie", alignment: { vertical: "top" } },
        { name: "origine", label: "Origine", alignment: { vertical: "top" } },
        {
          name: "proprietaire_compte",
          label: "Propriétaire du compte",
          alignment: { vertical: "top" },
        },
        {
          name: "nom_compte",
          label: "Nom du compte",
          alignment: { vertical: "top" },
        },
        {
          name: "nom_contact",
          label: "Nom du contact",
          alignment: { vertical: "top" },
        },
        {
          name: "email_contact",
          label: "Email du contact",
          alignment: { vertical: "top" },
        },
        {
          name: "tel_contact",
          label: "Tel du contact",
          alignment: { vertical: "top" },
        },
        {
          name: "email_supp_contact",
          label: "Email supplémentaire du contact",
          alignment: { vertical: "top" },
        },
        {
          name: "objet",
          label: "Objet",
          alignment: { vertical: "top" },
          columnWidth: 2,
        },
        {
          name: "description",
          label: "Description",
          alignment: { vertical: "top", wrapText: true },
          columnWidth: 6,
        },
      ],
      data: dataToExport,
    };

    exportToExcel(filename, params);
  };

  const handleFilterActive = (filterActive) => {
    setGridFilterActive(filterActive);
  }

  return (
    <div
      className={`ticketContent ${recentItemsAreShown ? "" : "retract"}`}
      style={{ display: "flex" }}
    >
      <span className="retractBtn fordesktop">
        <a href="#;">
          <button
            className={`show ${recentItemsAreShown ? "hide" : ""}`}
            onClick={handleClickRetract}
          >
            <SortIcon />
          </button>
        </a>
      </span>
      <div className={`contractLeft`}>
        <RecentItems
          isShow={recentItemsAreShown}
          onClickRetract={handleClickRetract}
          onSelectTicket={handleSelectTicket}
        />
      </div>

      <div className={`rightCol ${gridFilterActive ? "gridFilterActive": ""}`}>
        <div className="export">
          <span
            className={`retractBtn formobile ${
              recentItemsAreShown ? "hidelink" : ""
            }`}
          >
            <a href="#;">
              <button
                className={`show ${recentItemsAreShown ? "hide" : ""}`}
                onClick={handleClickRetract}
              >
                <SortIcon />
              </button>
            </a>
          </span>
          <button className="btn primary" onClick={handleExport}>
            <ImportExportIcon /> Exporter la liste
          </button>
        </div>
        <h2>Tous les tickets</h2>
        {/* <div className="filter">
          <FilterListIcon />
          <TextField id="outlined-basic" label="Filter" variant="outlined" />
        </div> */}
        <GridFilter
          fields={columnsFilter}
          onFilterActive={handleFilterActive}
          onFilterChanged={handleFilterChanged}
        />
        <div className="listOfTicket">
          <Backdrop
            sx={{
              backgroundColor: "rgba(0,0,0,0)",
              zIndex: 10,
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
            open={isLoading}
          >
            <CircularProgress />
          </Backdrop>
          <GenericDatagrid
            data={
              ticketListState === TICKET_LIST_DEFAULT
                ? ticketsOrderedyDate
                : ticketsOrderedyDate?.filter(
                    (tick) => tick?.creator_user?.id === user?.id
                  )
            }
            fields={ticketParams?.fields.filter((f) => f.list)}
            title={ticketParams.title}
            allowEditing={false}
            allowDeleting={false}
            withToolbar={false}
            type="ticket"
            onSelectRow={handleSelectGridRow}
          />
        </div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(TicketPage);
