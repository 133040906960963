
import {
  ADD_TICKET_HISTORY,
  GET_ALL_TICKET_HISTORY,
  GET_ONE_TICKET_HISTORY,
  MODIF_TICKET_HISTORY
} from "../actions/ticketHistoryAction";

const initialState = {
  ticketHistories: [],
  ticketHistory: {}
};

export function ticketHistoryReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TICKET_HISTORY:
      return { ...state, ticketHistories: action.payload };

    case GET_ONE_TICKET_HISTORY:
      return { ticketHistory: action.payload };

    case ADD_TICKET_HISTORY:
      return { ...state, ticketHistory: action.payload };

    case MODIF_TICKET_HISTORY:
        return { ...state, ticketHistory: action.payload };
  
    default:
      return { ...state };
  }
}