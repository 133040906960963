import axios from "axios";

export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const GET_ONE_COMPANY = "GET_ONE_COMPANY";
export const MODIF_COMPANY = "MODIF_COMPANY";

export const getAllCompanies =
  (async_ = false) =>
  (dispatch) => {
    try {
      return async_
        ? axios.get("/companies")
        : axios.get("/companies").then((cli) => {
            dispatch({ type: GET_ALL_COMPANY, payload: cli.data });
          });
    } catch (error) {
      return console.log("ERREUR GET ALL COMPANY ", error);
    }
  };

export const getOneCompany = (id) => (dispatch) => {
  try {
    return axios
      .get(`/companies/${id}`)

      .then((rep) => {
        dispatch({ type: GET_ONE_COMPANY, payload: rep.data });
      });
  } catch (error) {
    return console.log("ERREUR ", error);
  }
};

export const getOneCompany_ = (id) => {
  try {
    return axios.get(`/companies/${id}`);
    /*
                    .then(rep => {
                        dispatch({ type: GET_ONE_COMPANY, payload: rep.data });
                    });
        */
  } catch (error) {
    return console.log("ERREUR ", error);
  }
};

export const updateCompany = (companyState) => (dispatch) => {
  try {
      return axios.put(`/companies/${companyState.id}`, companyState)
                  .then(cli => {
                      dispatch({ type: MODIF_COMPANY, payload: cli.data })
                  });
  } catch (error) {
      return console.log("ERREUR MODIFICATION COMPANY ", error);
  }
};