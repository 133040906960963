import axios from "axios";

export const GET_ALL_TICKET_ORIGIN = "GET_ALL_TICKET_ORIGIN";
export const ADD_TICKET_ORIGIN = "ADD_TICKET_ORIGIN";
export const MODIF_TICKET_ORIGIN = "MODIF_TICKET_ORIGIN";
export const DELETE_TICKET_ORIGIN = "DELETE_TICKET_ORIGIN";
export const GET_ONE_TICKET_ORIGIN = "GET_ONE_TICKET_ORIGIN";

export const getAllTicketOrigins = () => (dispatch) => {
  try {
      return axios.get("/ticket-origins")
                  .then(cli => {
                      dispatch({ type: GET_ALL_TICKET_ORIGIN, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR GET ALL TICKET_ORIGIN ", error);
  }
};

export const addTicketOrigin = (ticketOriginState, history) => (dispatch) => {
  try {
      return axios.post("/ticket-origins", ticketOriginState)
                  .then(cli => {
                      dispatch({ type: ADD_TICKET_ORIGIN, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR AJOUT TICKET_ORIGIN ", error);
  }
};

export const updateTicketOrigin = (ticketOriginState) => (dispatch) => {
  try {
      return axios.put(`/ticket-origins/${ticketOriginState.id}`, ticketOriginState)
                  .then(cli => {
                      dispatch({ type: MODIF_TICKET_ORIGIN, payload: cli.data })
                  });
  } catch (error) {
      return console.log("ERREUR MODIFICATION TICKET_ORIGIN ", error);
  }
};

export const deleteTicketOrigin = (id) => (dispatch) => {
  try {
      return axios.delete(`/ticket-origins/${id}`)
                  .then((cli) => {
                      dispatch({ type: DELETE_TICKET_ORIGIN, payload: cli.data });
                  });
  } catch (error) {
      return console.log("ERREUR DELETE TICKET_ORIGIN ", error);
  }
};

export const getOneTicketOrigin = (id) => (dispatch) => {
  try{
      return axios.get(`/ticket-origins/${id}`)
                  .then(rep => {
                      dispatch({ type: GET_ONE_TICKET_ORIGIN, payload: rep.data });
                  });
  } catch (error) {
      return console.log("ERREUR ", error);
  }
};