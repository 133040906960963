/* eslint-disable react-hooks/exhaustive-deps */
/**
 * DashboardPage
 * Création : 15/03/2023
 * Page contenant le tableau de bord de l'application (filtré par rapport au user connecté : client ou admin)
 * Historique
 *      DD/MM/YYYY   log
 */
import { useState, useEffect } from "react";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { Select, MenuItem, TextField, FormControl } from '@mui/material';

import { addDays, getMondayOfCurrentWeek, 
    getSundayOfCurrentWeek, getMondayOfLastWeek, getSundayOfLastWeek,
    getFirstDayOfCurrentMonth, getLastDayOfCurrentMonth, 
    getFirstDayOfLastMonth, getLastDayOfLastMonth,
    getFirstDayOfCurrentYear, getLastDayOfCurrentYear } from "../../core/utils";

import TicketByStatut from "./ticket-by-statut";
import TicketByContact from "./ticket-by-contact";
import TicketByObjet from "./ticket-by-objet";
import TicketByType from "./ticket-by-type";
import TicketByPriorite from "./ticket-by-priorite";
import TicketByCompte from "./ticket-by-compte";

import './dashboard.scss';

const DashboardPage = (props) => {
    const { currentUser,
        getAllTickets, getCompanyTickets, tickets, 
        getAllTicketStatuts, ticketStatuts, 
        getAllTicketPriorities, ticketPriorities,
        getAllTicketObjects, ticketObjects,
        getAllTicketTypes, ticketTypes,
        getAllCompanies, companies,
        getAllContactsByCompanyAsync, contacts } = props;

    const [filteredDataStatus, setFilterDataStatus] = useState({labels:[], datasets:[]});
    const [filteredDataPriority, setFilterDataPriority] = useState({labels:[], datasets:[]});
    const [filteredDataObjet, setFilterDataObjet] = useState({labels:[], datasets:[]});
    const [filteredDataResponsable, setFilterDataResponsable] = useState({labels:[], datasets:[]});
    const [filteredDataType, setFilterDataType] = useState({labels:[], datasets:[]});

    const [currentFilterComparison, setCurrentFilterComparison] = useState("*");
    const [showFirstTextfieldFilter, setShowFirstTextfieldFilter] = useState(false);
    const [showSecondTextfieldFilter, setShowSecondTextfieldFilter] = useState(false);
    const [showThirdTextfieldFilter, setShowThirdTextfieldFilter] = useState(false);

    const [firstDateFilter, setFirstDateFilter] = useState("");
    const [secondDateFilter, setSecondDateFilter] = useState("");
    const [daysNumberFilter, setDaysNumberFilter] = useState("");

    const [totalTicket, setTotalTicket] = useState(0);
    const [ticketByStatut, setTicketByStatut] = useState({});
    const [ticketByContact, setTicketByContact] = useState({});
    const [ticketByObjet, setTicketByObjet] = useState({});
    const [ticketByType, setTicketByType] = useState({});
    const [ticketByPriorite, setTicketByPriorite] = useState({});
    const [ticketByCompany, setTicketByCompany] = useState({});

    const [noTicket, setNoTicket] = useState(false)

    useEffect(() => {
        console.log('tickets', tickets)
        if (currentUser){
            if (currentUser.role?.type === 'admin') getAllTickets();
            else getCompanyTickets(currentUser?.company)
        }

    }, [currentUser]);

    useEffect(() => {

        if (tickets?.length > 0) {
            setNoTicket(false);
            setTotalTicket(tickets.length);

            getAllTicketStatuts();
            getAllTicketPriorities();
            getAllTicketObjects();
            getAllTicketTypes();

            if (currentUser.role?.type === 'admin') getAllCompanies();
            else getAllContactsByCompanyAsync(currentUser?.company);
        }
        else {
            setNoTicket(true)
        }

    }, [tickets])

    useEffect(() => {
        if (tickets && ticketStatuts) buildFilterDataStatus();
    }, [ticketStatuts])

    useEffect(() => {
        if (tickets && ticketPriorities) buildFilterDataPriority();
    }, [ticketPriorities])

    useEffect(() => {
        if (tickets && ticketObjects) buildFilterDataObjet();
    }, [ticketObjects])

    useEffect(() => {
        if (tickets && ticketTypes) buildFilterDataType();
    }, [ticketTypes])

    useEffect(() => {
        if (tickets && contacts) buildFilterDataContact();
    }, [contacts])

    useEffect(() => {
        if (tickets && companies) buildFilterDataCompany();
    }, [companies])

    useEffect(() => {
        setShowFirstTextfieldFilter(["=", ">=", "<=", "><"].includes(currentFilterComparison));
        setShowSecondTextfieldFilter(["><"].includes(currentFilterComparison));
        setShowThirdTextfieldFilter([">t-", "<t-", "t-"].includes(currentFilterComparison));
    }, [currentFilterComparison])

    useEffect(() => {
        refreshTicketCharts();
    }, [firstDateFilter, secondDateFilter, daysNumberFilter, currentFilterComparison])

    const refreshTicketCharts = () => {
        buildFilterDataStatus(); 
        buildFilterDataPriority();
        buildFilterDataObjet();
        buildFilterDataType();
        if (tickets && contacts) buildFilterDataContact();
        if (tickets && companies) buildFilterDataCompany();
    }

    const filterTickets = (arrTickets) => {
        if (currentFilterComparison !== "*"){
            let result = arrTickets;

            // eslint-disable-next-line default-case
            switch(currentFilterComparison){
                case "=":
                    case ">=":
                        case "<=":
                            case "><":
                                const startDateFilter = firstDateFilter === "" ? new Date() : new Date(firstDateFilter);
                                
                                const temps = arrTickets.filter((t) => {
                                    const creationDate = new Date(t.created_at);
                                    if (currentFilterComparison === "="){

                                        return creationDate.toISOString().substring(0, 10) === startDateFilter.toISOString().substring(0, 10);
                                    }
                                    else if (currentFilterComparison === ">=")
                                        return creationDate >= startDateFilter;
                                    else if (currentFilterComparison === "<=")
                                        return creationDate <= startDateFilter;
                                    else if (currentFilterComparison === "><"){
                                        const endDateFilter = secondDateFilter === "" ? new Date() : new Date(secondDateFilter);
                                        return startDateFilter <= creationDate && creationDate <= endDateFilter;
                                    }
                                })
                                result = temps;
                                break;
                                
                case ">t-":
                    case "<t-":
                            case "t-":
                                const days = +daysNumberFilter;
                                const today = new Date();
                                const temps2 = arrTickets.filter((t) => {
                                    const creationDate = new Date(t.created_at);
                                    
                                    if (currentFilterComparison === ">t-") // il y a moins de
                                        return creationDate > addDays(today, -days);
                                    else if  (currentFilterComparison === "<t-") // il y a plus de
                                        return creationDate < addDays(today, -days);
                                    else if  (currentFilterComparison === "t-") // il y a
                                        return creationDate.toISOString().substring(0, 10) === addDays(today, -days).toISOString().substring(0, 10);
                                })

                                result = temps2;
                                break;
                case "t": //aujourd'hui
                    const temps3 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const today = new Date();
                        return creationDate.toISOString().substring(0, 10) === today.toISOString().substring(0, 10);
                    })
                    result = temps3;
                    break;
                case "ld": //hier
                    const temps4 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const today = new Date();
                        return creationDate.toISOString().substring(0, 10) === addDays(today, -1).toISOString().substring(0, 10);
                    })
                    result = temps4;
                    break;
                case "w": //cette semaine
                    const temps5 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const monday = getMondayOfCurrentWeek();
                        const sunday = getSundayOfCurrentWeek();
                        return monday <= creationDate && creationDate <= sunday;
                    })
                    result = temps5;
                    break;
                case "lw": //la semaine dernière
                    const temps6 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const monday = getMondayOfLastWeek();
                        const sunday = getSundayOfLastWeek();

                        return monday <= creationDate && creationDate <= sunday;
                    })
                    result = temps6;
                    break;
                case "l2w": //les 2 dernières semaines
                    const temps7 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const monday = getMondayOfLastWeek();
                        const sunday = getSundayOfCurrentWeek();

                        return monday <= creationDate && creationDate <= sunday;
                    })
                    result = temps7;
                    break;
                case "m": //ce mois-ci
                    const temps8 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const firstDay = getFirstDayOfCurrentMonth();
                        const lastDay = getLastDayOfCurrentMonth();
                        
                        return firstDay <= creationDate && creationDate <= lastDay;
                    })
                    result = temps8;
                    break;
                case "lm": //le mois dernier
                    const temps9 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const firstDay = getFirstDayOfLastMonth();
                        const lastDay = getLastDayOfLastMonth();
                        
                        return firstDay <= creationDate && creationDate <= lastDay;
                    })
                    result = temps9;
                    break;
                case "y": //cette année
                    const temps10 = arrTickets.filter((t) => {
                        const creationDate = new Date(t.created_at);
                        const firstDay = getFirstDayOfCurrentYear();
                        const lastDay = getLastDayOfCurrentYear();
                        
                        return firstDay <= creationDate && creationDate <= lastDay;
                    })
                    result = temps10;
                    break;
            }

            return result;
        }

        return arrTickets;
    }

    const buildFilterDataStatus = () => {
        let temp = {};
        ticketStatuts.forEach(ts => {
            let temps = tickets.filter((t) => ts?.slug === t?.ticket_statut?.slug);
            temps = filterTickets(temps);

            if (temps.length > 0) {
                temp[ts.name] = {data:temps, color:ts.color, class: ts.class};
            }
        });

        setTicketByStatut(temp);
    }

    const buildFilterDataPriority = () => {
        let temp = {};
        ticketPriorities.forEach(tp => {
            let temps = tickets.filter((t) => tp?.slug === t.ticket_priority?.slug);

            temps = filterTickets(temps);

            temp[tp.name] = {data:temps, color:tp.color};;
        });

        setTicketByPriorite(temp)
    }

    const buildFilterDataObjet = () => {
        let temp = {};
        ticketObjects.forEach(tm => {
            let temps = tickets.filter((t) => tm.name === t.ticket_object?.name);
            temps = filterTickets(temps);

            temp[tm.name] = {data:temps, color:tm.color};
        });

        setTicketByObjet(temp);
    }

    const buildFilterDataType = () => {
        let temp = {};
        ticketTypes.forEach(tt => {
            let temps = tickets.filter((t) => tt.name === t.ticket_type?.name);
            temps = filterTickets(temps);

            if (temps.length > 0){
                temp[tt.name] = {data:temps, color:tt.color};
            }
        });

        setTicketByType(temp);
    }

    const buildFilterDataContact = () => {
        let temp = {};
        contacts.forEach(c => {
            let temps = tickets.filter((t) => {
                if (t.contacts.length > 0){
                    return c.id === t.contacts[0].id
                }
                return false
            });
            const displayName = `${c.firstName} ${c.lastName}`;

            temps = filterTickets(temps);

            temp[displayName] = {data:temps};
        });

        setTicketByContact(temp)
    }

    const buildFilterDataCompany = () => {
        let temp = {};
        companies.forEach(tt => {
            let temps = tickets.filter((t) => tt.name === t.company?.name);
            temps = filterTickets(temps);

            if (temps.length > 0){
                temp[tt.name] = {data:temps, color:tt.color};
            }
        });

        setTicketByCompany(temp)
    }

    const handleFilterChange = (e) =>{
        setCurrentFilterComparison(e.target.value);
    }

    const handleFirstDateChange = (e) =>{
        setFirstDateFilter(e.target.value);
    }

    const handleSecondDateChange = (e) =>{
        setSecondDateFilter(e.target.value);
    }

    const handleDaysNumberChange = (e) =>{
        setDaysNumberFilter(e.target.value);
    }

    return (
        <div className="dashboard">
            <h1 className="title">Dashboard</h1>
            {tickets && tickets.length > 0 &&
            <>
                <div className="filter">
                    <div className="inputContent labelSelection">
                            <label className="trierpar">Nombre de tickets :</label>
                            <Select 
                                className="selectDash"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="filter"
                                defaultValue="*"
                                value={currentFilterComparison}
                                onChange={handleFilterChange}>
                                <MenuItem value="*">tous</MenuItem>
                                <MenuItem value="=">égal</MenuItem>
                                <MenuItem value=">=">&gt;=</MenuItem>
                                <MenuItem value="<=">&lt;=</MenuItem>
                                <MenuItem value="><">entre</MenuItem>
                                <MenuItem value=">t-">il y a moins de</MenuItem>
                                <MenuItem value="<t-">il y a plus de</MenuItem>
                                <MenuItem value="t-">il y a</MenuItem>
                                <MenuItem value="t">aujourd'hui</MenuItem>
                                <MenuItem value="ld">hier</MenuItem>
                                <MenuItem value="w">cette semaine</MenuItem>
                                <MenuItem value="lw">la semaine dernière</MenuItem>
                                <MenuItem value="l2w">les 2 dernières semaines</MenuItem>
                                <MenuItem value="m">ce mois-ci</MenuItem>
                                <MenuItem value="lm">le mois dernier</MenuItem>
                                <MenuItem value="y">cette année</MenuItem>
                            </Select>
                            {showFirstTextfieldFilter &&
                                <div className="inputContent dateStart">
                                    <FormControl fullWidth variant="outlined" size="small" margin="normal">
                                        <TextField fullWidth variant="outlined" type="date" margin="normal" size="small" onChange={handleFirstDateChange} />
                                    </FormControl>
                                </div>
                            }
                            {showSecondTextfieldFilter &&
                                <>
                                    <label className="et">et</label>
                                    <div className="inputContent dateEnd">
                                        <FormControl fullWidth variant="outlined" size="small" margin="normal">
                                            <TextField fullWidth variant="outlined" type="date" margin="normal" size="small" onChange={handleSecondDateChange} />
                                        </FormControl>
                                    </div>
                                </>
                            }
                            {showThirdTextfieldFilter &&
                                <div className="inputContent nbrDay">
                                    <FormControl fullWidth variant="outlined" size="small" margin="normal">
                                        <TextField fullWidth variant="outlined" type="number" margin="normal" size="small" onChange={handleDaysNumberChange} />
                                        <label className="jour">jours</label>
                                    </FormControl>
                                </div>
                            }
                    </div>  
                </div>
                <div className="content">
                    <div className="bloc ticket-by-statut">
                        <TicketByStatut data={ticketByStatut} totalTickets={totalTicket} />
                    </div>
                    <div className={currentUser?.role?.type === 'admin' ? "bloc ticket-by-compte" : "bloc ticket-by-contact" }>{currentUser?.role?.type === 'admin' ? <TicketByCompte data={ticketByCompany} />: <TicketByContact data={ticketByContact} />}</div>
                    <div className="bloc ticket-by-objet"><TicketByObjet data={ticketByObjet} /></div>
                    <div className="bloc ticket-by-type"><TicketByType data={ticketByType} /></div>
                    <div className="bloc ticket-by-priority"><TicketByPriorite data={ticketByPriorite} totalTickets={totalTicket} /></div>
                </div>
            </>
            }
            {noTicket && 
                <div className="zero-ticket"><span>Vous n'avez pas encore créé de ticket</span></div>
            }
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);