import axios from "axios";

export const GET_CONFIG = "GET_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";

export const getConfig = (callback) => (dispatch) =>{
  try {
    return axios.get("/config")
                .then(cli => {
                    dispatch({ type: GET_CONFIG, payload: cli.data });
                    if (callback && typeof(callback)==='function') callback(cli.data);
                });
  } catch (error) {
      return console.log("ERREUR GET CONFIG ", error);
  }
} 

export const updateConfig = (configData, callback) => (dispatch) =>{
  try {
    return axios.put("/config", configData)
                .then(cli => {
                    dispatch({ type: UPDATE_CONFIG, payload: cli.data });
                    if (callback && typeof(callback)==='function') callback(cli.data);
                });
  } catch (error) {
      return console.log("ERREUR EDIT CONFIG ", error);
  }
} 