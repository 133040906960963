/**
 * SolutionCategoryPage
 * Création : 15/03/2023
 * Page contenant la liste des catégories des bases de connaissances
 * Historique
 *      22/04/2023   implémentation liste catégories
 */
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { Chip } from "@mui/material";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { getLocaleDateTime } from "../../core/utils";

import moment from "moment-timezone";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

import "./category.scss";

const SolutionCategoryPage = (props) => {
  const {
    getAllKnowledgeCategories,
    knowledgeCategories,
    getAllKnowledgeBasesByCategory,
    downloadFile,
    currentUser,
  } = props;
  const { category_id, kb_id } = useParams();
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [currentKnowledgeBase, setCurrentKnowledgeBase] = useState({});
  const navigate = useNavigate();
  const [classn, setClassn] = useState("firstpagesolution");
  const [classKB, setClassKB] = useState("");
  const initialTop = 83;
  const [scrollTop, setScrollTop] = useState(0);
  const [kbIndex, setKbIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [filteredKnowledgeBases, setFilteredKnowledgeBases] = useState([]);

  useEffect(() => {
    getAllKnowledgeCategories();
    if (category_id) {
      setLoading(true);
      getAllKnowledgeBasesByCategory(category_id).then((kbs) => {
        setKnowledgeBases(kbs.data);
        setFilteredKnowledgeBases(kbs.data);
        setLoading(false);
      });
    }
  }, [category_id]);

  useEffect(() => {
    if (knowledgeCategories && knowledgeCategories.length > 0) {
      //console.log('knowledgeCategories', knowledgeCategories, category_id)
      const currentCategorie = knowledgeCategories.filter(
        (kbc) => kbc.id == category_id
      )[0];
      setCurrentCategory(currentCategorie);
      //console.log('currentCategorie', currentCategorie)
      setClassKB(currentCategorie.class);
    }
  }, [knowledgeCategories]);

  useEffect(() => {
    if (knowledgeBases.length > 0) {
      if (kb_id && kb_id > 0) {
        const idx = knowledgeBases.findIndex((kb) => kb.id == kb_id);
        setCurrentKnowledgeBase(knowledgeBases[idx]);
      } else {
        setCurrentKnowledgeBase(knowledgeBases[0]);
      }
    }
  }, [knowledgeBases, kb_id]);

  useEffect(() => {
  }, [currentKnowledgeBase]);

  const gotoCategory = (kc) => {
    if (kc.knowledge_bases.length > 0) {
      setCurrentCategory(kc);
      navigate(`/solution/${kc.id}`);
      setClassn("gotosolution");
      setClassKB(kc.class);
      setScrollTop(0);
    }
  };

  const showKnowledgeBase = (kb, index) => {
    setKbIndex(index);
    setCurrentKnowledgeBase(kb);
    setClassn("gotodetailsolution");
  };

  const handleListScroll = (e) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    const temp = knowledgeBases?.filter((kb) => {
      return (
        kb.question?.toLowerCase().includes(filter.toLowerCase()) ||
        kb.answer?.toLowerCase().includes(filter.toLowerCase())
      );
    });
    setFilteredKnowledgeBases(temp);
    if (temp.length === 0) setCurrentKnowledgeBase(null);
    else {
      setCurrentKnowledgeBase(temp[0]);
    }
  };

  return (
    <div className="category-details-no-scroll">
      <h1 className="title">Solutions</h1>
      <div className={`solution-item-tabs ${classn}`}>
        <div className="list-category">
          {knowledgeCategories &&
            knowledgeCategories.map((kc, index) => {
              return (
                <div
                  key={index}
                  className={
                    "one-category " +
                    kc.class +
                    " " +
                    (kc.id == category_id ? "active" : "")
                  }
                  onClick={() => gotoCategory(kc)}
                >
                  <div className={kc.class}>
                    {kc.name}{" "}
                    <Chip label={kc.knowledge_bases.length} size="small"></Chip>
                  </div>
                  <div className="rightIco">
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="knowledges">
          <div className="list-knowledges">
            <div className="filter">
              <div
                className="category-resp first"
                onClick={() => setClassn("firstpagesolution")}
              >
                <span className="icon-back"></span>
              </div>
              <input
                type="text"
                placeholder={`Recherche dans ${currentCategory.name}`}
                onChange={handleFilterChange}
              />
            </div>
            {!loading && (
              <div className="list" onScroll={handleListScroll}>
                {filteredKnowledgeBases &&
                  filteredKnowledgeBases.map((k, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          "one-kb " +
                          classKB +
                          " " +
                          (k.id == currentKnowledgeBase?.id ? "active" : "") + 
                          " " +
                          ((k.id == currentKnowledgeBase?.id) && (scrollTop > (76*(index))) ? "topfixed": "") + 
                          " " +
                          ((k.id == currentKnowledgeBase?.id) && (scrollTop < ((76*index)-250)) ? "bottomfixed": "")
                        }
                        onClick={() => showKnowledgeBase(k, index)}
                      >
                        <div className="createdAt">
                          {getLocaleDateTime(
                            k.created_at,
                            currentUser.timezone
                          )}
                        </div>
                        <div className="question">{k.question}</div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className={"knowledge-details " + classKB}>
            <div className="scroll-knowledge">
              {currentKnowledgeBase && !loading && (
                <div>
                  <div className="title question">
                    <div
                      className="category-resp second"
                      onClick={() => setClassn("gotosolution")}
                    >
                      <span className="icon-back"></span>
                    </div>
                    {currentKnowledgeBase.question}
                  </div>
                  <div className="date createdAt">
                    {getLocaleDateTime(
                      currentKnowledgeBase.created_at,
                      currentUser.timezone
                    )}
                  </div>
                  <div
                    className="body"
                    dangerouslySetInnerHTML={{
                      __html: currentKnowledgeBase.answer,
                    }}
                  ></div>
                  <div className="documents">
                    {currentKnowledgeBase.documents?.map((doc) => {
                      return (
                        <div className="one-doc">
                          <div className="docname">
                            <TextSnippetOutlinedIcon />
                            {doc.name}{" "}
                          </div>
                          <div className="download">
                            <Button
                              className="btn primary inverse"
                              onClick={() => downloadFile(doc.file[0].id)}
                            >
                              <FileDownloadOutlinedIcon /> Télécharger
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolutionCategoryPage);
