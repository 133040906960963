import {
  GET_ALL_KNOWLEDGECATEGORY,
  MODIF_KNOWLEDGECATEGORY,
  DELETE_KNOWLEDGECATEGORY,
  ADD_KNOWLEDGECATEGORY,
  GET_ONE_KNOWLEDGECATEGORY
} from "../actions/knowledgeCategoryAction";

const initialState = {
  knowledgeCategories: [],
  knowledgeCategory: {},
};

export function knowledgeCategoryReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_KNOWLEDGECATEGORY:
      return { ...state, knowledgeCategories: action.payload };

    case ADD_KNOWLEDGECATEGORY:
      return { ...state, knowledgeCategory: action.payload };

    case MODIF_KNOWLEDGECATEGORY:
      return { ...state };

    case DELETE_KNOWLEDGECATEGORY:
      return { ...state };

    case GET_ONE_KNOWLEDGECATEGORY:
      return { knowledgeCategory: action.payload };

    default:
      return { ...state };
  }
}
