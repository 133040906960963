import axios from "axios";

export const GET_ALL_KNOWLEDGECATEGORY = "GET_ALL_KNOWLEDGECATEGORY";
export const ADD_KNOWLEDGECATEGORY = "ADD_KNOWLEDGECATEGORY";
export const MODIF_KNOWLEDGECATEGORY = "MODIF_KNOWLEDGECATEGORY";
export const DELETE_KNOWLEDGECATEGORY = "DELETE_KNOWLEDGECATEGORY";
export const GET_ONE_KNOWLEDGECATEGORY = "GET_ONE_KNOWLEDGECATEGORY";

export const getAllKnowledgeCategories = (async_=false) => (dispatch) => {
    try {
        return async_ 
            ? axios.get("/knowledge-categories")
            : axios.get("/knowledge-categories").then(cli => {
                    dispatch({ type: GET_ALL_KNOWLEDGECATEGORY, payload: cli.data });
                }) ;
    } catch (error) {
        return console.log("ERREUR GET ALL KNOWLEDGECATEGORY ", error);
    }
};

export const addKnowledgeCategory = (knowledgeCategoryState, history, callback) => (dispatch) => {
    try {
        return axios.post("/knowledge-categories", knowledgeCategoryState)
                    .then(cli => {
                        dispatch({ type: ADD_KNOWLEDGECATEGORY, payload: cli.data });
                        if (callback && typeof(callback)==='function') callback(cli.data);
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT KNOWLEDGECATEGORY ", error);
    }
};

export const updateKnowledgeCategory = (knowledgeCategoryState) => (dispatch) => {
    try {
        return axios.put(`/knowledge-categories/${knowledgeCategoryState.id}`, knowledgeCategoryState)
                    .then(cli => {
                        dispatch({ type: MODIF_KNOWLEDGECATEGORY, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION KNOWLEDGECATEGORY ", error);
    }
};

export const deleteKnowledgeCategory = (id) => (dispatch) => {
    try {
        return axios.delete(`/knowledge-categories/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_KNOWLEDGECATEGORY, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE KNOWLEDGECATEGORY ", error);
    }
};

export const getOneKnowledgeCategory = (id) => (dispatch) => {
    try{
        return axios.get(`/knowledge-categories/${id}`);
        /*
                    .then(rep => {
                        dispatch({ type: GET_ONE_KNOWLEDGECATEGORY, payload: rep.data });
                    });
        */                    
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const getOneKnowledgeCategory_ = (id) => {
    try{
        return axios.get(`/knowledge-categories/${id}`);
        /*
                    .then(rep => {
                        dispatch({ type: GET_ONE_KNOWLEDGECATEGORY, payload: rep.data });
                    });
        */                    
    } catch (error) {
        return console.log("ERREUR ", error);
    }
}