import axios from "axios";

export const GET_ALL_DOCUMENTTYPE = "GET_ALL_DOCUMENTTYPE";
export const ADD_DOCUMENTTYPE = "ADD_DOCUMENTTYPE";
export const MODIF_DOCUMENTTYPE = "MODIF_DOCUMENTTYPE";
export const DELETE_DOCUMENTTYPE = "DELETE_DOCUMENTTYPE";
export const GET_ONE_DOCUMENTTYPE = "GET_ONE_DOCUMENTTYPE";

export const getAllDocumentTypes = () => (dispatch) => {
    try {
        return axios.get("/document-types")
                    .then(cli => {
                        dispatch({ type: GET_ALL_DOCUMENTTYPE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL DOCUMENTTYPE ", error);
    }
};

export const addDocumentType = (documentTypeState, history) => (dispatch) => {
    try {
        return axios.post("/document-types", documentTypeState)
                    .then(cli => {
                        dispatch({ type: ADD_DOCUMENTTYPE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT DOCUMENTTYPE ", error);
    }
};

export const updateDocumentType = (documentTypeState) => (dispatch) => {
    try {
        return axios.put(`/document-types/${documentTypeState.id}`, documentTypeState)
                    .then(cli => {
                        dispatch({ type: MODIF_DOCUMENTTYPE, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION DOCUMENTTYPE ", error);
    }
};

export const deleteDocumentType = (id) => (dispatch) => {
    try {
        return axios.delete(`/document-types/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_DOCUMENTTYPE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE DOCUMENTTYPE ", error);
    }
};

export const getOneDocumentType = (id) => (dispatch) => {
    try{
        return axios.get(`/document-types/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_DOCUMENTTYPE, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};