import {
  GET_ALL_COMPANYTYPE,
  MODIF_COMPANYTYPE,
  DELETE_COMPANYTYPE,
  ADD_COMPANYTYPE,
  GET_ONE_COMPANYTYPE,
} from "../actions/companyTypeAction";

const initialState = {
  companyTypes: [],
};

export function companyTypeReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COMPANYTYPE:
      return { ...state, companyTypes: action.payload };

    case ADD_COMPANYTYPE:
      return { ...state, companyType: action.payload };

    case MODIF_COMPANYTYPE:
      return { ...state };

    case DELETE_COMPANYTYPE:
      return { ...state };

    case GET_ONE_COMPANYTYPE:
      return { companyTypes: action.payload };

    default:
      return { ...state };
  }
}
