import axios from "axios";

export const GET_ALL_TICKET_CONSULTATION = "GET_ALL_TICKET_CONSULTATION";
export const ADD_TICKET_CONSULTATION = "ADD_TICKET_CONSULTATION";
export const GET_ONE_TICKET_CONSULTATION = "GET_ONE_TICKET_CONSULTATION";
export const DELETE_TICKET_CONSULTATION = "DELETE_TICKET_CONSULTATION";

export const getAllTicketConsultation = () => (dispatch) => {
  try {
    return axios.get("/ticket-consultations").then((cli) => {
      console.log(cli);
      dispatch({ type: GET_ALL_TICKET_CONSULTATION, payload: cli.data });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL TICKET CONSULTATION ", error);
  }
};

export const addTicketConsultation =
  (ticketConsultationState, history) => (dispatch) => {
    // try {
    //   axios.get("/ticket-consultations?_limit=10000").then((cli) => {
    //     const ticket_user_consultations = cli.data?.filter(
    //       (c) =>
    //         c?.users_permissions_user?.id ===
    //           ticketConsultationState?.users_permissions_user &&
    //         c?.ticket?.id === ticketConsultationState?.ticket
    //     );
    //     ticket_user_consultations.forEach((consultation) => {
    //       try {
    //         axios.delete(`/tickets/${consultation?.id}`).then((cli) => {
    //           console.log(cli);
    //         });
    //       } catch (error) {
    //         return console.log("ERREUR DELETE TICKET ", error);
    //       }
    //     });
    //   });
    // } catch (error) {
    //   console.log(error);
    // }

    try {
      return axios
        .post("/ticket-consultations", ticketConsultationState)
        .then((cli) => {
          dispatch({ type: ADD_TICKET_CONSULTATION, payload: cli.data });
        });
    } catch (error) {
      return console.log("ERREUR AJOUT CONSULTATION TICKET ", error);
    }
  };

export const getOneTicketConsultation = (id) => (dispatch) => {
  try {
    return axios.get(`/ticket-consultations/${id}`).then((rep) => {
      dispatch({ type: GET_ONE_TICKET_CONSULTATION, payload: rep.data });
    });
  } catch (error) {
    return console.log("ERREUR ", error);
  }
};

export const getTicketConsultationsByUser = (userID) => (dispatch) => {
  //console.log(userID);
  try {
    const querya = `query getTicketsConsultations($userID:ID!){
      ticketConsultations (where : {users_permissions_user : {id: $userID}}) {
        id
        created_at
        published_at
        ticket{
          id,
          created_at,
          objet
        }
        updated_at
        users_permissions_user{
          id,
          username,
          email
        }
      }
    }`;
    const variablesa = { userID: userID.toString() };

    const body = {
      query: querya,
      variables: variablesa,
    };
    //console.log(body)

    return axios.post("/graphql", body).then((rep) => {
      //console.log(rep);
      const consultations = rep.data.data.ticketConsultations?.map(
        (consultation) => {
          return {
            ...consultation,
            id: Number(consultation?.id),
            ticket: {
              ...consultation?.ticket,
              id: Number(consultation?.ticket?.id),
            },
            users_permissions_user: {
              ...consultation?.users_permissions_user?.id,
              id: Number(consultation?.users_permissions_user?.id),
            },
          };
        }
      );

      dispatch({
        type: GET_ALL_TICKET_CONSULTATION,
        payload: consultations,
      });
    });
    // axios.get("/ticket-consultations?_limit=10000").then((cli) => {
    //   const consultations = cli.data;
    //   console.log(consultations);
    //   dispatch({
    //     type: GET_ALL_TICKET_CONSULTATION,
    //     payload: consultations?.filter(
    //       (c) => c?.users_permissions_user?.id === userID
    //     ),
    //   });
    // });
  } catch (error) {
    return console.log("ERREUR GET ALL BUILDING ", error);
  }
};

export const deleteTicketConsultation = (id, callback) => (dispatch) => {
  //console.log(id, `${axios.defaults.baseURL}/ticket-consultations/${id}`)
  try {
    return axios.delete(`/ticket-consultations/${id}`).then((cli) => {
      console.log(cli)
      dispatch({ type: DELETE_TICKET_CONSULTATION, payload: cli.data });
      if (callback && typeof(callback)==="function") callback();
    });
  } catch (error) {
    return console.log("ERREUR DELETE TICKET ", error);
  }
};

export const getTicketConsultationsByUserByTicketSync = (ticketID, userID) => {
  try {
    const querya = `query getTicketsConsultations($userID:ID!,$ticketID:ID!){
      ticketConsultations (
        where : {
          _and: [
            {users_permissions_user : { id: $userID } },
            {ticket : { id: $ticketID } }
          ]
        }
      ) {
        id
        created_at
        published_at
        ticket{
          id,
          created_at,
          objet
        }
        updated_at
        users_permissions_user{
          id,
          username,
          email
        }
      }
    }`;
    const variablesa = {
      userID: userID?.toString(),
      ticketID: ticketID?.toString(),
    };
    //console.log(userID);
    const body = {
      query: querya,
      variables: variablesa,
    };
    //console.log(body)
    return axios.post("/graphql", body);
  } catch (error) {
    return console.log("ERREUR GET ALL BUILDING ", error);
  }
};
