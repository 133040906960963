import React from "react";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";
import { useState, useEffect } from "react";

import './error.scss';

const Error = (props) => {
    const {
        errors
    } = props; 
    const [messages, setMessages] = useState([]);

    const getErrorDescription = (id) => {
        switch (id) {
            case 'Auth.form.error.username.taken':
                return "Le nom d'utilisateur existe déjà dans le système.";
            case 'Auth.form.error.email.taken': 
                return "L'email existe déjà dans le système.";
            case 'missing.email': 
                return "Email vide";
            case 'missing.username': 
                return "Nom d'utilisateur vide";
            default:
                return ''
        }
    }

    useEffect(() => {
        let errs = [];
        if(Array.isArray(errors)){
            errors?.map((message, index) => {
                return (
                    message.messages?.map((err) => {
                        errs.push(getErrorDescription(err.id));
                    })
                )
            })
        }else{
            errs.push(getErrorDescription(errors.message));
        }
        setMessages(errs);
    }, [errors]);  

    return (
        <div>
            <div className='errors'>
                {messages}
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Error);