import axios from "axios";

export const GET_ALL_TICKET = "GET_ALL_TICKET";
export const GET_ONE_TICKET = "GET_ONE_TICKET";
export const ADD_TICKET = "ADD_TICKET";
export const MODIF_TICKET = "MODIF_TICKET";
export const GET_ONE_TICKET_GRAPHQL = "GET_ONE_TICKET_GRAPHQL";
export const DELETE_TICKET = "DELETE_TICKET";
export const GET_ALL_TICKET_GRAPHQL = "GET_ALL_TICKET_GRAPHQL";
export const GET_ALL_TICKET_NO_FILTER = "GET_ALL_TICKET_NO_FILTER"

export const getAllTickets = (affectDefaultList=true) => (dispatch) => {
    try {
        return axios.get("/tickets?_limit=5000")
                    .then(cli => {
                        //si on veut changer le state par defaut utilisé (tickets)
                        if (affectDefaultList){ 
                            dispatch({ type: GET_ALL_TICKET, payload: cli.data });
                        }
                        dispatch({ type: GET_ALL_TICKET_NO_FILTER, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL TICKET ", error);
    }
};

export const getOneTicket = (id) => (dispatch) => {
    try{
        return axios.get(`/tickets/${id}`);
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const addTicket = (ticketState, history) => (dispatch) => {
    try {
        const jwt = localStorage.getItem("clientmmgmt");
        return new Promise((resolve, reject) => 
        {
            axios.get("/users/me", { headers: { Authorization: `Bearer ${jwt}` } }).then ((res) => {
                const user = res.data;
    
                resolve(axios.post("/tickets", {...ticketState, creator_user: user})
                        .then(cli => {
                            dispatch({ type: ADD_TICKET, payload: cli.data });
                        })
                        .catch((err) => reject({error: err})));
            })
            .catch((err) => reject({error: err}));
        });

    } catch (error) {
        return console.log("ERREUR AJOUT TICKET!", error);
    }
};

export const updateTicket = (ticketState) => (dispatch) => {
    try {
        return axios.put(`/tickets/${ticketState.id}`, ticketState);
                    // .then(cli => {
                    //     dispatch({ type: MODIF_TICKET, payload: cli.data })
                    // });
    } catch (error) {
        return console.log("ERREUR MODIFICATION TICKET ", error);
    }  
};

export const getOneTicketGraphql = (id) => (dispatch) => {
    try{
        const querya = `query TicketDetails($tid: ID!) {
                ticket(id: $tid) {
                    id
                    created_at
                    ticket_statut {
                        id
                        name
                        slug
                    }
                    ticket_priority {
                        id
                        name
                        classofprio
                    }
                    ticket_object {
                        id
                        name
                    }
                    ticket_motif {
                        id
                        name
                    }
                    ticket_type {
                        id
                        name
                    }
                    ticket_origin {
                        id
                        name
                    }
                    contract {
                        id
                        created_at
                        montant
                        slas {
                            id
                            gti
                            gtr
                        }
                    }
                    comment
                    escalation_level_group {
                        id
                    }
                    responsible_user {
                        id
                        username
                        email
                        name
                        lastname
                        escalation_level_group {
                            id
                        }
                    }
                    lastUpdatedBy {
                        id
                        username
                        email
                        name
                        lastname
                    }
                    objet
                    company {
                        id
                        name
                        contact_owner {
                            id
                            firstName
                            lastName
                        }
                    }
                    contacts {
                        id
                        firstName
                        lastName
                        email
                        fix_number
                        mobile_number
                    }
                    closedDate
                }
            }`;
        const variablesa = { "tid" : id }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET_ONE_TICKET_GRAPHQL", error);
    }
};

export const deleteTicket = (id) => (dispatch) => {
    try {
        return axios.delete(`/tickets/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_TICKET, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE TICKET ", error);
    }
};

export const getAllTicketsGraphql = () => (dispatch) => {
    try{
        const querya = `query getTickets {
                tickets {
                    id
                    created_at
                    updated_at
                    ticket_statut {
                        id
                        name
                        slug
                    }
                    ticket_priority {
                        id
                        name
                        color
                        classofprio
                    }
                    ticket_object {
                        id
                        name
                    }
                    ticket_motif {
                        id
                        name
                    }
                    ticket_type {
                        id
                        name
                    }
                    ticket_origin {
                        id
                        name
                    }
                    contract {
                        id
                        created_at
                        montant
                        slas {
                            id
                            gti
                            gtr
                        }
                    }
                    comment
                    escalation_level_group {
                        id
                    }
                    responsible_user {
                        id
                        username
                        email
                        name
                        lastname
                        escalation_level_group {
                            id
                        }
                    }
                    lastUpdatedBy {
                        id
                        username
                        email
                        name
                        lastname
                    }
                    objet
                    company {
                        id
                        name
                        contact_owner {
                            id
                            firstName
                            lastName
                        }
                    }
                    contacts {
                        id
                        firstName
                        lastName
                        email
                        fix_number
                        mobile_number
                    }
                    closedDate
                }
            }`;

        const body = {
            query : querya,
        }

        return axios.post('/graphql', body).then(cli => {
            dispatch({ type: GET_ALL_TICKET, payload: cli.data.data.tickets});
        });
    } catch (error) {
        return console.log("ERREUR GET_ALL_TICKET_GRAPHQL", error);
    }
};

export const getFilteredTicketsGraphql = (filters, companyID = null) => (dispatch) => {
    console.log('getFilteredTicketsGraphql', filters)
    try{
        let wql = `where: {`;

        filters.length > 0 && filters.forEach((f, index) => {
            if (f.field.includes(".")){
                const table = f.field.split(".")[0];
                const tableField = f.field.split(".")[1];
                wql += `${table} : {${tableField}${f.comparator}:"${f.value}"}`;
            }
            else {
                if (f.field === "comments"){
                    wql += `ticket_histories : {field : "comments", newValue${f.comparator}:"${f.value}"}`;
                }
                else {
                    wql += `${f.field}${f.comparator}:"${f.value}"`;
                }
            }
            if (index < filters.length-1) {
                wql += ',';
            }
        });

        wql += '}';

        console.log('wql', wql);

        const querya = `query getTickets {
                tickets(${wql}) {
                    id
                    created_at
                    updated_at
                    ticket_statut {
                        id
                        name
                        slug
                    }
                    ticket_priority {
                        id
                        name
                        color
                        classofprio
                    }
                    ticket_object {
                        id
                        name
                    }
                    ticket_motif {
                        id
                        name
                    }
                    ticket_type {
                        id
                        name
                    }
                    ticket_origin {
                        id
                        name
                    }
                    contract {
                        id
                        created_at
                        montant
                        slas {
                            id
                            gti
                            gtr
                        }
                    }
                    comment
                    escalation_level_group {
                        id
                    }
                    responsible_user {
                        id
                        username
                        email
                        name
                        lastname
                        escalation_level_group {
                            id
                        }
                    }
                    lastUpdatedBy {
                        id
                        username
                        email
                        name
                        lastname
                    }
                    objet
                    company {
                        id
                        name
                        contact_owner {
                            id
                            firstName
                            lastName
                        }
                    }
                    contacts {
                        id
                        firstName
                        lastName
                        email
                        fix_number
                        mobile_number
                    }
                    closedDate
                    ticket_histories{
                        id
                        field
                        newValue
                    }
                }
            }`;
        
        const body = {
            query : querya
        }

        console.log('body', body)

        return axios.post('/graphql', body).then(cli => {
            if (companyID){
                const tickets = cli.data.data.tickets;
                const filteredTickets = tickets?.filter((t) => t?.company?.id === companyID.toString());
                dispatch({ type: GET_ALL_TICKET, payload: filteredTickets });
            }
            else dispatch({ type: GET_ALL_TICKET, payload: cli.data.data.tickets});
        });
    } catch (error) {
        return console.log("ERREUR GET_ALL_TICKET_GRAPHQL", error);
    }
};

export const getCompanyTickets = (companyID) => (dispatch) => {
    //console.log('ticketAction.getCompanyTickets.param', companyID);
    try {
        return axios.get("/tickets?_limit=5000")
                    .then(cli => {
                        //console.log('ticketAction.getCompanyTickets.result', cli.data)
                        const tickets = cli.data;
                        const filteredTickets = tickets?.filter((t) => t?.company?.id === companyID);
                        dispatch({ type: GET_ALL_TICKET, payload: filteredTickets });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL TICKET ", error);
    }
};

export const getAllTicketsBySearchKey = (searchKey, companyID) => (dispatch) => {
    const querya = `query getTickets($q: String) {
        tickets(where : {_or: [{id_contains:$q}, {objet_contains:$q}, {comment_contains:$q}] }) {
            id
            objet
            company {id}
        }
    }`;

    const variablesa = { "q" : searchKey }

    const body = {
        query : querya,
        variables : variablesa
    }

    return new Promise((resolve, reject) => 
    {
        axios.post('/graphql', body).then(cli => {
            if (companyID){
                const tickets = cli.data.data.tickets;
                const filteredTickets = tickets?.filter((t) => t?.company?.id === companyID.toString());
                resolve(filteredTickets)
            }
            else {
                resolve(cli.data.data.tickets);
            } 
        })
        .catch((err) => reject({error: err}));
    });
}

export const getAllTicketsSync = () => {
    try {
        return axios.get("/tickets?_limit=5000")
    } catch (error) {
        return console.log("ERREUR GET ALL TICKET ", error);
    }
};

export const selectTicket = (ticket) => (dispatch)=> {
    dispatch({ type: GET_ONE_TICKET, payload: ticket })
}

export const getAllTicketsNotFiltered = () => (dispatch) => {
    dispatch(getAllTickets(false));
}