import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';
import MenuItem  from '@mui/material/MenuItem';
import InputLabel  from '@mui/material/InputLabel';

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { useEffect, useState } from "react";

import './input-select-field.scss';
//import { Box, Chip } from '@mui/material';

const InputSelectField = (props) => {
    const {
        param, 
        handleChange, 
        value, 
        disabled, 
        withNullDefaultValue=false, 
        multiple=false, 
        paramDataID
    } = props;
    const useDataIDAsParam = param?.useDataIDAsParam;
    const sourceFunc = param.provider.source.func;
    const is_static = param.static;
    const sourceFuncParam = param.provider.source.param;
    //const [sourceFuncParam, setSourceFuncParam] = useState(0);
    const sourceData = sourceFunc ? props[param.provider.source.data] : param.provider.source.data;
    const [sourceDataDynamic, setSourceDataDynamic] = useState([]);
    const valueField = param.provider.valueField;
    const displayFields = param.provider.displayField.split(',');
    const displayFormat = param.provider.displayFormat;
    const [selectedsMultiple,setSelectedMultiples] = useState(value)

    const getFormattedTextFromObject = (data) => {
        let res = displayFormat;
        for (let i=0;i<displayFields.length;i++){
            res = res.replace('{'+i+'}', data[displayFields[i]]);
        }
        return res;
    }

    const createObjectDefaultValue = (arrayData) => {
        return (arrayData && arrayData.length>0) 
            ? {
                ...arrayData[0], 
                id:0, 
                [param.provider.displayField]: "Aucun" 
            }
            : {id:0,name:"null"};
    }

    useEffect(() => {
        if (!useDataIDAsParam){
            if (sourceFuncParam ) {
                //setSourceFuncParam(param.provider.source.param);
                //console.log(props,";",sourceFunc,";",props[sourceFunc])
                props[sourceFunc](sourceFuncParam).then(res => {
                    const defaultValueData = {
                        id: '0',
                        [param.provider.displayField]: "Aucun" 
                    }
                    const sourceDataDynamic_ = withNullDefaultValue 
                        ? [defaultValueData].concat(res.data.data[param.provider.source.data])
                        : res.data.data[param.provider.source.data]
                    setSourceDataDynamic(sourceDataDynamic_);
                });
            }
            else {
                if(!is_static){
                    props[sourceFunc]();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceFuncParam]);  
    
    useEffect(()=>{
        if (paramDataID && useDataIDAsParam){
            props[sourceFunc](paramDataID).then(res => {
                const defaultValueData = {
                    id: '0',
                    [param.provider.displayField]: "Aucun" 
                }
                const sourceDataDynamic_ = withNullDefaultValue 
                    ? [defaultValueData].concat(res.data.data[param.provider.source.data])
                    : res.data.data[param.provider.source.data]
                setSourceDataDynamic(sourceDataDynamic_);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[paramDataID, useDataIDAsParam])

    useEffect(() => {
        console.log('new value', param.field, value)
        setSelectedMultiples(value)
    }, [value])

    const handleChangeEvt = (e) =>{
        handleChange(e, param);
        setSelectedMultiples(e.target.value);
        console.log('handleChangeEvt.new value', e.target.value)
    }

    if (param.provider.source.param || useDataIDAsParam) {
        return (
            <FormControl fullWidth variant="outlined" size="small" margin="normal">
                <InputLabel id={param.field + "-select-label"}>{param.headerName + (param.mandatory?' *':'')}</InputLabel>
                <Select labelId={param.field + "-select-label"} fullWidth
                    multiple={multiple} 
                    inputProps={{ readOnly: disabled }}
                    name={param.field}
                    onChange={handleChangeEvt}
                    value = {value}
                    label={param.headerName + (param.mandatory?' *':'')}

                    defaultValue={value}
                    required={param.mandatory}
                >
                    {
                        sourceDataDynamic?.length>0 && sourceDataDynamic?.map((data, index) => {
                            const displayText = displayFields.length === 1 ? data[displayFields[0]]:getFormattedTextFromObject(data);
                            return <MenuItem value={data[valueField]} key={data[valueField]}>{displayText}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        )        
    }
    else {
        const sourceData_ = withNullDefaultValue 
        ? [createObjectDefaultValue(sourceData)].concat(sourceData) 
        : sourceData
        return (
            <FormControl fullWidth variant="outlined" size="small" margin="normal">
                <InputLabel id={param.field + "-select-label"}>{param.headerName + (param.mandatory?' *':'')}</InputLabel>
                {param.native ?
                    <select 
                        id={param.field} 
                        name={param.field}
                        value={selectedsMultiple}
                        onChange={handleChangeEvt}>
                            <option value=""></option>
                    {
                        sourceData_?.map((data, index) => {
                            const displayText = displayFields.length === 1 ? data[displayFields[0]]:getFormattedTextFromObject(data);
                            return <option value={data[valueField]} key={data[valueField]}>{displayText}</option>
                        })
                    }
                    </select>
                    :
                    <Select labelId={param.field + "-select-label"} fullWidth 
                        multiple={multiple}
                        inputProps={{ readOnly: disabled }}
                        name={param.field}
                        onChange={handleChangeEvt}
                        label={param.headerName + (param.mandatory?' *':'')}
                        value = {selectedsMultiple}
                        defaultValue={value}
                        required={param.mandatory}
                    >   
                        {
                            sourceData_?.map((data, index) => {
                                const displayText = displayFields.length === 1 ? data[displayFields[0]]:getFormattedTextFromObject(data);
                                return <MenuItem value={data[valueField]} key={data[valueField]}>{displayText}</MenuItem>
                            })
                        }
                    </Select>

                }
            </FormControl>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InputSelectField);