import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import './confirm-dialog.scss';

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      className="DeletePopup"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent className="deleteContent">
        <span className="icon-delete"></span>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          className ="cancelBtn"
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;