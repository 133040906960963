import axios from "axios";

export const GET_ALL_ROOM = "GET_ALL_ROOM";
export const GET_ONE_ROOM = "GET_ONE_ROOM";
export const GET_ALL_ROOM_GRAPHQL = "GET_ALL_ROOM_GRAPHQL";
export const GET_ALL_ROOM_BY_COMPANY_GRAPHQL = "GET_ALL_ROOM_BY_COMPANY_GRAPHQL";
export const GET_ALL_ROOM_BY_SITE_GRAPHQL = "GET_ALL_ROOM_BY_SITE_GRAPHQL";
export const GET_ONE_ROOM_GRAPHQL = "GET_ONE_ROOM_GRAPHQL";

export const getAllRooms = () => (dispatch) => {
    try {
        return axios.get("/rooms")
                    .then(cli => {
                        dispatch({ type: GET_ALL_ROOM, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR GET ALL ROOM ", error);
    }
};

export const getOneRoom = (id) => (dispatch) => {
    try{
        return axios.get(`/rooms/${id}`)
                    .then(rep => {
                        dispatch({ type: GET_ONE_ROOM, payload: rep.data });
                    });
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const getAllRoomsByBuilding = (buildingId) => (dispatch) => {
    try {
        const querya = `query getRooms($bid:ID!){
            rooms (where : {building : {id : $bid}}) {
                id
                name
                description
                etage
                building {
                    id
                    name
                    site {
                        id
                        name
                    }
                }
                reference
                wing
            }
        }`;
        const variablesa = { "bid" : buildingId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL ROOM ", error);
    }
};

export const getAllRoomsByCompany = (companyId) => (dispatch) => {
    try {
        const querya = `query getRooms($cid:ID!){
            rooms (where : {building : { site: {company : { id : $cid }}}}) {
              id
              name
              description
              etage
              building {
                  id
                  name
                  site {
                      id
                      name
                  }
              }
              reference
              wing
            }
        }`;
        const variablesa = { "cid" : companyId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL ROOM ", error);
    }
};

export const getAllRoomsBySite = (siteId) => (dispatch) => {
    try {
        const querya = `query getRooms($cid:ID!){
            rooms (where : {building : { site: { id : $cid }}}) {
                id
                name
                description
                etage
                building {
                    id
                    name
                    site {
                        id
                        name
                    }
                }
                reference
                wing
            }
        }`;
        const variablesa = { "cid" : siteId }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body);
    } catch (error) {
        return console.log("ERREUR GET ALL ROOM ", error);
    }
};

export const getOneRoomGraphql = (id) => (dispatch) => {
    try{
        const querya = `query RoomDetails($rid: ID!) {
                room(id: $rid) {
                    id
                    name
                    etage
                    building {
                        id
                        name
                        site {
                            id
                            name
                            company{
                                id
                                name
                            }
                        }
                    }
                    reference
                    wing
                }
            }`;
        const variablesa = { "rid" : id }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body)
        .then((result) => {
            //console.log('graphql.result', result)
            dispatch({ type: GET_ONE_ROOM_GRAPHQL, payload: result.data.data.room });
        });
    } catch (error) {
        return console.log("ERREUR GET_ONE_ROOM_GRAPHQL", error);
    }
};

export const addRoomSync = (roomState) => (dispatch) => {
    try {
        return axios.post("/rooms", roomState);
    } catch (error) {
        return console.log("ERREUR AJOUT ROOM ", error);
    }
};

export const getAllRoomsSync = () => (dispatch) => {
    try {
        return axios.get("/rooms?_limit=-1");
    } catch (error) {
        return console.log("ERREUR GET ALL ROOM ", error);
    }
};

export const importRoomData = (roomData) => {
    try {
        
        if (window.location.origin.indexOf("localhost") !== -1){
            return axios.post("http://localhost/gp/import-room.php", roomData,{
                headers: { 'Content-Type': 'application/json;charset=UTF-8' }
            });
        }
        else {
            return axios.post(window.location.origin + "/import-room.php", roomData,{
                headers: { 'Content-Type': 'application/json' }
            });
        }
    } catch (error) {
        return console.log("importRoom", error);
    }
};