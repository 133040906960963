import { 
  ADD_TICKET_MOTIF, 
  DELETE_TICKET_MOTIF, 
  GET_ALL_TICKET_MOTIF, 
  GET_ONE_TICKET_MOTIF, 
  MODIF_TICKET_MOTIF 
} from "../actions/ticketMotifAction";


const initialState = {
  ticketMotifs: [],
  ticketMotif: {}
};

export function ticketMotifReducers(state = initialState, action) {
  switch (action.type) {
      case GET_ALL_TICKET_MOTIF:
          return { ...state, ticketMotifs: action.payload };

      case ADD_TICKET_MOTIF:
          return { ...state, ticketMotif: action.payload };

      case MODIF_TICKET_MOTIF:
          return { ...state };

      case DELETE_TICKET_MOTIF:
          return { ...state };

      case GET_ONE_TICKET_MOTIF:
          return { ticketMotif: action.payload };

      default:
          return { ...state };
  }
}