import { 
    DataGrid, 
    GridActionsCellItem, 
    GridToolbarContainer, 
    GridToolbarFilterButton, 
    GridToolbarExport,
    frFR 
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import FormDialog from '../dialog/form-dialog';
import ConfirmDialog from '../dialog/confirm-dialog';

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import './generic-datagrid.scss';

const GenericDatagrid = (props) => {
    const {
        data,
        fields,
        type,
        handler, 
        forDetails, 
        title, 
        disableClickRow, 
        addHandler, 
        editHandler, 
        openImportDialogHandler,
        onSelectRow, 
        allowEditing = true, 
        allowDeleting = true, 
        withToolbar = true,
        enablePagination = true,
        additionnalFields,
        defaultDefinedData, 
        orderedFields=false,
        //initialState={},
        columnVisibilityModel={},
        afterAdd
    } = props; 
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [currentRowToDelete, setCurrentRowToDelete] = useState(null);

    //state local
    const [rows, setRows] = useState(data);
    const [addMode, setAddMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    /*const [copyMode, setCopyMode] = useState(false);*/
    const [gridPageSize, setGridPageSize] = useState(10);
    const [currentRow, setCurrentRow] = useState({});
    const navigate = useNavigate();
    const [elementDisplayText, setElementDisplayText] = useState("");

    //useEffect
    useEffect(() => {
        setRows(data);
    }, [data]);    

    const gridFields = forDetails 
        ? fields?.map(e => 
            (e.listdetails 
                ? { ...e, hide: false } 
                : { ...e, hide: true })
        ) 
        : fields?.map(e => 
            (e.list
                ? { ...e, hide: false } 
                : { ...e, hide: true }
            )
        );
    const addFields = fields?.filter((f) => f.add);
    const addAditionnalFields = additionnalFields?.fields?.filter((f) => f.add);
    const editFields = fields?.filter((f) => f.edit);
    const exportFields = fields?.filter((f) => f.export).map(e => e.field);
    const gridActions = {
        field: 'actions',
        type: 'actions',
        headerName: '',
        flex: (allowDeleting & allowEditing) ? 0.15 : 0.09,
        getActions: (params) => {
            const handleEditItem = () =>{
                editItem(params)
            }
            const handleDelete = () => {
                setCurrentRowToDelete(params);
                setElementDisplayText(params.row.name ? params.row.name : `#${params.row.id}`);
                setConfirmOpen(true);
            }

            return [
                allowEditing ?
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        className="edit"
                        label="Editer"
                        onClick={handleEditItem}
                        title="Editer"
                    />: 
                    <></>, 
                allowDeleting ?           
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        className="delete"
                        label="Supprimer"
                        title="Supprimer"
                        onClick={handleDelete}
                    />
                    : <></>
                    /*
                    <GridActionsCellItem
                        icon={<FileCopyIcon />}
                        label="Dupliquer"
                        onClick={() => {copyItem(params)}}
                        
                    />,*/
        ]},
        filterable: false, add: false, edit: false, list: true
    }
   
    //fonction locale
    const viewItem = (id) =>{
        if (type) navigate('/' + type + '/' + id);
    }
    const editItem = (params) => {
        if(editHandler){
            editHandler(params);
        }else{
            setCurrentRow(params.row);
            setEditMode(true);
        }     
    }    
    const deleteItem = () => {
        if (currentRowToDelete){
            props[handler.delete](currentRowToDelete.id);
            setRows(rows.filter((r) => r.id !== currentRowToDelete.id));
            setCurrentRowToDelete(null);
        }
    }

    //handlers
    const onClickAddHandler = () => {
        setCurrentRow(null);
        setAddMode(true);
    }

    const rowClickHandler = (param) => {
        if (!disableClickRow) viewItem(param.id);
        if (onSelectRow && typeof(onSelectRow)==='function') {
            setCurrentRow(param);
            onSelectRow(param)
        };
    }

    const handleClose = () => {
        setAddMode(false);
        setEditMode(false);
    };    

    const onOpenImportHandler = () => {
        openImportDialogHandler();
    }

    const handleDataGridPageSizeChange = (newPageSize) => {
        setGridPageSize(newPageSize)
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer className="groupBtn btnUp">
                <GridToolbarFilterButton text="Filtrer"></GridToolbarFilterButton>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: 'Liste '+title,
                    delimiter: ';',
                    utf8WithBom: true,
                    includeHeaders: true,
                    fields: exportFields
                }}/>
                { openImportDialogHandler && 
                    <Button size="small" onClick={onOpenImportHandler} startIcon={<ImportExportIcon />} className="add">Importer</Button>
                }
                <Button size="small" onClick={addHandler ? addHandler : onClickAddHandler} startIcon={<AddIcon />} className="add">Ajouter</Button>
            </GridToolbarContainer>
        )
    }

    return (
        <div className="datagrid" style={{ width: '100%' }}>
            <h2>{title}</h2>
            <DataGrid
                rows={rows || []}
                columns={!allowEditing && !allowDeleting ? gridFields : [...gridFields, gridActions]}
                columnVisibilityModel={columnVisibilityModel}
                pagination 
                pageSize={enablePagination? gridPageSize :undefined}
                rowsPerPageOptions={ enablePagination ? [10, 20, 50] : undefined}
                hideFooterPagination={!enablePagination}
                hideFooter={!enablePagination}
                onPageSizeChange={handleDataGridPageSizeChange}
                autoHeight
                componentsProps={{
                    pagination: {
                        component: "div",
                        labelRowsPerPage: "Lignes par page",
                        labelDisplayedRows: (page) => (`Page ${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count}`),
                    }
                }}
                // localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                disableSelectionOnClick 
                onRowClick={(!disableClickRow && rowClickHandler)? rowClickHandler : null}
                components={{ Toolbar : withToolbar? CustomToolbar : undefined}}
                getRowClassName={(params) => currentRow?.id === params?.row?.id ? "gridSelectedRow" : ""}
            >
            </DataGrid>
            {(!addHandler && !editHandler) && allowEditing && 
                <FormDialog 
                    open={editMode || addMode} 
                    onClose={handleClose} 
                    title={addMode ? 'Ajouter ' + title: 'Editer'} 
                    fields={addMode ? addFields: editFields }
                    additionnalFields = {addMode ? addAditionnalFields: null}
                    additionnalHandler = {additionnalFields?.handler}
                    additionnalFieldsContentType = {additionnalFields?.contentType}
                    additionnalContentTypeFieldName = {additionnalFields?.contentTypeFieldName} 
                    contentTypeFieldToUpdate = {additionnalFields?.contentTypeFieldToUpdate}
                    handler={handler}
                    data={defaultDefinedData? {...currentRow,...defaultDefinedData} : currentRow}
                    withOrder={orderedFields}
                    afterAdd={afterAdd && addMode ? afterAdd: null}
                />
            }
            {allowDeleting && 
                <ConfirmDialog
                    title={"Suppression de '" + elementDisplayText + "'"}
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={deleteItem}
                >
                    Etes-vous sûr de vouloir supprimer cet élément ?
                </ConfirmDialog>  
            }              
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericDatagrid);