/**
 * UserSettingsPassword
 * Création : 29/03/2023
 * Page permettant à un utilisateur de paramétrer son compte (changement mot de passe, paramétrage notification, fuseau horaire)
 * Historique
 *      DD/MM/YYYY   log
 */
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import mapStateToProps from "../../../redux/mapStateToProps";
import mapDispatchToProps from "../../../redux/mapDispatchToProps";

import zxcvbn from 'zxcvbn';

import {ReactComponent as VisibilityPass} from "../../../assets/images/visibilityPass.svg";
import {ReactComponent as VisibilityPassHidden} from "../../../assets/images/visibilityPassHidden.svg";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";

import Alert from '@mui/material/Alert';
import './password.scss';

const UserSettingsPassword = (props) => {
    const { customResetPassword, user } = props;
    const [ formData, setFormData] = useState({
        password : '',
        newPassword : '',
        confirmPassword: ''
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isNewAndConfirmEqual, setIsNewAndConfirmEqual] = useState(true);
    const [isNotValid, setIsNotValid] = useState(true);
    const [passwordStrength, setPasswordStrength] = useState(-1);

    const strengths = [
        {class : 'low', label : 'Très faible'},
        {class : 'low', label : 'Faible'},
        {class : 'medium', label : 'Moyen'},
        {class : 'high', label : 'Fort'},
        {class : 'high', label : 'Très fort'}
    ];

    useEffect(() => {
        checkValidity();
    }, [formData])

    const handleValidate = () => {
        /* send mail test
        sendMail({
            to : 'a.arilanto@gmail.com',
            subject: 'test',
            html: '<div>test çà marche ?</div>',
        }).then((r) => console.log('sendMail.res', r));
        */

        customResetPassword({
            ...formData,
            identifier : user.username
        }).then((res) => {
            if (res.data.jwt){
                localStorage.setItem("clientmmgmt", res.data.jwt);

                setInfoMessage("Votre mot de passe a bien été modifié.");
                setTimeout(() => {
                    setInfoMessage("")
                }, 3000)

                setFormData({
                    password : '',
                    newPassword : '',
                    confirmPassword: ''
                });
            }

            //console.log('customResetPassword.res', res)
        })
        .catch((error) => {
            //console.log('customResetPassword.error', error);
            if (error.response?.data?.data?.id === "Auth.form.error.invalid"){
                setErrorMessage("Le mot de passe saisi est incorrect.");
            }
            else {
                setErrorMessage(error.response?.data?.data?.message);
            }

            setTimeout(() => {
                setErrorMessage("")
            }, 3000);

            setFormData({
                password : '',
                newPassword : '',
                confirmPassword: ''
            });
        })

    }

    const checkValidity = () => {
        let valid = true;

        valid = (formData.password !== "" && formData.newPassword !== "" && formData.confirmPassword !== "" && (formData.newPassword === formData.confirmPassword)); 

        setIsNewAndConfirmEqual(formData.confirmPassword === "" || formData.newPassword === formData.confirmPassword);

        setIsNotValid(!valid);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "newPassword") {
            const strength = zxcvbn(value).score;
            setPasswordStrength(strength);
        }

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSwitchShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleSwitchShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    }

    const handleSwitchShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <div className="contentRight">
            <h1 className="title">Paramètres de sécurité</h1>
            <Box sx={{ flexGrow: 1 }}>
                <form autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <label>Nom d'utilisateur :</label> <br />
                        <input type="text" autoComplete="off" name="user" value={user.username} disabled />
                    </Grid>
                    <Grid item xs={6} position={"relative"}>
                        <label>Mot de passe actuel :</label> <br />
                        <input type={showPassword ? "text": "password"} autoComplete="off" name="password" placeholder="Ancien mot de passe" value={formData.password} onChange={handleChange} />
                        <div className="visibilityPass"><a href="javascript:;" onClick={handleSwitchShowPassword}>{showPassword ? <VisibilityPass/>: <VisibilityPassHidden/>}</a></div>
                    </Grid>
                    <Grid item xs={6} position={"relative"}>
                        <label>Nouveau mot de passe :</label> <br />
                        <input type={showNewPassword ? "text": "password"} autoComplete="off" name="newPassword" placeholder="Nouveau mot de passe" value={formData.newPassword} onChange={handleChange} />
                        <div className="visibilityPass"><a href="javascript:;" onClick={handleSwitchShowNewPassword}>{showNewPassword ? <VisibilityPass/>: <VisibilityPassHidden/>}</a></div>
                        {passwordStrength >=0 && formData.newPassword !== "" && <div className="security">niveau de sécurité mot de passe : <span className={strengths[passwordStrength].class}>{strengths[passwordStrength].label}</span></div>}
                    </Grid>
                    <Grid item xs={6} position={"relative"}>
                        <label>Confirmer nouveau mot de passe :</label> <br />
                        <input type={showConfirmPassword ? "text": "password"} autoComplete="off" name="confirmPassword" placeholder="Confirmation mot de passe" value={formData.confirmPassword} onChange={handleChange} />
                        <div className="visibilityPass"><a href="javascript:;" onClick={handleSwitchShowConfirmPassword}>{showConfirmPassword ? <VisibilityPass/>: <VisibilityPassHidden/>}</a></div>
                        {!isNewAndConfirmEqual && <div className="security"><span className="low">Incorrect</span></div>}
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={6} className="btnValid">
                        <div>
                        <Button className="btn primary" disabled={isNotValid} onClick={handleValidate}>Sauvegarder</Button>
                        </div>
                    </Grid>
                    {
                        errorMessage && <Alert severity="warning">{errorMessage}</Alert>
                    }
                    {
                        infoMessage && <Alert severity="info">{infoMessage}</Alert>
                    }
                </Grid>
                </form>
            </Box>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsPassword);