/**
 * SolutionPage
 * Création : 15/03/2023
 * Page contenant la liste des bases de connaissances
 * Historique
 *      DD/MM/YYYY   log
 */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import './category-index.scss';

const kbParams = {
    name: 'knowledgeBase',
    access: ['admin'],
    path: '/knowledge-base',
    title: 'Solution',
    menu: true,
    description: '',
    isParent: false,
    fields: [
        {
            field: "question",
            headerName: "Sujet",
            inputType: 'text',
            flex: 0.2,
            filterable: true, add: true, edit: true, list: true, export: true, listdetails: false
        },
        {
            field: "answer",
            headerName: "Réponse",
            inputType: 'textarea',
            flex: 0.2,
            filterable: true, add: true, edit: true, list: false, export: true, listdetails: false
        },
    ],
    handler: {
        list: { func: 'getAllKnowledgeBases', data: 'knowledgeBases' },
        add: 'addKnowledgeBase',
        update: 'updateKnowledgeBase',
        delete: 'deleteKnowledgeBase'
    }
}

const SolutionPage = (props) => {
    const { getAllKnowledgeCategories, knowledgeCategories, getConfigData, dataConfig } = props;
    const navigate = useNavigate();
    const [newSolutionsDays, setNewSolutionsDays] = useState(0);

    useEffect(() => {
        getAllKnowledgeCategories();
        getConfigData();
    }, []);

    useEffect(() => {
        setNewSolutionsDays(dataConfig.NEW_KNOWLEDGE_DAYS);
    }, [dataConfig])

    const gotoCategory = (catId) => {
        console.log('catId', catId)
        navigate(`/solution/${catId}`)
    }

    return (
        <div className="solutions">
            <h1 className="title">Solutions</h1>
            <div className="category_solution">
                {knowledgeCategories && knowledgeCategories.map((kc) => {
                    const today = new Date();
                    today.setDate(today.getDate() - newSolutionsDays);
                    const currentDaysMinus5Days = today;
                    const newKnowledges = kc.knowledge_bases.filter(kb => {
                        return new Date(kb.created_at) > currentDaysMinus5Days;
                    });

                    return (
                        <div className={`one-category ${kc.class}`} onClick={() => gotoCategory(kc.id)}>
                            <div className="category-split-info">
                                <div className="title-category">{kc.name}</div>
                                <div className="knowledge-count">
                                    <b>{kc.knowledge_bases.length}</b> solution{kc.knowledge_bases.length > 1 ? "s" : ""}
                                </div>
                                <div className="new-items-category"><b>{newKnowledges.length}</b>nouvelle{newKnowledges.length > 1 ? "s" : ""} solution{newKnowledges.length > 1 ? "s" : ""}</div>
                            </div>
                            <div className={`category-split-icon ${kc.class}`}>
                                <span className={`icon-${kc.class}`}></span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(SolutionPage);