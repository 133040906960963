/* eslint-disable react-hooks/exhaustive-deps */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

//import { withStyles } from '@mui/styles';

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import InputTextField from '../input/input-text-field';
import InputSelectField from '../input/input-select-field';
import InputAutocompleteField from '../input/input-autocomplete-field';
import InputSwitchField from '../input/input-switch-field';
import InputFileField from '../input/input-file-field';
import DocumentList from '../document/document-list';
import InputTextMultilineField from '../input/input-text-multiline-field'

import './form-dialog.scss';
import { hasRequiredField, isFormValid } from '../../core/utils';
import Error from '../error-display/error';

const FormDialog = (props) => {
    const {
        open, 
        onClose, 
        fields, 
        title, 
        handler, 
        setNeedRefresh, 
        data, 
        isPage=false, 
        customHandleSubmit,
        customHandleChangeField,
        additionnalFields,
        additionnalHandler,
        additionnalFieldsContentType,
        additionnalContentTypeFieldName,
        contentTypeFieldToUpdate,
        withOrder=false,
        validHandler,
        afterAdd,
        onStartUploadDocument,
        onEndUploadDoculent
    } = props;
    const navigate = useNavigate();
    const formRef = useRef({});

    const [formIsValid, setFormIsValid] = useState(!hasRequiredField(fields));
    const [errors, setErrors] = useState([]);
    const [acFields, setAcFields] = useState({});
    const [acFieldsLoaded, setAcFieldsLoaded] = useState(false);
    const [acData, setAcData] = useState([]);
    const [forceRender, setForceRender] = useState("");

    useEffect(()=>{
        if (typeof(validHandler) === 'function') validHandler(isFormValid(fields, formRef.current))

        let temp = {}
        fields.forEach(f => {
            if (f.autocomplete) temp = {...temp, [f.autocomplete.field]: []};
        })
        setAcFields(temp);
    },[])

    useEffect(() => {
        if (open){
            setErrors([]);
            if (data){
                if (data.id) formRef.current = {id: data.id}
                else formRef.current = data
                setFormIsValid(isFormValid(fields, data))
            }
        }

        if (open) setNeedRefresh(false)

        if (isPage) {
            formRef.current = data;
            setNeedRefresh(false);
        }
        //setFormIsValid(isFormValid(fields, formRef.current))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        if (acFields != {} && !acFieldsLoaded){
            if (handler && handler.autocomplete) {
                props[handler.autocomplete.source.func]()
            }
        }
    }, [acFields])

    useEffect(() => {
        setAcFieldsLoaded(true)
        const data = props[handler?.autocomplete?.source.data];
        setAcData(data);

    }, [props[handler?.autocomplete?.source.data]])

    useEffect(() => {
        setFormIsValid(isFormValid(fields, formRef.current))
    }, [forceRender])

    const close = () => {
        onClose();
        setFormIsValid(!hasRequiredField(fields));
    }
    const handleStartUploadDocumentFile = () => {
        setFormIsValid(false);
        if (
          onStartUploadDocument &&
          typeof onStartUploadDocument === "function"
        )
          onStartUploadDocument();
    }
    const handleEndUploadDocumentFile = () => {
        setFormIsValid(isFormValid(fields, formRef.current));
        if (onEndUploadDoculent && typeof onEndUploadDoculent === "function")
          onEndUploadDoculent();
    }

    const renderSwitchField = (f) => {
        let value = (data && data[f.field]) ? data[f.field]: null;
        return <InputSwitchField key={f.field} param={f} value={value} handleChange={handleSwitchChange} />                 
    }
    const renderTextField = (f) => {
        if (data) {
            const tgt = {target: {name: f.field, value: data[f.field]}};
            initChangeDataRef(tgt);
            return (
                <div className="inputContent" key={f.field}>
                    <InputTextField 
                        param={f} 
                        value={data[f.field]} 
                        handleChange={(e) => handleChange(e, f)} 
                        formRef={formRef} 
                        disabled={f.disabled}
                    />
                </div>
                )
        }
        else {
            if (f.default){
                const tgt = {target: {name: f.field, value: f.default}};
                initChangeDataRef(tgt)                    
            }
            return (
                <div className={`inputContent ${f.className?f.className:""}`} key={f.field}>
                    <InputTextField param={f} value={f.default?f.default:''} handleChange={(e) => handleChange(e, f)} />
                    {f.autocomplete && acFields[f.autocomplete.field] && acFields[f.autocomplete.field].length > 0 &&
                        <div className='ac-container'>
                        {
                            acFields[f.autocomplete.field]?.map((d, index) => {
                                return <div key={index} onClick={(e) => handleSelectAutocomplete(index, f, d[f.autocomplete.field])}>{d[f.autocomplete.field]}</div>
                            })
                        }
                        </div>
                    }
                </div>
            )
        }
    }
    const renderSelectField = (f) => {
        let value = (data && data[f.field]) ? data[f.field]: (f.value ? f.value: null);

        const multipleValue = (f.multipleSelect && value && value?.length>0) ? value.map((v)=>v.id) : []
        if (value){
            const tgt = {target: {name: f.field, value: value.id}};
            initChangeDataRef(tgt)
        }
        else {
            if (f.default){
                initChangeDataRef({
                    target:{
                        name:f.field,
                        value: "0"
                    }
                })
            }
        }
        return (
            <div className={`inputContent ${f.className?f.className:""}`} key={f.field}>
                <InputSelectField 
                    paramDataID={data?.id}
                    multiple={f.multipleSelect} 
                    disabled={f.disabled}
                    param={f} 
                    value={value? (f.multipleSelect ? multipleValue : value.id) :''} 
                    handleChange={handleChange}
                />
            </div>
        )
    }
    const renderAutocompleteField = (f) => {
        let value = (data && data[f.field]) ? data[f.field]: null;
        if (value){
            const tgt = {target: {name: f.field, value: value.id}};
            initChangeDataRef(tgt)
        }
        return <InputAutocompleteField 
                    paramDataID={data?.id}
                    key={f.field} 
                    param={f} 
                    value={value
                        ? value
                        :{id: 0, [f.provider.displayField ? f.provider.displayField : 'name']:''}} 
                    handleChange={handleChange} 
                />
    }
    const renderFileField = (f) => {
        let value = (data && data[f.field]) ? data[f.field]: null;
        return (
            <InputFileField key={f.field} param={f} value={value} handleChange={handleChange} />
        )
    }
    const renderDocumentListField = (f) => {
        let param = f;
        param.provider.source.param = data ? data['id'] : 0;//hack 
        return (
          <div className="inputContent fileForTicket" key={param.field}>
            <DocumentList
              add={true}
              param={param}
              handleChange={handleChange}
              onStartUploading={handleStartUploadDocumentFile}
              onEndUploading={handleEndUploadDocumentFile}
            />
          </div>
        );
    }
    const renderTextMultilineField = (f) => {
        if (data)
                return (
                    <div className="inputContent textareaTicket" key={f.field}>
                        <InputTextMultilineField rows={f?.rows} param={f} value={data[f.field]} handleChange={handleChange}/>
                    </div>
                    )
            else
            return (
                <div className="inputContent textareaTicket" key={f.field}>
                    <InputTextMultilineField rows={f?.rows} param={f} value={''} handleChange={handleChange} />
                </div>
            )
    }
    const renderSwitchFields = (fields) => {
        const booleanFields = (fields && fields?.filter( f => f.inputType === 'boolean').length > 0 )
            ? fields?.filter(f => f.inputType === 'boolean' )
            : null;
        return (
            booleanFields
                ?<Stack direction="row" spacing={4} className="switchChoice">
                    {booleanFields?.map(f => {
                       return renderSwitchField(f)
                    })}
                </Stack>:""
            
        )
    }
    const renderTextFields = (fields) => {
        return fields?fields.filter(f => (f.inputType === 'text' || f.inputType === 'number' || f.inputType === 'date' || f.inputType === 'email' || f.inputType === 'password')).map(f => {
            return renderTextField(f);
        }):''
    }
    const renderSelectFields = (fields) => {
        return fields?fields.filter(f => f.inputType === 'list').map(f => {
            return renderSelectField(f);
        }):''
    }    
    const renderAutocompleteFields = (fields) => {
        return fields?fields.filter(f => f.inputType === 'autocomplete').map(f => {
            return renderAutocompleteField(f);
        }):''
    }    
    const renderFileFields = (fields) => {
        return fields?fields.filter(f => (f.inputType === 'file')).map(f => {
            return renderFileField(f);
        }):''
    }     
    const renderDocumentListFields = (fields) => {
        return fields?fields.filter(f => f.inputType === 'document-list').map(f => {
            return renderDocumentListField(f);
        }):''
    }
    const renderTextMultilineFields = (fields) => {
        return fields?fields.filter(f => (f.inputType === 'textarea')).map(f => {
            return renderTextMultilineField(f);
        }):''
    }
    const renderWithOrder = (fields) =>{
        return fields? fields.map(f => {
            if (!f.orderedToTheEnd){
                return renderField(f); 
            } else return ''
        }):''
    }
    const renderField = (f) => {
        if (f.inputType === 'text' 
            || f.inputType === 'number' 
            || f.inputType === 'date' 
            || f.inputType === 'email' 
            || f.inputType === 'password')
            return renderTextField(f);
        if (f.inputType === 'list') return renderSelectField(f);
        if (f.inputType === 'autocomplete') return renderAutocompleteField(f);
        if (f.inputType === 'file') return renderFileField(f);
        if (f.inputType === 'document-list') return renderDocumentListField(f);
        if (f.inputType === 'textarea') return renderTextMultilineField(f);
        else return '';
    }
    const rendertheFieldsAtTheEnd = (fields) =>{
        return fields? fields.map(f => { 
            if (f.orderedToTheEnd){
                return renderField(f);
            }
            else return '';
        })
        :''
    }
    const renderFormContents = () =>
    (
        <div className="formPopup">
            {data && (
                <input name="id" type="hidden" value={data.id} />
            )}
            { withOrder
                ? <>
                    {additionnalFields && additionnalFields?.length>0 ? renderWithOrder(additionnalFields):''}
                    {renderWithOrder(fields)}
                    {renderSwitchFields(fields)}
                    {rendertheFieldsAtTheEnd(fields)}
                </>
                : (
                    <>
                        {additionnalFields && additionnalFields?.length>0
                        ?(
                            <>
                                {renderTextFields(additionnalFields)}
                                {renderSelectFields(additionnalFields)}
                                {renderSwitchFields(additionnalFields)}
                                {renderAutocompleteFields(additionnalFields)}
                                {renderFileFields(additionnalFields)}
                                {renderDocumentListFields(additionnalFields)}
                                {renderTextMultilineFields(additionnalFields)}
                            </>
                        ) 
                        : ''
                        }
                        {renderTextFields(fields)}
                        {renderSelectFields(fields)}
                        {renderSwitchFields(fields)}
                        {renderAutocompleteFields(fields)}
                        {renderFileFields(fields)}
                        {renderDocumentListFields(fields)}
                        {renderTextMultilineFields(fields)}
                    </>
                )
            }
        </div>
    )

    const initChangeDataRef = (e) =>{
        const { name, value } = e.target;
        formRef.current = { 
            ...formRef.current, 
            [name]: ( typeof(value) === 'number' ) ? value.toString() : value  
        };

        if (customHandleChangeField && typeof(customHandleChangeField) === "function") {
            customHandleChangeField(formRef.current);
        }
        if (typeof(validHandler) === 'function') validHandler(isFormValid(fields, formRef.current))
    }

    const handleChange = (e, f) => {
        initChangeDataRef(e);
        setFormIsValid(isFormValid(fields, formRef.current))

        if (f && f.autocomplete && !data){
            
            if (e.target.value !== ""){
                const temp = acFields[f.autocomplete.field];
                const fil = acData.filter(acf => {
                    if (acf[f.autocomplete.field]){
                        return acf[f.autocomplete.field].toUpperCase().startsWith(e.target.value.toUpperCase())
                    } else return false
                });
                acFields[f.autocomplete.field] = fil;
                
                setAcFields({
                    ...acFields,
                    ...temp
                })
                
            }
            else {
                acFields[f.autocomplete.field] = [];
                
                setAcFields({
                    ...acFields
                })
                
            }
        }

        if (f && f.onChange) {
            const action = f.onChange;

            if (action){
                let r = (Math.random() + 1).toString(36).substring(7);
                if (action?.condition.value == e.target.value){
                    eval(action.codeToEvalTrue);
                    const targetField = fields.filter((f) => f.field == action.reactCodeTrue.targetField)[0];
                    targetField['value'] = {id : action.reactCodeTrue.targetFieldValue};

                    setForceRender(r)
                }
                else {
                    eval(action.codeToEvalFalse);

                    setForceRender(r)

                }
            }
        }

    };   

    //le controle switch renvoie toujouts 'on' comme valeur de value
    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        formRef.current = { ...formRef.current, [name]: checked };
    };       

    const validate = (e) => {
        if (additionnalFields && additionnalHandler && additionnalFieldsContentType){
            if (!(data && data.id)){
                const data = {...formRef.current}
                const additionnalKeys = Object.keys(data).filter((key) => key.includes(additionnalFieldsContentType));
                let additionnalObject = {};
                additionnalKeys.forEach((key)=>{
                    const finalKeyName = key.replace(`${additionnalFieldsContentType}_`,'')
                    additionnalObject = {
                        ...additionnalObject,
                        [finalKeyName]:data[key],
                    }
                })
                if (customHandleSubmit && typeof(customHandleSubmit)==="function"){
                    customHandleSubmit({...formRef.current,...additionnalObject}, navigate);
                } 
                else {  
                    props[additionnalHandler.add](additionnalObject)
                    .then((res)=>{
                        const createdAdditionnalData = res?.data;
                        props[handler.add]({
                            ...formRef.current, 
                            [additionnalContentTypeFieldName]:createdAdditionnalData
                        }, 
                        navigate, 
                        (data) => {
                            if (contentTypeFieldToUpdate){
                                const updatedObject = {
                                    ...createdAdditionnalData,
                                    [contentTypeFieldToUpdate]: data?.id,
                                }
                                props[additionnalHandler.update](updatedObject);
                            }
                            setNeedRefresh(true);
                        });
                        
                    })
                    if (onClose) onClose();
                }
            }
        }
        else{
            if (customHandleSubmit && typeof(customHandleSubmit)==="function"){
                customHandleSubmit({...formRef.current}, navigate);
            }
            if (data && data.id) {
                props[handler.update]({...formRef.current}, navigate)
                    .then((result) => {
                        setNeedRefresh(true);
                        if (onClose) onClose();
                    });
            }
            else { 
                props[handler.add]({...formRef.current}, navigate)
                    .then((result) => {
                        //console.log(result);
                        setNeedRefresh(true);
                        if (onClose) onClose();
                        //console.log('after add called.result', formRef.current)
                        if (afterAdd) afterAdd(formRef.current);
                    }).catch((error) => {
                        setErrors(error.response.data.data ? error.response.data.data : error.response.data);
                    })
            }
        }
    }

    const handleSelectAutocomplete = (index, field, value) => {
        const temp = acData.filter(acd => acd[field.autocomplete.field] == value);

        fields.forEach(f => {
            if (f.autocomplete && temp.length == 1){
                document.getElementById(f.field).value = temp[0][f.autocomplete.field];
                initChangeDataRef({target: {name: f.field, value: temp[0][f.autocomplete.field]}});
            }
                
        })

        acFields[field.autocomplete.field] = [];
        setAcFields({
            ...acFields
        })
    }

    return (
        <>
            {!isPage ? (
                <Dialog 
                    fullWidth={true}
                    maxWidth="md"
                    open={open}
                    onBackdropClick={close}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent dividers>
                        <Error errors={errors} />
                        <form autoComplete='off' autoCorrect='off'>
                            <input type="hidden" value={forceRender} />
                        {renderFormContents()}
                        </form>
                    </DialogContent>
                    <DialogActions className="footerContent">
                        {/*<Button onClick={close}>Annuler</Button>*/}
                        <Button className="save" disabled={!formIsValid} onClick={validate}>Valider</Button>                
                    </DialogActions>
                </Dialog>
            ):( 
                <div className="relativeBtn">
                    {renderFormContents()}
                    {   
                        (customHandleSubmit && typeof (customHandleSubmit) === "function") 
                            ? <div className='footerContent'>
                                <Button className="save" disabled={!formIsValid} onClick={validate}>Valider</Button>
                            </div>
                            :"" 
                    }
                </div>
            )}
            
        </>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);