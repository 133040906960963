
import {
  ADD_TICKET_CONSULTATION,
  GET_ALL_TICKET_CONSULTATION,
  GET_ONE_TICKET_CONSULTATION,
} from "../actions/ticketConsultationAction";

const initialState = {
  ticketConsultations: [],
  ticketConsultation: {}
};

export function ticketConsultationReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TICKET_CONSULTATION:
      return { ...state, ticketConsultations: action.payload };

    case GET_ONE_TICKET_CONSULTATION:
      return { ticketConsultation: action.payload };

    case ADD_TICKET_CONSULTATION:
      return { ...state, ticketConsultation: action.payload };
  
    default:
      return { ...state };
  }
}