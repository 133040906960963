/**
 * UserSettingsPage
 * Création : 27/03/2023
 * Page permettant à un utilisateur de paramétrer son compte (changement mot de passe, paramétrage notification, fuseau horaire)
 * Historique
 *      DD/MM/YYYY   log
 */
import { connect } from "react-redux";
import mapStateToProps from "../../../redux/mapStateToProps";
import mapDispatchToProps from "../../../redux/mapDispatchToProps";

import '../user.scss';

import { useState } from "react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {ReactComponent as SecuriteSVG} from "../../../assets/images/secu.svg";
import {ReactComponent as MessagerieSVG} from "../../../assets/images/mess.svg";
import {ReactComponent as LocalisationSVG} from "../../../assets/images/localisation.svg";

import UserSettingsPassword from "./password";
import UserSettingsTimezone from "./timezone";
import UserSettingsNotification from "./notification";

const UserSettingsPage = (props) => {
    const [currentMenu, setCurrentMenu] = useState(0);

    const handleChangeMenu = (_, newValue) => {
        setCurrentMenu(newValue)
    }

    return (
        <div className="settingsContent" style={{display: 'flex'}}>
            
            <div className="settings-menu" style={{width: '300px'}}>
                <h1 className="title">Mes paramètres</h1>
                <Tabs
                    orientation="vertical"
                    value={currentMenu}
                    onChange={handleChangeMenu}>
                        <Tab icon={<SecuriteSVG fill="red" stroke="current"/>} iconPosition="start" label="Paramètres de sécurité" />
                        <Tab icon={<MessagerieSVG/>} iconPosition="start" label="Paramètres de messagerie" />
                        <Tab icon={<LocalisationSVG/>} iconPosition="start" label="Paramètres de localisation" />
                </Tabs>
            </div>
            <div className="settings-content">
                {currentMenu===0 && <UserSettingsPassword />}
                {currentMenu===1 && <UserSettingsNotification />}
                {currentMenu===2 && <UserSettingsTimezone />}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsPage);