import axios from "axios";

export const GET_ALL_KNOWLEDGEBASE = "GET_ALL_KNOWLEDGEBASE";
export const ADD_KNOWLEDGEBASE = "ADD_KNOWLEDGEBASE";
export const MODIF_KNOWLEDGEBASE = "MODIF_KNOWLEDGEBASE";
export const DELETE_KNOWLEDGEBASE = "DELETE_KNOWLEDGEBASE";
export const GET_ONE_KNOWLEDGEBASE = "GET_ONE_KNOWLEDGEBASE";

export const getAllKnowledgeBases = (async_=false) => (dispatch) => {
    try {
        return async_ 
            ? axios.get("/knowledge-bases")
            : axios.get("/knowledge-bases").then(cli => {
                    dispatch({ type: GET_ALL_KNOWLEDGEBASE, payload: cli.data });
                }) ;
    } catch (error) {
        return console.log("ERREUR GET ALL KNOWLEDGEBASE ", error);
    }
};

export const addKnowledgeBase = (knowledgeBaseState, history, callback) => (dispatch) => {
    try {
        return axios.post("/knowledge-bases", knowledgeBaseState)
                    .then(cli => {
                        dispatch({ type: ADD_KNOWLEDGEBASE, payload: cli.data });
                        if (callback && typeof(callback)==='function') callback(cli.data);
                    });
    } catch (error) {
        return console.log("ERREUR AJOUT KNOWLEDGEBASE ", error);
    }
};

export const updateKnowledgeBase = (knowledgeBaseState) => (dispatch) => {
    try {
        return axios.put(`/knowledge-bases/${knowledgeBaseState.id}`, knowledgeBaseState)
                    .then(cli => {
                        dispatch({ type: MODIF_KNOWLEDGEBASE, payload: cli.data })
                    });
    } catch (error) {
        return console.log("ERREUR MODIFICATION KNOWLEDGEBASE ", error);
    }
};

export const deleteKnowledgeBase = (id) => (dispatch) => {
    try {
        return axios.delete(`/knowledge-bases/${id}`)
                    .then((cli) => {
                        dispatch({ type: DELETE_KNOWLEDGEBASE, payload: cli.data });
                    });
    } catch (error) {
        return console.log("ERREUR DELETE KNOWLEDGEBASE ", error);
    }
};

export const getOneKnowledgeBase = (id) => (dispatch) => {
    try{
        return axios.get(`/knowledge-bases/${id}`);
        /*
                    .then(rep => {
                        dispatch({ type: GET_ONE_KNOWLEDGEBASE, payload: rep.data });
                    });
        */                    
    } catch (error) {
        return console.log("ERREUR ", error);
    }
};

export const getOneKnowledgeBase_ = (id) => {
    try{
        return axios.get(`/knowledge-bases/${id}`);
        /*
                    .then(rep => {
                        dispatch({ type: GET_ONE_KNOWLEDGEBASE, payload: rep.data });
                    });
        */                    
    } catch (error) {
        return console.log("ERREUR ", error);
    }
}

export const getAllKnowledgeBasesByCategory = (idCategory) => (dispatch) => {
    try {
        return axios.get("/knowledge-bases?knowledge_category=" + idCategory);
    } catch (error) {
        return console.log("ERREUR GET ALL KNOWLEDGEBASE ", error);
    }
};

export const getAllKnowledgeBasesBySearchKey = (searchKey) => (dispatch) => {
    try {
        const querya = `query KnowledgeBases($q: String) {
            knowledgeBases(where : { _or: [{ question_contains: $q }, { answer_contains: $q }] }) {
                id
                question
                answer
                knowledge_category {
                    id
                    name
                    class
                }
            }
        }`;
        const variablesa = { "q" : searchKey }

        const body = {
            query : querya,
            variables : variablesa
        }

        return axios.post('/graphql', body)
    } catch (error) {
        return console.log("ERREUR GET ALL KNOWLEDGEBASE ", error);
    }
};