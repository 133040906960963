import axios from "axios";

export const GET_ALL_MATERIAL = "GET_ALL_MATERIAL";
export const GET_ONE_MATERIAL = "GET_ONE_MATERIAL";
export const GET_ONE_COMPANY_MATERIALS = "GET_ONE_COMPANY_MATERIALS";
export const GET_ALL_NOT_INSTALLED_MATERIAL = "GET_ALL_NOT_INSTALLED_MATERIAL";
export const GET_ONE_SITE_MATERIALS = "GET_ONE_SITE_MATERIALS";
export const GET_ONE_BUILDING_MATERIALS = "GET_ONE_BUILDING_MATERIALS";
export const GET_ONE_ROOM_MATERIALS = "GET_ONE_ROOM_MATERIALS";

export const getAllMaterials = () => (dispatch) => {
  try {
    return axios.get("/materials?_sort=designation&_limit=1000").then((cli) => {
      dispatch({ type: GET_ALL_MATERIAL, payload: cli.data });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL MATERIAL ", error);
  }
};

export const getAllMaterials2 = () => (dispatch) => {
  try {
    const querya = `query getMaterials{
            materials{
                  id,
                  designation,
                  serialnumber,
                  room {
                    id
                    name
                    building {
                      id
                      name
                      site {
                        id
                        name
                        company {
                          id
                          name
                        }
                      }
                    }
                  },
                      building {
                    id
                    name
                    site {
                      id
                      name
                      company {
                        id
                        name
                      }
                    }
                  }
                      site {
                    id
                    name
                    company {
                      id
                      name
                    }
                  }
                  published_at,
                  created_at,
                  updated_at,
                  modele,
                  gamme,
                  oldserialnumber,
                  ipAddress,
                  versionLogiciel,
                  macAddress,
                  identifiant,
                  remarque,
                  dateAchat,
                  dateReception,
                  dateMiseEnService,
                  dateFinService,
                  coutAchat,
                  devise,
                  valeurEuro,
                  prixVente,
                  dureeImmobilisation,
                  material_type {
                    id
                    name
                  },
                  material_etat {
                    id
                    name
                  },
                  material_statut {
                    id
                    name
                  },
                  kdPark
            }
          }`;

    const body = {
      query: querya,
    };

    return axios.post("/graphql", body).then((result) => {
      console.log("graphql.result", result);
      dispatch({ type: GET_ALL_MATERIAL, payload: result.data.data.materials });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL MATERIAL ", error);
  }
};

export const getOneMaterial = (id) => (dispatch) => {
  try {
    const querya = `query getMaterials($cid:ID!){
            materials (where : { id : $cid }){
                  id,
                  designation,
                  serialnumber,
                  room {
                    id
                    name
                    building {
                      id
                      name
                      site {
                        id
                        name
                        company {
                          id
                          name
                        }
                      }
                    }
                  },
                      building {
                    id
                    name
                    site {
                      id
                      name
                      company {
                        id
                        name
                      }
                    }
                  }
                      site {
                    id
                    name
                    company {
                      id
                      name
                    }
                  }
                  published_at,
                  created_at,
                  updated_at,
                  modele,
                  gamme,
                  oldserialnumber,
                  ipAddress,
                  versionLogiciel,
                  macAddress,
                  identifiant,
                  remarque,
                  dateAchat,
                  dateReception,
                  dateMiseEnService,
                  dateFinService,
                  coutAchat,
                  devise,
                  valeurEuro,
                  prixVente,
                  dureeImmobilisation,
                  material_type {
                    id
                    name
                  },
                  material_etat {
                    id
                    name
                  },
                  material_statut {
                    id
                    name
                  },
                  kdPark
            }
          }`;

    const variablesa = { cid: id };

    const body = {
      query: querya,
      variables: variablesa,
    };

    return axios.post("/graphql", body);
  } catch (error) {
    return console.log("ERREUR ", error);
  }
};

export const getOneCompanyMaterials = (companyId) => (dispatch) => {
  //console.log(companyId);
  try {
    const querya = `query materials($cid:ID!){
            mat1:materials (where : {room : { building : {site : { company : { id : $cid }}}}}) {
              id
              designation
              serialnumber
              room {
                    id
                    name
                    building {
                        id
                        name
                        site {
                            id
                            name
                        }
                    }
              }
              material_etat{
                  id
                  name
              } 
              material_statut{
                id
                name
              }   
            }
            mat2:materials (where : {building : {site : { company : { id : $cid }}}}) {
              id
              designation
              serialnumber
              room {
                id
                name
              }              
              building {
                  id
                  name
                  site {
                      id
                      name
                  }
              } 
              material_etat{
                id
                name
              } 
                material_statut{
                id
                name
              }   
            }  
            mat3:materials (where : {site : { company : { id : $cid }}}) {
              id
              designation
              serialnumber
              room {
                id
                name
              }        
              building {
                id
                name
              }                    
              site {
                  id
                  name
              }
              material_etat{
                id
                name
              } 
              material_statut{
                id
                name
              }   
            }   
        }`;
    const variablesa = { cid: companyId };

    const body = {
      query: querya,
      variables: variablesa,
    };

    return axios.post("/graphql", body).then((result) => {
      //console.log("graphql.result", result);
      const arr1 = result?.data.data?.mat1;
      const arr2 = arr1.concat(
        result?.data.data?.mat2.filter((m) => m.room == null)
      );
      dispatch({
        type: GET_ONE_COMPANY_MATERIALS,
        payload: arr2.concat(
          result?.data.data?.mat3.filter((m) => m.building == null)
        ),
      });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL BUILDING ", error);
  }
};

export const getAllNotInstalledMaterials = () => (dispatch) => {
  try {
    //TODO trouver la bonne requête pour dire [room = null && building = null && site = null]
    const querya = `query Materials{
            materials (where : {room : null}) { 
              id
              designation
              serialnumber
            }
        }`;

    const body = {
      query: querya,
    };

    return axios.post("/graphql", body).then((result) => {
      console.log("graphql.result", result);
      dispatch({
        type: GET_ALL_NOT_INSTALLED_MATERIAL,
        payload: result.data.data.materials,
      });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL MATERIAL ", error);
  }
};

export const getOneSiteMaterials = (siteId) => (dispatch) => {
  try {
    const querya = `query Materials($cid:ID!){
            materials (where : {site : { id : $cid }}) {
              id
              designation
              serialnumber
              site {
                    id
                    name
                }              
              material_etat{
                    id
                    name
                } 
              material_statut{
                  id
                  name
                }   
            }
        }`;
    const variablesa = { cid: siteId };

    const body = {
      query: querya,
      variables: variablesa,
    };

    return axios.post("/graphql", body).then((result) => {
      console.log("site:", result.data.data?.materials);
      dispatch({
        type: GET_ONE_COMPANY_MATERIALS,
        payload: result.data.data?.materials,
      });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL MATERIALS ", error);
  }
};

export const getOneBuildingMaterials = (buildingId) => (dispatch) => {
  try {
    const querya = `query Materials($cid:ID!){
            materials (where : {room : { building : { id : $cid }}}) {
              id
              designation
              serialnumber
              room {
                    id
                    name
                    building {
                        id
                        name
                        site {
                            id
                            name
                        }
                    }
                }              
                material_etat{
                    id
                    name
                } 
                material_statut{
                  id
                  name
                }   
            }
        }`;
    const variablesa = { cid: buildingId };

    const body = {
      query: querya,
      variables: variablesa,
    };

    return axios.post("/graphql", body).then((result) => {
      console.log("building:", result.data.data?.materials);
      dispatch({
        type: GET_ONE_BUILDING_MATERIALS,
        payload: result.data.data.materials,
      });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL MATERIALS ", error);
  }
};

export const getOneRoomMaterials = (roomId) => (dispatch) => {
  try {
    const querya = `query Materials($cid:ID!){
            materials (where : {room : { id : $cid }}) {
              id
              designation
              serialnumber
              room {
                    id
                    name
                    building {
                        id
                        name
                        site {
                            id
                            name
                        }
                    }
                }              
                material_etat{
                    id
                    name
                } 
                material_statut{
                  id
                  name
                }   
            }
        }`;
    const variablesa = { cid: roomId };

    const body = {
      query: querya,
      variables: variablesa,
    };

    return axios.post("/graphql", body).then((result) => {
      console.log("room:", result.data.data?.materials);
      dispatch({
        type: GET_ONE_ROOM_MATERIALS,
        payload: result.data.data.materials,
      });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL MATERIALS ", error);
  }
};

export const addMaterialSync = (materialState) => (dispatch) => {
  try {
    return axios.post("/materials", materialState);
  } catch (error) {
    return console.log("ERREUR addMaterialSync", error);
  }
};
