/**
 * ContentLayout
 * Création : 15/03/2023
 * Contenu principal de l'application
 * Historique
 *      DD/MM/YYYY   log
 */
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import mapStateToProps from "../redux/mapStateToProps";
import mapDispatchToProps from "../redux/mapDispatchToProps";

import DashboardPage from "../pages/dashboard";
import TicketPage from "../pages/ticket";
//import AddTicketPage from "../pages/ticket/add-dialog";
import SolutionPage from "../pages/solution";
import AddSolutionPage from "../pages/solution/add";
import SolutionCategoryPage from "../pages/solution/category";
import UserPage from "../pages/user";
import UserSettingsPage from "../pages/user/settings";
import './content-layout.scss';
import TicketDetailsPage from "../pages/ticket/ticket-details-page";

const ContentLayout= (props) => {
    const { currentUser } = props;

    return (
        <div className="mainContainer">
            <div className="containerPrincipal">
                <Routes>
                    <Route path="/" element={<DashboardPage currentUser={currentUser} />} exact />
                    <Route path="/home" element={<DashboardPage currentUser={currentUser} />} exact />
                    <Route path="/tickets" element={<TicketPage currentUser={currentUser}/> } exact />
                    <Route path="/tickets/me" element={<TicketPage currentUser={currentUser} param="me"/>} exact />
                    <Route path="/tickets/own-company" element={<TicketPage currentUser={currentUser} param="own-company"/>} exact />
                    <Route path="/ticket/:id" element={<TicketDetailsPage currentUser={currentUser} />} exact />
                    <Route path="/solution" element={<SolutionPage/>} exact />
                    <Route path="/solution/create" element={<AddSolutionPage/>} exact />
                    <Route path="/solution/:category_id" element={<SolutionCategoryPage currentUser={currentUser}/>} exact />
                    <Route path="/solution/:category_id/:kb_id" element={<SolutionCategoryPage currentUser={currentUser}/>} exact />
                    
                    <Route path="/user" element={<UserPage/>} exact />
                    <Route path="/user/settings" element={<UserSettingsPage/>} exact />
                </Routes>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ContentLayout);