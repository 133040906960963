import {
  GET_ME,
  ADD_USER,
  LOGIN_USER,
  LOGIN_FAILED,
  LOGOUT_USER,
  MODIF_RESS,
  DELETE_RESS,
  GET_ALL_USER,
  ADD_USER_ERROR,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  EMAIL_CONFIRMATION
} from "../actions/usersAction";

const initialState = {
  users: [],
  user: [],
  error: {}
};

export function usersReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ME:
      return { ...state, user: action.payload };

    case ADD_USER:
      return { ...state };

    case LOGIN_USER:
      return { ...state, user: action.payload };

    case LOGIN_FAILED:
      return { ...state, error: action.payload };

    case LOGOUT_USER:
      return { ...state };

    case MODIF_RESS:
      return { ...state };

    case DELETE_RESS:
      return { ...state };

    case GET_ALL_USER:
      return { ...state, users: action.payload };
    
    case ADD_USER_ERROR:
        return { ...state, error: action.payload };

    case FORGOT_PASSWORD:
      return { ...state, error: action.payload };

    case RESET_PASSWORD:
      return { ...state, error: action.payload };

    case EMAIL_CONFIRMATION:
      return { ...state, error: action.payload};
        
    default:
      return { ...state };
  }
}
