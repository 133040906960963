/**
 * AddSolutionPage
 * Création : 15/03/2023
 * Page permettant de créer une base de connaissance
 * Historique
 *      DD/MM/YYYY   log
 */
import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

const AddSolutionPage = (props) => {

    return (
        <div>AddSolutionPage</div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSolutionPage);