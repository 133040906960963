import { combineReducers } from 'redux';
import { rolesReducers } from './rolesReducers';
import { usersReducers} from "./usersReducers";
import { companyReducers } from "./companyReducers";
import { companyTypeReducers } from "./companyTypeReducers";
import { documentTypeReducers } from "./documentTypeReducers";
import { documentReducers } from "./documentReducers";
import { contactReducers } from "./contactReducers";
import { navigationReducers } from "./navigationReducers";
import { ticketTypeReducers } from './ticketTypeReducer';
import { ticketReducers } from "./ticketReducers";
import { ticketPriorityReducers } from "./ticketPriorityReducers";
import { ticketStatutReducers } from "./ticketStatutReducers";
import { ticketObjectReducers } from './ticketObjectReducers';
import { ticketMotifReducers } from './ticketMotifReducers';
import { ticketOriginReducers } from './ticketOriginReducers';
import { ticketHistoryReducers } from './ticketHistoryReducers';
import { knowledgeBaseReducers } from './knowledgeBaseReducers';
import { knowledgeCategoryReducers } from './knowledgeCategoryReducers';
import { fileReducers } from "./fileReducers";
import { configReducers } from './configReducers';
import { materialReducers } from './materialReducers';
import { siteReducers } from './siteReducers';
import { ticketListReducers } from './ticketListReducers';
import { ticketConsultationReducers } from './ticketConsultationReducers';


const rootReducer = combineReducers({
  log: (_, action) => ({}),
  users: usersReducers,
  roles: rolesReducers,
  companies: companyReducers,
  companyTypes: companyTypeReducers,
  documentTypes: documentTypeReducers,
  documents: documentReducers,
  contacts: contactReducers,
  navigation : navigationReducers,
  tickets : ticketReducers,
  ticketPriorities : ticketPriorityReducers,
  ticketStatuts : ticketStatutReducers,
  ticketTypes: ticketTypeReducers,
  ticketObjects: ticketObjectReducers,
  ticketMotifs: ticketMotifReducers,
  ticketOrigins: ticketOriginReducers,
  ticketHistories: ticketHistoryReducers,
  configData: configReducers,
  knowledgeBases: knowledgeBaseReducers,
  knowledgeCategories: knowledgeCategoryReducers,
  file : fileReducers,
  materials: materialReducers,
  sites: siteReducers,
  ticketListState: ticketListReducers,
  ticketConsultations: ticketConsultationReducers,
}); 
  
export default rootReducer;
