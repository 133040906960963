import axios from "axios";

export const GET_ALL_ESCALATION_GROUP = "GET_ALL_ESCALATION_GROUP";
export const GET_ONE_ESCALATION_GROUP = "GET_ONE_ESCALATION_GROUP";
export const GET_ONE_ESCALATION_GROUP_ = "GET_ONE_ESCALATION_GROUP_";

export const getAllEscalationGroups = () => (dispatch) => {
  try {
    return axios.get("/escalation-groups").then((cli) => {
      dispatch({ type: GET_ALL_ESCALATION_GROUP, payload: cli.data });
    });
  } catch (error) {
    return console.log("ERREUR GET ALL ESCALATION_GROUP ", error);
  }
};

export const getOneEscalationGroup = (id) => (dispatch) => {
  try {
    return axios.get(`/escalation-groups/${id}`).then((rep) => {
      dispatch({ type: GET_ONE_ESCALATION_GROUP, payload: rep.data });
    });
  } catch (error) {
    return console.log("ERREUR ", error);
  }
};

export const getOneEscalationGroup_ = (id) => {
  try {
    return axios.get(`/escalation-groups/${id}`);
  } catch (error) {
    return console.log("ERREUR ", error);
  }
};

export const getAllEscalationGroupsSync = () => {
  try {
    return axios.get("/escalation-groups");
  } catch (error) {
    return console.log("ERREUR GET ALL ESCALATION_GROUP ", error);
  }
};
